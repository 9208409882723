import { useEffect } from 'react';
import { compact, uniq } from 'lodash';

import { recomendationsActions } from 'reducers/application';

import * as hooks from './';

const useRecomendations = (title: string, productId?: number) => {
  const selectors = hooks.useSelectors();

  useEffect(() => {
    recomendationsActions.changeTitle(title);
    const excIds = uniq(compact([productId, ...selectors.basketIds]));
    recomendationsActions.changeExclude(excIds);
    recomendationsActions.changeProductId(productId);
    recomendationsActions.fetchData();
  }, [selectors.pathname]);

  return {
    selectors,
  }
}

export default useRecomendations;

import { useEffect } from 'react';

import * as hooks from './';

const useOrder = () => {
  const selectors = hooks.useSelectors();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectors.pathname]);

  return {
    selectors,
  }
}

export default useOrder;

export const ORDER_SHOW         = 'ORDER/SHOW';
export const ORDER_UPDATE       = 'ORDER/UPDATE';
export const ORDER_CREATE       = 'ORDER/CREATE';
export const ORDER_FORM_SUCCESS = 'ORDER/FORM_SUCCESS';
export const ORDER_FORM_FAIL    = 'ORDER/FORM_FAIL';
export const FETCH_ORDER        = 'ORDER/FETCH_ORDER';

export const MESSAGE_SET_DATA   = 'ORDER/MESSAGE_SET_DATA';
export const MESSAGE_SHOW       = 'ORDER/MESSAGE_SHOW';
export const CLEAR              = 'ORDER/CLEAR';

export const COULD_PAY          = 'ORDER/ORDER_COULD_PAY';
export const PAYMENT_SITE       = 'ORDER/PAYMENT_SITE';
export const PAYMENT_UPDATE     = 'ORDER/PAYMENT_UPDATE';

import { call, put, takeLatest } from 'redux-saga/effects';
import { post } from 'common/utils/api';

import * as types from 'reducers/application/basket/basket.constants'

function* hundleData(action: any) {
  try {
      const response = yield call(post, 'clients/basket/data', action.payload)
      if (response.success) {
        yield put({type: types.LOADED_SUCCESS, payload: response.data})
      } else {
        yield put({type: types.LOADED_ERROR, payload: response.data})
      }
   } catch (error) {
      yield put({type: types.LOADED_ERROR, payload: error})
   }
}

export function* data() {
  yield takeLatest(types.LOAD_EMPTY_DATA, hundleData);
}

import { useEffect } from 'react';

import * as payInfoUtils from 'components/application/PayInfo/utils';
import { price as preparePrice } from 'common/utils/calculate';
import * as hooks from './';

const useOrder = () => {
  const selectors = hooks.useSelectors();
  const products = payInfoUtils.prepare.products(selectors.basketData);
  const { price } = preparePrice(products, selectors.type, selectors.promocode);

  return {
    selectors,
    price,
  }
}

export default useOrder;

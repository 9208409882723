import { FormErrors } from 'redux-form';

import { errorHash } from 'common/utils/validation';
import { errors } from 'common/utils/localized';

export const user = (values = {}): FormErrors => {
  const constraints = {
    firstName: {
      presence: { 
        allowEmpty: false,
        message: errors.empty,
      },
    },
    lastName: {
      presence: {
        allowEmpty: false,
        message: errors.empty,
      }
    },
    phone: {
      presence: {
        allowEmpty: false,
        message: errors.empty,
      },
      phone: true
    },
    email: {
      email: {
        message: `email ${errors.invalid}`,
      },
      presence: {
        allowEmpty: false,
        message: `email ${errors.invalid}`,
      },
    },
    socialUrl: {
      instagramUrl: true,
    },
  }

  return errorHash(values, constraints, { fullMessages: false })
};

export default user;
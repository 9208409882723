import React, { FC } from 'react';
import classNames from 'classnames';

import { IMenu } from './Menu.interface';
import * as hooks from './hooks';

import theme from './Menu.module.scss';
import { Links } from './components';
import { Basket  } from '../';

const Menu: FC<IMenu> = ({
}) => {
  const { openMenu, setOpenMenu } = hooks.useMenu();

  return (
    <nav className={ theme.root }>
      <Links className={ theme.links } />
      <Basket />
    </nav>
  );
}

export default Menu;

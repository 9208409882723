import React, { FC } from 'react';
import { Field } from 'redux-form';

import { TextField } from 'common/ui';
import { TextFieldProps } from './TextField.interface'
import theme from './TextField.module.scss';

const FormInput: FC<TextFieldProps> = ({
  name,
  type = 'text',
  label,
  ...props
}) => {

  return (
    <Field
      { ...props }
      name={ name }
      type={ type }
      label={ label || name }
      id={ name }
      component={ TextField }
    />
  )
}

export default FormInput;
import React, { FC } from 'react';
import cn from 'classnames';

import { IHtmlText } from './HtmlText.interface';
import theme from './HtmlText.module.scss';

const HtmlText: FC<IHtmlText> = ({
  className,
  html,
}) => {
  return (
    <div className={ cn(theme.root, className,)}
      dangerouslySetInnerHTML={{__html: html}}
    />
  );
}

export default HtmlText;

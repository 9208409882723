import { 
  defaultTableState, defaultFormState, defaultSelectState
} from 'reducers/admin/helpers/model';

export const initialFormState = {
  ...defaultFormState,
  article: {
    tags: [],
  },
};

export const initialTableState = {
  ...defaultTableState,
  articles: []
};

export const initialSelectState = {
  ...defaultSelectState,
  articles: [],
};

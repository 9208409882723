import React, { FC } from 'react';
import cn from 'classnames';

import { Tabs, Tab, Form, GreyButton, SubmitButton, Icon } from 'common/ui';
import { orderActions } from 'reducers/application';
import { Address } from 'components/both';
import { change }  from 'redux-form';

import * as hooks from './hooks';
import { IOrderForm } from './OrderForm.interface';
import { store } from 'store/indexApplication';

import { User, Payment } from '../';
import { validate } from './validate';
import theme from './OrderForm.module.scss';


const OrderForm: FC<IOrderForm> = ({ 
  className,
  price,
}) =>  {
  const { 
    states, selectors, onSubmitFail, label, labelSending,
  } = hooks.useOrderForm();

  return(
    <div className={ cn(theme.root, className) }>
      <div 
        className={ theme.header }
        onClick={ () => orderActions.order.show(false) }
      >
        <div className={ theme.text }>Оформлення замовлення</div>
        <Icon className={theme.icon} name="close-outlined" />
      </div>
      <Form
        onSubmit={ selectors.form.id ? orderActions.form.update : orderActions.form.create }
        form='order'
        initialValues={ selectors.form }
        validate={ validate }
        className={ theme.form }
        showButtons={ false }
        onSubmitFail={ onSubmitFail }
      >
        <div className={ theme.body } >
          <Tabs
            tabIndex={ states.tabIndex }
            onChange={ states.setTabIndex }
          >
            <Tab label="Ваші дані" icon="perm-identity-outlined" className={ theme.firstTab }>
              <User />
            </Tab>
             <Tab label="Доставка" icon="local-shipping-outlined">
              <Address formName="order" store={ store } />
            </Tab>
             <Tab label="Спосіб оплати" icon="payment-outlined">
              <Payment price={ price } promocode={ selectors.promocode } />
            </Tab>
          </Tabs>

          <div className={ theme.grow } />
        </div>

        <SubmitButton
          className={ theme.button }
          label={ label }
          labelSending={ labelSending }
          property="grey"
          disabled={ false }
          sending={ selectors.sending }
         /> 
      </Form>
    </div>
  )
}

export default OrderForm;

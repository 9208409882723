import React, { FC } from 'react';
import cn from 'classnames';

import { priceView } from 'common/utils/humanView';
import { price as preparePrice } from 'common/utils/calculate';
import { timeMessage } from 'common/utils/discount';
import { IPayInfo, IPayOption } from './PayInfo.interface';

import * as hooks from './hooks';
import * as utils from './utils';

import theme from './PayInfo.module.scss';

const PayOption: FC<IPayOption> = ({
  active = true,
  label,
  children,
  className
}) => {
  if (!active) { return null }

  return (
    <li className={ cn(theme.option, className) }>
      <div className={ theme.label }>{ label }</div>
      <div className={ theme.value }>{ children }</div>
    </li>
  )
}

const PayInfo: FC<IPayInfo> = ({
  className,
  delivery,
  back,
}) => {
  const { data, promocode } = hooks.usePayInfo();
  const { price, discount, totalPrice } = preparePrice(utils.prepare.products(data), back, promocode);

  if (!data.length) { return null }

  return (
    <ul className={ cn(theme.root, className) }>
      <PayOption active={ !!promocode.discount } label="Знижка по промокоду">
        { `${promocode.discount}%` }
      </PayOption>
      <PayOption active={ !!discount.price } label="Дисконт на замовлення від 1000 грн">
        { `${discount.price}%` }
      </PayOption>
      <PayOption active={ delivery == 'novaPosta' } label="Доставка">
        { utils.delivery.novaPosta(price, back) }
      </PayOption>
      <PayOption active={ !!discount.payment } label={ timeMessage("Знижка за попередню оплату", 'site')} >
         <strong>{ priceView(totalPrice*discount.payment/100) }</strong>
      </PayOption>
      <PayOption active={ totalPrice !== price } label="Сума без знижок">
        { priceView(totalPrice) }
      </PayOption>
      <PayOption className={theme.total} label="До оплати">
        { priceView(price) }
      </PayOption>
    </ul>
  );
}

export default PayInfo;

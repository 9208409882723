import React, { FC } from 'react';
import { Field } from 'redux-form';

import { CheckBox } from 'common/ui';
import { CheckBoxProps } from './CheckBox.interface'
import theme from './CheckBox.module.scss';

const FormCheckBox: FC<CheckBoxProps> = ({
  name,
  label,
  onChange = null
}) => {

  return (
    <Field
      className={ theme.root }
      name={ name }
      label={ label || name }
      id={ name }
      elementChange={onChange}
      component={ CheckBox }
    />
  )
}

export default FormCheckBox;
export const ARTICLES_FETCH          = 'ARTICLES/FETCH';
export const ARTICLES_FETCH_SUCCESS  = 'ARTICLES/FETCH_SUCCESS';
export const ARTICLES_FETCH_ERROR    = 'ARTICLES/FETCH_ERROR';
export const ARTICLES_SORTING_CHANGE = 'ARTICLES/SORTING_CHANGE';
export const ARTICLES_SEARCH         = 'ARTICLES/SEARCH';
export const ARTICLES_FLTER_CHANGE   = 'ARTICLES/FLTER_CHANGE';

export const ARTICLE_FETCH         = 'ARTICLES/FETCH_ONE';
export const ARTICLE_FETCH_SUCCESS = 'ARTICLES/FETCH_ONE_SUCCESS';
export const ARTICLE_FETCH_ERROR   = 'ARTICLES/FETCH_ONE_ERROR';

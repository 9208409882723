import React, { FC } from 'react';
import cn from 'classnames';

import { Drawer, Icon } from 'common/ui';

import * as utils from './utils';
import { IMessage } from './Message.interface';
import theme from './Message.module.scss';

const Message: FC<IMessage> = ({ 
  className,
}) =>  {
  return(
    <Drawer
      isOpened
      onBackdropClick={ utils.main.close }
      className={ theme.drawer }
      property="center"
    >
      <div className={ cn(theme.root, className) }>
        <div 
          className={ theme.header }
          onClick={ utils.main.close }
        >
          <div className={ theme.text }>Дякуємо за запитання!</div>
          <Icon className={theme.icon} name="close-outlined" />
        </div>
        <div className={ theme.body }>
          <p>Ваше питання було надіслано до нас.</p>
          <p>І незабаром наш менеджер зв'яжеться із вами.</p>
        </div>
      </div>
    </Drawer>
  )
}

export default Message;

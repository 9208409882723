import React, { FC } from 'react';
import classNames from 'classnames';
import { Checkbox } from '@material-ui/core';

import { CheckBoxProps } from './CheckBox.interface';
import theme from './CheckBox.module.scss';

const CheckBox: FC<CheckBoxProps> = ({
  className,
  id,
  input,
  label,
  meta: { touched, error },
  elementChange,
  ...props
}) => {
  const value = input.value == "true" || input.value == true

  return (
    <div className={ classNames({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      { label && <label className={ theme.label } htmlFor={ id }>
          { label }
        </label>
      }

      <div className={ theme.checkbox }>
        <Checkbox 
          { ...input } 
          id={ id }
          type="checkbox"
          checked={value}
          color="primary"
          onChange={(event) => {
            input.onChange(event)
            if(elementChange) { elementChange(event) }
          }}
        />
      </div>

      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
  )
};

export default CheckBox;

export const toNumber = (value) =>  value && parseFloat(parseFloat(value).toFixed(2)) || 0

export const priceView = (number, type = 'uan') => {
	let parts = toNumber(number).toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const price = parts.join(".")

  switch (type) {
    case 'uan':
      return `${price} грн.`
    case 'usd':
      return `${price} $`
  }

  return `${parts.join(".")} грн.`
}

export const humanValue = (hash) => {
	switch (hash.type) {
    case 'money':
      return priceView(hash.value)
    default:
      return hash.value
  }
}

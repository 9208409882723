import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { startSubmit, stopSubmit, reset } from 'redux-form'
import { post, patch } from 'common/utils/api'
import { preparedFormData } from 'common/utils/preparedData'

import * as types from 'reducers/admin/promocodes/promocodes.constants';
import { showSuccess, showError } from 'reducers/admin/snackbar/snackbar.actions'

function* objectResponse(response, message = null) {
  if (response.success) {
    yield put(stopSubmit('promocode'))

    yield put({type: types.PROMOCODE_FORM_SUCCESS, payload: response.data})
    yield put({type: types.PROMOCODE_SELECT_CLEAR})
    yield put(push(`/admin/promocodes/${response.data.id}/edit`))

    if (message) { showSuccess(message) }
  } else {
    yield put({type: types.PROMOCODE_FORM_FAIL, payload: { error: response.error }})
    if (response.data.messages) {
      yield put(stopSubmit('promocode', response.data.messages))
    }
  }
}

function* fetchObject(action: any) {
  try {
    const response = yield call(post, 'promocodes/get_info', action.payload)
    yield call(objectResponse, response)
  } catch (error) {
    yield put({type: types.PROMOCODE_FORM_FAIL, error})
    showError(error)
  }
}

function* updateObject(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('promocode'))
    const response = yield call(patch, 'promocodes', formData)

    yield call(objectResponse, response, "Промокод був оновлений")
  } catch (error) {
    yield put({type: types.PROMOCODE_FORM_FAIL, error})
    showError(error)
  }
}

function* createObject(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('promocode'))
    const response = yield call(post, 'promocodes', formData)

    yield call(objectResponse, response, "Промокод був створений")
  } catch (error) {
    yield put({type: types.PROMOCODE_FORM_FAIL, error})
    showError(error)
  }
}

export function* formFetchFlow() {
  yield takeLatest(types.PROMOCODE_FORM_FETCH, fetchObject)
}

export function* formCreateFlow() {
  yield takeLatest(types.PROMOCODE_FORM_CREATE, createObject)
}

export function* formUpdateFlow() {
  yield takeLatest(types.PROMOCODE_FORM_UPDATE, updateObject)
}

import { FormErrors } from 'redux-form';

import { errorHash } from 'common/utils/validation';
import { errors } from 'common/utils/localized';

export const validate = (values = {}): FormErrors => {
  let constraints = {
    type: {
      presence: { 
        allowEmpty: false,
        message: errors.empty,
      },
    }
  }

  if (values.type == 'card') {
    constraints['check'] = {
      presence: { 
        allowEmpty: false,
        message: errors.empty,
      },
      file: {
        formats: ['jpg', 'jpeg', 'png', 'pdf'],
      }
    }
  }

  return errorHash(values, constraints, { fullMessages: false })
};

export default validate;
import React, { FC, cloneElement, Children } from 'react';
import classNames from 'classnames';

import { ITabs } from './Tabs.interface';
import theme from './Tabs.module.scss';


const Tabs: FC<ITabs> = ({
  className,
  children,
  tabIndex,
  onChange,
  ...props
}) => {
  return (
    <div className={ classNames(theme.root, className) }>
      { Children.map(children, (child, index) => {
          return cloneElement(child, {
            ...props,
            active: tabIndex === index,
            onClick: () => onChange(index),
          })
        }) }
    </div>
  )
}

export default Tabs;

import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'common/utils/api';

import * as types from 'reducers/application/filters/filters.constants'

function* hundleFilters(action: any) {
  try {
      const response = yield call(get, 'clients/filters/all', action.payload)
      if (response.success) {
        yield put({type: types.FILTERS_FETCH_SUCCESS, payload: response.data})
      } else {
        yield put({type: types.FILTERS_FETCH_FAIL, payload: response.data})
      }
   } catch (error) {
      yield put({type: types.FILTERS_FETCH_FAIL, payload: error})
   }
}

export function* filters() {
  yield takeLatest(types.FILTERS_FETCH, hundleFilters);
}


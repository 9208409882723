import React, { FC } from 'react';
import TagsLibrary from 'react-meta-tags';

import { IMetaTags } from './MetaTags.interface';
import * as hooks from './hooks';


const MetaTags: FC<IMetaTags> = ({
}) => {
  const { selectors } = hooks.useMetaTags();

  return (
    <TagsLibrary>
      <meta name="title" content={ selectors.title } />
      <meta name="description" content={ selectors.description } />
      <meta name="image" content={ selectors.image } />
      <meta name="keywords" content={ selectors.keywords } />
      <meta property="og:title" content={ selectors.title } />
      <meta property="og:description" content={ selectors.description } />
      <meta property="og:image" content={ selectors.image } />
    </TagsLibrary>
  );
}

export default MetaTags;

import { FormErrors } from 'redux-form';

import { errorHash } from 'common/utils/validation';
import { errors } from 'common/utils/localized';

export const otherConstraint = (values = {}): FormErrors => {
  return {
    info: {
      presence: { 
        allowEmpty: false,
        message: errors.empty,
      },
    },
  }
}

export const novaPostaConstraint = (values = {}): FormErrors => {
  let constraint = {
    cityId: {
      presence: { 
        allowEmpty: false,
        message: errors.empty,
      },
    },
    warehouseId: {
      presence: { 
        allowEmpty: false,
        message: errors.empty,
      },
    },
  }

  if (values.requiredW2) {
    constraint['warehouse2Id'] = {
      presence: { 
        allowEmpty: false,
        message: errors.empty,
      },
    }
  }

  return constraint
}

export const address = (values = {}): FormErrors => {
  let constraints = {
    type: {
      presence: { 
        allowEmpty: false,
        message: errors.empty,
      },
    }
  }

  switch(values.type) {
    case 'other':
      constraints = { ...constraints, ...otherConstraint(values) }
      break;
    case 'novaPosta':
      constraints = { ...constraints, ...novaPostaConstraint(values) }
      break;
  }

  return errorHash(values, constraints, { fullMessages: false })
};

export default address;

import { initialTableState } from './articles.model';
import * as types from './articles.constants';

export const table = (state = initialTableState, action: any) => {
  switch(action.type) {
    case types.TABLE_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case types.TABLE_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      };
    }
    case types.TABLE_SEARCH: {
      return {
        ...state,
        values: {
          ...state.values,
          query: action.payload.query,
          page: 0,
          searchFocus: true
        }
      };
    }
    case types.TABLE_SUCCESS: {
      return {
        ...state,
        articles: action.payload.data,
        totalCount: action.payload.totalCount,
        extraInfo: action.payload.extraInfo,
        loading: false,
        error: '',
      };
    }
    case types.TABLE_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch articles',
      };
    }
    default: {
      return state;
    }
  }
};

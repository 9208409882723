import React, { FC } from 'react';
import { Grid, Box } from '@material-ui/core';

import cn from 'classnames';
import { Icon } from 'common/ui';
import theme from './TabPanel.module.scss';

const TabPanel = ({ className = '', label, icon = '', index, value, onChange, children, ...props }) => {
  return (
    <Grid container {...props}>
      <Grid item xs={12}>
        <Box component="span"
          display={{ xs: 'block', sm: 'block', md: 'none' }}
        >
          <div
            className={ cn({
                [theme.active]: index === value
              }, theme.root, className)
            }
            onClick={()=> onChange(index)}
          >
            { icon && <Icon name={ icon } className={ theme.icon }/> }
            { label }
          </div>
        </Box>
      </Grid>
      <Grid item md={12} xs={12}>
        <div hidden={value !== index}>
          <Grid container>
            { children }
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default TabPanel
import React, { FC } from 'react';

import { IDelivery } from './Delivery.interface';
import theme from './Delivery.module.scss';
import * as hooks from './hooks';

const Delivery: FC<IDelivery> = ({
  
}) => {
  hooks.useDelivery();

  return (
    <div className={ theme.root }>
      <div className={ theme.headerMain }>
        Доставка
      </div>

      <ul className={ theme.list }>
        <li>
          Опрацювання замовлень здійснюється в порядку черги щодня з 10 до 17 години.
        </li>
        <li>
          Відправлення відбувається у вівторок та п'ятницю.
        </li>
        <li>
          Всі замовлення обробляються в порядку черги. Ми обов'язково з вами зв'яжемося. Будь ласка, дочекайтеся повідомлення на пошту від нашого менеджера!
        </li>
        <li>
          Як правило ми завжди зв'язуємося з вами через залишену Вами пошту, соцмережі чи месенджери — Viber або Telegram.
        </li>
        <li>
          Ми намагаємося не  телефонувати, адже практично всі наші клієнти — це матусі з маленькими дітками. І щоб не розбудити або не подзвонити в незручний час — ми тримаємо з Вами зв'язок через електронні засоби зв‘язку.
        </li>
        <li>
          Якщо ми не зможемо із Вами зв‘язатися за допомогою вищезгаданих способів — ми обов'язково Вам зателефонуємо.
        </li>
        <li>
          Будь ласка, перевіряйте правильність заповнення ваших даних (телефон і пошта)!
        </li>
        <li>
          Після відправлення Вам на пошту (або за допомогою іншого вищезгаданого способу зв‘язку, за яким нам вдалося із Вами зв‘язатися) надійде лист з ТТН (товарно-транспортна накладна) Вашого замовлення. 
        </li>
      </ul>

      <div className={ theme.headerSecond }>
        Доставка по Україні
      </div>

      <ul className={ theme.listSecond }>
        <li>
          Доставка по Україні здійснюється службою НОВА ПОШТА.
          Відправка у вівторок та п'ятницю.
          Вартість доставки - згідно з тарифами поштової служби, оплачує клієнт при отриманні замовлення.
          Або ми оплачуємо доставку при умовах повної передоплати на загальну суму замовлення вище 1 500 грн (остаточна сума до оплати повинна перевищувати 1500 грн з урахуванням всіх знижок)
        </li>
        <li>
          Інші способи доставки за бажанням клієнта обговорюються в індивідуальному порядку та зі збільшенням термінів відправлення.
        </li>
      </ul>
    </div>
  );
}

export default Delivery;

import { initialTableState } from './products.model';
import {
  PRODUCTS_TABLE_FETCH,
  PRODUCTS_TABLE_SUCCESS,
  PRODUCTS_TABLE_FAIL,
  PRODUCTS_TABLE_VALUES,
  PRODUCTS_TABLE_SEARCH,
} from './products.constants';

export const table = (state = initialTableState, action: any) => {
  switch(action.type) {
    case PRODUCTS_TABLE_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case PRODUCTS_TABLE_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      };
    }
    case PRODUCTS_TABLE_SEARCH: {
      return {
        ...state,
        values: {
          ...state.values,
          query: action.payload.query,
          page: 0,
          searchFocus: true
        }
      };
    }
    case PRODUCTS_TABLE_SUCCESS: {
      return {
        ...state,
        products: action.payload.data,
        totalCount: action.payload.totalCount,
        extraInfo: action.payload.extraInfo,
        loading: false,
        error: '',
      };
    }
    case PRODUCTS_TABLE_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch products',
      };
    }
    default: {
      return state;
    }
  }
};

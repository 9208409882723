import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { post } from 'common/utils/api'
import { store } from 'store/indexAdmin';

import {
  PRODUCTS_TABLE_FETCH,
  PRODUCTS_TABLE_FAIL,
  PRODUCTS_TABLE_SUCCESS,
  PRODUCTS_TABLE_VALUES,
  PRODUCTS_TABLE_SEARCH,
} from 'reducers/admin/products/products.constants';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* fetchProducts(action: any) {
  try {
      const response = yield call(post, 'products/all', action.payload)
      yield put({type: PRODUCTS_TABLE_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: PRODUCTS_TABLE_FAIL, error})
   }
}

function* loadinData(payload: any = {}) {
  const response = yield call(post, 'products/all', { ...payload })

  if (response.failure) {
    yield put({type: PRODUCTS_TABLE_FAIL})
    return false
  }
  yield put({type: PRODUCTS_TABLE_SUCCESS, payload: response.data})
}

function* searchTableProducts(action: any) {
  yield call(delay, 500)
  yield call(fetchTableProducts)
}

function* fetchTableProducts(action: any) {
  const { getState } = store;
  const { productsData: { table: { values } } } = getState()
  yield put({type: PRODUCTS_TABLE_FETCH, payload: values})
}

export function* productsFetchFlow() {
  yield takeLatest(PRODUCTS_TABLE_FETCH, fetchProducts)
}

export function* productsTableFetchFlow() {
  yield takeLatest(PRODUCTS_TABLE_VALUES, fetchTableProducts)
}

export function* productsTableSearchFlow() {
  yield takeLatest(PRODUCTS_TABLE_SEARCH, searchTableProducts)
}


import React, { FC } from 'react';
import cn from 'classnames';

import { Icon } from 'common/ui';
import { IIconButton } from './IconButton.interface';

import theme from './IconButton.module.scss';

const IconButton: FC<IIconButton> = ({
  name,
  className,
  active = true,
  onClick,
  iconClass,
}) => {

  return (
    <div 
      className={ cn(theme.root, { [theme.active]: active }, className) }
      onClick={ () => { active && onClick() } }
    >
      <Icon className={ cn(theme.icon, iconClass) } name={ name } />
    </div>
  );
}

export default IconButton;

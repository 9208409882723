import { all } from 'redux-saga/effects';
import {
  colorsFetchFlow,
  colorsTableFetchFlow,
  colorsTableSearchFlow
} from './table.saga';

import {
  colorFormFetchFlow,
  colorFormCreateFlow,
  colorFormUpdateFlow
} from './form.saga';

import {
  colorsSelectFetchFlow,
  colorsSelectedFetchFlow
} from './select.saga'

export function* colorsFlow() {
  yield all([
    colorsFetchFlow(),
    colorsTableFetchFlow(),
    colorsTableSearchFlow(),
    colorFormFetchFlow(),
    colorFormCreateFlow(),
    colorFormUpdateFlow(),
    colorsSelectFetchFlow(),
    colorsSelectedFetchFlow()
  ]);
}

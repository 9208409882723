import { store } from 'store/indexApplication';

import * as types from './metaTags.constants';
import { initialState } from './metaTags.model';
import ShopPhoto from 'images/shop.jpeg';

export const change = (params: any = {}) => {
  const imagePath = params.image ? params.image : ShopPhoto;
  const image = `${window.location.origin}${imagePath}`

  store.dispatch({
    type: types.CHANGE_TAGS,
    payload: { 
      image,
      title: params.title || initialState.title,
      description: params.description || initialState.description,
      keywords: params.keywords || initialState.keywords,
    },
  })
}

import React, { useState } from 'react';

const setStates = (min: number, max: number, rValue: number[]) => {
  const initialValue = rValue.length ? rValue : [min, max]
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
  }
}

export default setStates;
import { all, call, fork } from 'redux-saga/effects';
import { loginAdminFlow } from './admin/login/login.saga';

import { usersFlow } from './admin/users/index.saga';
import { outlaysFlow } from './admin/outlays/index.saga';
import { purchasesFlow } from './admin/purchases/index.saga';
import { colorsFlow } from './admin/colors/index.saga';
import { tagsFlow } from './admin/tags/index.saga';
import { articlesFlow } from './admin/articles/index.saga';
import { slidersFlow } from './admin/sliders/index.saga';
import { ordersFlow } from './admin/orders/index.saga';
import { productsFlow } from './admin/products/index.saga';
import { promocodesFlow } from './admin/promocodes/index.saga';
import { categoriesFlow } from './admin/categories/index.saga';
import { novaPostaFlow } from './both/novaPosta';

export default function* rootSaga() {
  yield fork(productsFlow);
  yield fork(promocodesFlow);
  yield fork(slidersFlow);
  yield fork(categoriesFlow);
  yield fork(colorsFlow);
  yield fork(tagsFlow);
  yield fork(articlesFlow);
  yield fork(ordersFlow);
  yield fork(usersFlow);
  yield fork(outlaysFlow);
  yield fork(purchasesFlow);
  yield fork(loginAdminFlow);
  yield fork(novaPostaFlow);
}

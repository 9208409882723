import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { startSubmit, stopSubmit, reset } from 'redux-form';
import { push } from 'connected-react-router'

import { orderActions } from 'reducers/application';
import { post, patch } from 'common/utils/api';
import { preparedFormData } from 'common/utils/preparedData';

import * as types from 'reducers/application/order/order.constants';

function* orderResponse(response, message = null) {
  if (response.success && response.data.success) {
    if (message) { yield put(stopSubmit('payment')) }
    yield put({type: types.ORDER_FORM_SUCCESS, payload: response.data})
    orderActions.main.checkMessage();

  } else {
    yield put({type: types.ORDER_FORM_FAIL, payload: { error: response.data.errors }})
    if (response.data.messages) {
      yield put(stopSubmit('payment', response.data.messages))
    }
  }
}

function* handleSite(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('payment'))
    const response = yield call(post, 'clients/payments/site', formData)

    yield call(orderResponse, response, "Замовлення було оплачено")
  } catch (error) {
    yield put({type: types.ORDER_FORM_FAIL, error})
  }
}

function* handleUpdate(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('payment'))
    const response = yield call(patch, 'clients/payments/update', formData)

    yield call(orderResponse, response, "Замовлення було оновлено")
  } catch (error) {
    yield put({type: types.ORDER_FORM_FAIL, error})
  }
}

export function* fetchSite() {
  yield takeLatest(types.PAYMENT_SITE, handleSite)
}

export function* fetchUpdate() {
  yield takeLatest(types.PAYMENT_UPDATE, handleUpdate)
}

import { initialState } from './model';
import * as types from './constants';

export const promocodes = (state = initialState, action: any) => {
  switch(action.type) {
    case types.FETCH_DATA: {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }
    case types.FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: null,
        data: {
          ...action.payload,
          discount: parseFloat(action.payload.discount)
        },
      };
    }
    case types.FETCH_ERROR: {
      return {
        ...state,
        data: initialState.data,
        loading: false,
        errors: action.payload.errors,
      };
    }
    case types.CHANGE_CODE: {
      return {
        ...state,
       code: action.payload.code,
      };
    }
  case types.CLEAR_DATA: {
      return {
        ...state,
       data: initialState.data,
      };
    }
    default: {
      return state;
    }
  }
};

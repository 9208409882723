import React, { FC } from 'react';
import cn from 'classnames';

import { Counter, IconButton } from 'common/ui';
import { basketActions } from 'reducers/application';

import * as utils from './utils';
import { priceView } from 'common/utils/humanView';
import { IBasketElement } from './BasketElement.interface';

import theme from './BasketElement.module.scss';

const BasketElement: FC<IBasketElement> = ({
  product,
  color,
  count,
  photo,
  number,
}) => {
  return (
    <li 
      className={ cn(theme.root) }
    >
      <div className={ theme.photo } style={{ backgroundImage: `url(${photo})` }} />
      <div className={ theme.info }>
        <div className={ theme.name }>{ product.label }</div>
        <div className={ theme.colorInfo }>
          Колір: 
          <div className={ theme.color } 
            style={{
              backgroundImage: `url(${color.photo})`,
              backgroundColor: color.color,
             }}
          />
        </div>
        <div className={ theme.price }>{ priceView(product.price) }</div>
        <Counter 
          value={ count }
          minValue={ 1 }
          maxValue={ number }
          onChange={ utils.change.count({
            number,
            value: count,
            productId: product.id,
            colorId: color.id,
          }) }
        />
      </div>
      <IconButton
        className={ theme.remove }
        iconClass={ theme.removeIcon }
        name="cancel-outlined"
        onClick={ () => { 
          basketActions.removeProduct({
            count,
            number,
            colorId: color.id, 
            productId: product.id,
          })
       }}
      />
    </li>
  );
}

export default BasketElement;

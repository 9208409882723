import { fork } from 'redux-saga/effects';

import { productsFlow } from './application/products';
import { recomendationsFlow } from './application/recomendations';
import { articlesFlow } from './application/articles';
import { filtersFlow } from './application/filters';
import { promocodesFlow } from './application/promocodes';
import { basketFlow } from './application/basket';
import { novaPostaFlow } from './both/novaPosta';
import { orderFlow } from './application/order';
import { questionFlow } from './application/question';

export default function* rootSaga() {
  yield fork(productsFlow);
  yield fork(recomendationsFlow);
  yield fork(articlesFlow);
  yield fork(filtersFlow);
  yield fork(promocodesFlow);
  yield fork(basketFlow);
  yield fork(novaPostaFlow);
  yield fork(orderFlow);
  yield fork(questionFlow);
}

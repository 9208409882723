import { initialState } from './order.model';
import * as types from './order.constants';

export const order = (state = initialState, action: any) => {
  switch(action.type) {
    case types.ORDER_SHOW: {
      return {
        ...state,
        orderShow: action.payload.value,
      };
    }
    case types.ORDER_UPDATE: {
      return {
        ...state,
        sending: true,
      };
    }
    case types.PAYMENT_UPDATE: {
      return {
        ...state,
        sending: true,
      };
    }
    case types.ORDER_CREATE: {
      return {
        ...state,
        sending: true,
      };
    }
    case types.MESSAGE_SET_DATA: {
      return {
        ...state,
        message: action.payload,
      };
    }
     case types.MESSAGE_SHOW: {
      return {
        ...state,
        showMessage: action.payload,
      };
    }
    case types.FETCH_ORDER: {
      return {
        ...state,
        fetching: true,
      }
    }
    case types.PAYMENT_SITE: {
      return {
        ...state,
        paying: true,
      }
    }
    case types.COULD_PAY: {
      return {
        ...state,
        couldPay: action.payload,
      }
    }
    case types.ORDER_FORM_SUCCESS: {
      return {
        ...state,
        form: action.payload.order,
        sending: false,
        fetching: false,
        paying: false,
        error: '',
      };
    }
    case types.ORDER_FORM_FAIL: {
      return {
        ...state,
        sending: false,
        fetching: false,
        paying: false,
        error: action.payload.error,
      };
    }
    case types.CLEAR: {
      return {
        ...state,
        order: {},
        form: { ...initialState.form },
      };
    }
    default: {
      return state;
    }
  }
};

import React, { FC } from 'react';
import cn from 'classnames';

import { Icon } from 'common/ui';
import { ITab } from './Tab.interface';
import theme from './Tab.module.scss';

const Tab: FC<ITab> = ({
  className,
  label,
  icon,
  active,
  onClick,
  children,
}) => {
  return (
    <div className={ cn( theme.root, { [theme.active]: active }, className) }
      onClick={ onClick }
    >
      <div className={ cn(theme.header, { [theme.active]: active }) }>
        <span className={ theme.text }>
          { !!icon && <Icon className={ theme.icon } name={ icon } /> }
          { label }
        </span>
        <div className={cn({[theme.iconOpen]: !active}, theme.plus )}/>
      </div>
      <div className={ cn(theme.body, { [theme.active]: active }) }>
        { children }
      </div>
    </div>
  )
}

export default Tab;

import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { post } from 'common/utils/api'
import { store } from 'store/indexAdmin';

import { showError } from 'reducers/admin/snackbar/snackbar.actions'
import * as types from 'reducers/admin/orders/orders.constants'

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* fetchOrders(action: any) {
  try {
      const response = yield call(post, 'orders/all', action.payload)
      yield put({type: types.ORDERS_TABLE_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: types.ORDERS_TABLE_FAIL, error})
   }
}

function* loadinData(payload: any = {}) {
  const response = yield call(post, 'orders/all', { ...payload })

  if (response.failure) {
    yield put({type: types.ORDERS_TABLE_FAIL})
    return false
  }
  yield put({type: types.ORDERS_TABLE_SUCCESS, payload: response.data})
}

function* hundleFetchInitial(payload: any = {}) {
  try {
    const response = yield call(post, 'orders/table_initial', { ...payload })

   yield put({type: types.ORDERS_TABLE_INITIAL_SUCCESS, payload: response.data})
  } catch (error) {
    yield put({type: types.ORDERS_TABLE_FAIL, error})
    showError(error)
  }
}

function* searchTableOrders(action: any) {
  yield call(delay, 500)
  yield call(fetchTableOrders)
}

function* fetchTableOrders(action: any) {
  const { getState } = store;
  const { ordersData: { table: { values } } } = getState()
  yield put({type: types.ORDERS_TABLE_FETCH, payload: values})
}

export function* fetchFlow() {
  yield takeLatest(types.ORDERS_TABLE_FETCH, fetchOrders)
}

export function* tableFetchFlow() {
  yield takeLatest(types.ORDERS_TABLE_VALUES, fetchTableOrders)
}

export function* searchFlow() {
  yield takeLatest(types.ORDERS_TABLE_SEARCH, searchTableOrders)
}

export function* fetchInitial() {
  yield takeLatest(types.ORDERS_TABLE_FETCH_INITIAL, hundleFetchInitial)
}


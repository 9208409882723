import React, { FC } from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';

import { RadioGroup } from 'common/ui';
import { IRadioGroup } from './RadioGroup.interface'
import theme from './RadioGroup.module.scss';

const FormRadioGroup: FC<IRadioGroup> = ({
  name,
  label,
  values = [],
  onChange = null,
  className,
  ...props
}) => {

  return (
    <Field
      { ...props }
      className={ cn(theme.root, className) }
      name={ name }
      values={ values }
      label={ label }
      id={ name }
      elementChange={ onChange }
      component={ RadioGroup }
    />
  )
}

export default FormRadioGroup;

import axios from 'axios'

import { ok, fail } from './http'

interface Header {
  Token?: any;
}

const headers = () => {
  let hash:Header = {}

  const Token = localStorage.getItem('TTheLittleToken')

  if (Token) { hash['Token'] = Token }

  return hash
}

const preparePath = (path: string) => `/api/v1/${path}`

export const get = (path: string, opts: any = {}) =>
  axios
    .create({
      headers: headers(),
    }).get(preparePath(path), { params: opts })
    .then(ok)
    .catch(fail)

export const post = (path: string, opts: any = {}) =>
  axios
    .create({
      headers: headers(),
    }).post(preparePath(path), opts)
    .then(ok)
    .catch(fail)

export const patch = (path: string, opts: any = {}) =>
  axios
    .create({
      headers: headers(),
    }).patch(preparePath(path), opts)
    .then(ok)
    .catch(fail)

export const destroy = (path: string, opts: any = {}) =>
  axios
    .create({
      headers: headers(),
    }).delete(preparePath(path), opts)
    .then(ok)
    .catch(fail)

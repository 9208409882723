import { useSelector, shallowEqual } from 'react-redux';

const useSelectors = () => {
  const { 
    data, page, leftMore, sorting, loading, search 
  } = useSelector((state: any) => state.articles, shallowEqual);
  const { 
    filters, categories, tags, loaded,
  } = useSelector((state: any) => state.filters, shallowEqual);
  const { pathname } = useSelector((state: any) => state.router.location, shallowEqual);

  return {
    articles: data,
    page,
    leftMore,
    sorting,
    loading,
    search,
    filters,
    categories,
    tags,
    loaded,
    pathname,
  }
}

export default useSelectors;
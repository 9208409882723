import { call, put, takeLatest } from 'redux-saga/effects'
import { post } from 'common/utils/api'

import {
  OUTLAY_SELECT_FETCH_STATUSES,
  OUTLAY_SELECT_STATUS_SUCCESS,
  OUTLAY_SELECT_STATUS_FAIL,
} from 'reducers/admin/outlays/outlays.constants';

const preparedValues = (colors) =>
  outlays.map((color) => {
    return {
      value: color.id,
      label: color.name
    }
  })

function* fetchOutlaysStatuses(action: any) {
  try {
      const response = yield call(post, 'outlays/get_statuses', { ...action.payload })
      yield put({type: OUTLAY_SELECT_STATUS_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: OUTLAY_SELECT_STATUS_FAIL, error})
   }
}

export function* outlaysSelectStatuesFetchFlow() {
  yield takeLatest(OUTLAY_SELECT_FETCH_STATUSES, fetchOutlaysStatuses)
}

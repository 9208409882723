import React, { FC } from 'react';
import { Field } from 'redux-form';

import { AddToList } from 'common/ui';
import { AddToListProps } from './AddToList.interface'
import theme from './AddToList.module.scss';

const FormInput: FC<AddToListProps> = ({
  name,
  label,
  onClick
}) => {

  return (
    <Field
      className={ theme.root }
      name={ name }
      label={ label || name }
      id={ name }
      component={ AddToList }
      onClick={ onClick }
    />
  )
}

export default FormInput;

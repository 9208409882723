export const TABLE_FETCH   = 'ARTICLES/TABLE/FETCH';
export const TABLE_SUCCESS = 'ARTICLES/TABLE/SUCCESS';
export const TABLE_FAIL    = 'ARTICLES/TABLE/FAIL';
export const TABLE_VALUES  = 'ARTICLES/TABLE/VALUES';
export const TABLE_SEARCH  = 'ARTICLES/TABLE/SEARCH';

export const FORM_FETCH   = 'ARTICLES/FORM/FETCH';
export const FORM_SUCCESS = 'ARTICLES/FORM/SUCCESS';
export const FORM_FAIL    = 'ARTICLES/FORM/FAIL';
export const FORM_UPDATE  = 'ARTICLES/FORM/UPDATE';
export const FORM_CREATE  = 'ARTICLES/FORM/CREATE';

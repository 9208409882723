import React, { FC } from 'react';
import classNames from 'classnames';
import Select from 'react-select/creatable';

import theme from './CreatableSelect.module.scss';
import { ICreatableSelect } from './CreatableSelect.interface';

const CreatableSelect: FC<ICreatableSelect> = ({
  options = [],
  input,
  className,
  id,
  label,
  meta: { touched, error },
  elementChange = null,
  closeMenuOnSelect = true,
  ...props
}) => {
  const values = (input.value || []).map((value: any) => { return { value, label: value } }) || []

  return (
    <div className={ classNames({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      { label && <label className={ theme.label } htmlFor={ id }>
          { label }
        </label>
      }

      <Select
        { ...props }
        closeMenuOnSelect={closeMenuOnSelect}
        value={values}
        options={options}
        className={ theme.inputField }
        isMulti
        styles={{
          menu: style => {
            return { ...style, zIndex: '100' }
          }
        }}
        onChange={(event) => {
          const values = event.map((hash: any) => hash.value)
          input.onChange(values)
          if(elementChange) { elementChange(values) }
        }}
      />
      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
)};
  
export default CreatableSelect;

import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import { change } from "redux-form";
import loadImage from 'blueimp-load-image';

import PictureInput from './PictureInput.layout'

export default class PictureInputComponent extends Component {
  state = {
    src: null,
    crop: this.props.crop,
    openDialog: false,
    imageFormat: 'image/jpeg',
    imageName: 'newFile.jpg'
  };

  getImageAtt = (base64) => {
    const format = base64.split(';')[0].split('/')[1];
    if (format === 'png') {
      return {
        format: 'image/png',
        name: 'newFile.png'
      }
    }
    return {
      format: 'image/jpeg',
      name: 'newFile.jpg'
    }
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      loadImage(
        e.target.files[0],
        (img) => {
          let { crop } = this.state;
          crop.x = 0
          crop.y = 0
          const data = img.toDataURL(`image/jpeg`);
          const imageAtt = this.getImageAtt(data);
          this.setState({
            src: data,
            imageFormat: imageAtt.format,
            imageName:   imageAtt.name,
            openDialog:  true,
            crop
          })
        },
        { orientation: true, canvas: true }
      );
    }
  };

  dialogChange = (openDialog) => {
    this.setState({ openDialog })
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const { imageName } = this.state

      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        imageName
      );
      this.setState({ croppedImageUrl });

    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width*scaleX);
    canvas.height = Math.ceil(crop.height*scaleY);
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width*scaleX,
      crop.height*scaleY,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        const { input: { onChange } } = this.props

        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        onChange(blob)
        resolve(this.fileUrl);
      }, this.state.imageFormat);
    });
  }

  render() {
    const { crop, croppedImageUrl, src, openDialog } = this.state;
    const { input: { value } } = this.props

    return (
      <PictureInput
        { ...this.props }
        crop={crop}
        croppedImageUrl={croppedImageUrl || value}
        dialogChange={this.dialogChange}
        openDialog={openDialog}
        onCropChange={this.onCropChange}
        onCropComplete={this.onCropComplete}
        onImageLoaded={this.onImageLoaded}
        onSelectFile={this.onSelectFile}
        src={src}
      />
    );
  }
}

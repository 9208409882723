export const size = ({ 
  prices = [], 
  categories = [], 
  colors = [],
  tags = [],
  min,
  max, 
}) => {
  let sum = categories.length + colors.length + tags.length;

  if ( prices[0] != min ) { sum++ }
  if ( prices[1] != max ) { sum++ }
  
  return sum
}

import React, { FC, useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { change }  from 'redux-form';
import { camelCase, startCase } from 'lodash';
import moment from 'moment'

import { 
  materialDate, monthStartYear, monthEndYear 
} from 'common/utils/dateTime'
import DateRange from './DateRange.layout'
import { store } from 'store/indexAdmin';
import { initialValues } from './DateRange.model'

const DateRangeContainer = ({
	table,
	column,
  changeAction
})=> {
  const formName = camelCase(`dateRange ${table}`);
  const { form } = useSelector((state: any) => state, shallowEqual);
  const { range } = useSelector((state: any) => state[table].table.values, shallowEqual);
  const values = form[formName] && form[formName].values || initialValues()
  const { from, to } = values

  const [activeSort, setActiveSort] = useState(false);

  useEffect(() => {
    if (activeSort && (from != range.from || to != range.to)) {
      changeAction({ column, from, to })
    }
    if(!activeSort && (range.from || range.to)) {
      changeAction({})
    }
  }, [`${activeSort}_${formName}_${from}_${to}`]);

  const calculateFrom = (year, month) => materialDate(monthStartYear(year, month))
  const calculateTo = (year, month) => materialDate(monthEndYear(year, month))


  const recalculateRange = (newYear = null, newMonth = null) => {
    const formValues = store.getState().form[formName].values
    let { year, month } = formValues;

    year = newYear || year;
    month = newMonth || month;
    if (newMonth == 0) { month = 0 }

    store.dispatch(change(formName, 'from', calculateFrom(year, month)))
    store.dispatch(change(formName, 'to', calculateTo(year, month)))
  }

  return (
    <DateRange
      activeSort={activeSort}
      setActiveSort={setActiveSort}
    	table={table}
    	initialValues={initialValues()}
      form={formName}
      recalculateRange={recalculateRange}
    />
  )
}

export default DateRangeContainer

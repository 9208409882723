import update from 'immutability-helper';

export const moveImage = (states: any, input: any) => (dragIndex: number, hoverIndex: number) => {
  const dragCard = states.images[dragIndex];
  const images = update(states.images, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });
  states.setImages(images);
  input.onChange(images);
}

import React, { FC } from 'react';
import cn from 'classnames';

import * as mHooks from '../../hooks';
import { novaPostaActions } from 'reducers/both';

import * as utils from './utils';
import { SelectAsync } from 'common/ui/FormElements'
import { INovaPosta } from './NovaPosta.interface';
import theme from './NovaPosta.module.scss';

const NovaPosta: FC<INovaPosta> = ({ 
  className,
  formName,
  store,
  dispatch,
  state,
}) =>  {
  const selectors = mHooks.useSelectors();
  const requiredW2 = mHooks.form.selector(store, formName, 'address.requiredW2');
  return(
    <div className={ cn(theme.root, className) }>
      <SelectAsync
        className={ theme.select }
        name="cityId"
        label="Населений пункт"
        options={ utils.prepare.cities(selectors.cities) }
        loadOptions={ utils.load.cities }
        onChange={ utils.change.city(store, dispatch, formName, state) }
      />

      <SelectAsync
        key={ `${selectors.cityId}-1` }
        className={ theme.select }
        name="warehouseId"
        label={ requiredW2 ? 'Поштомат' : 'Відділення' }
        options={ utils.prepare.warehouses(selectors.warehouses) }
        loadOptions={ utils.load.warehouses(store, selectors) }
        isDisabled={ !selectors.loadedWarehouses }
        onChange={ novaPostaActions.changeWarehouse1(store)(formName) }
      />

      {
        requiredW2 && <div className={ theme.extra }>
          <div className={ theme.info }>
             Ви обрали <strong>поштомат</strong>. Будь ласка оберіть ще відділення, на котрому вам
             було би зручно отримати, <strong>якщо у поштоматі не буде вільних комірок</strong>
          </div>
          <SelectAsync
            key={ `${selectors.cityId}-2` }
            className={ theme.select }
            name="warehouse2Id"
            label="Відділення"
            options={ utils.prepare.warehouses2(selectors.warehouses) }
            loadOptions={ utils.load.warehouses2(selectors) }
            isDisabled={ !selectors.loadedWarehouses }
            onChange={ novaPostaActions.changeWarehouse2(store) }
          />
        </div>
      }
    </div>
  )
}

export default NovaPosta;

import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { post, get } from 'common/utils/api';

import * as types from 'reducers/application/articles/articles.constants'

function* hundleArticles(action: any) {
  try {
      const response = yield call(get, 'clients/articles/load', action.payload)
      yield put({type: types.ARTICLES_FETCH_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: types.ARTICLES_FETCH_ERROR, error})
   }
}

export function* articles() {
  yield takeLatest(types.ARTICLES_FETCH, hundleArticles);
}


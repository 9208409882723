import { initialTableState } from './colors.model';
import {
  COLORS_TABLE_FETCH,
  COLORS_TABLE_SUCCESS,
  COLORS_TABLE_FAIL,
  COLORS_TABLE_VALUES,
  COLORS_TABLE_SEARCH,
} from './colors.constants';

export const table = (state = initialTableState, action: any) => {
  switch(action.type) {
    case COLORS_TABLE_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case COLORS_TABLE_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      };
    }
    case COLORS_TABLE_SEARCH: {
      return {
        ...state,
        values: {
          ...state.values,
          query: action.payload.query,
          page: 0,
          searchFocus: true
        }
      };
    }
    case COLORS_TABLE_SUCCESS: {
      return {
        ...state,
        colors: action.payload.data,
        totalCount: action.payload.totalCount,
        extraInfo: action.payload.extraInfo,
        loading: false,
        error: '',
      };
    }
    case COLORS_TABLE_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch colors',
      };
    }
    default: {
      return state;
    }
  }
};

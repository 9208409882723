import React, { FC } from 'react';
import cn from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';

import { Product, FilterSorting } from 'components/application/sections';
import { GreyButton } from 'common/ui';

import { IProducts } from './Products.interface';
import theme from './Products.module.scss';

const Products: FC<IProducts> = ({
  products,
  className,
  loadMore,
  leftMore,
  sorting,
  search,
  fixFilters,
  loading,
}) => {
  return (
    <div className={ cn({
          [theme.fixFilters]: fixFilters,
        },
        theme.root,
        className,
      )}
    >  
      <FilterSorting 
        className={ theme.sorting } 
        type="products"
        params={[
          { label: 'популярні', value: 'popular' },
          { label: 'дешеві', value: 'priceAsc' },
          { label: 'дорогі', value: 'priceDesc' },
        ]}
      />

      { !products.length && <div className={ theme.empty }>
          За вашими даними пропозицій не знайдено :(
        </div> 
      }

      <InfiniteScroll
        pageStart={0}
        className={ theme.scroll }
        loadMore={ () => { loadMore() } }
        hasMore={ !loading && leftMore }
       >
         { !!products.length && (products).map((product, index) => (
          <Product
            { ...product }
            key={index}
            className={ theme.product }
          />
        ))}
      </InfiniteScroll>

      { leftMore &&
        <div className={ theme.loadButton }>
          <GreyButton
            label="Завантажити ще"
            type="grey"
            onClick={ loadMore }
           />
        </div>
      }
    </div>
  );
}

export default Products;

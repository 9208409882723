import React, { FC } from 'react';
import cn from 'classnames';

import { Drawer } from 'common/ui';
import { orderActions } from 'reducers/application';

import * as hooks from './hooks';
import { IOrder } from './Order.interface';
import theme from './Order.module.scss';
import { OrderForm, PaymentForm, Message } from './components';

import Photo from 'images/shop.jpeg';

const Order: FC<IOrder> = ({ 
}) =>  {
  const { selectors, price } = hooks.useOrder();

  return(
    <Drawer
      isOpened={ selectors.orderShow }
      onBackdropClick={ orderActions.order.show }
      className={ theme.drawer }
      property="center"
    >
      <div className={ theme.root }>
        <div className={ theme.photo } 
          style={{
            backgroundImage: `url(${Photo})`
           }}
        />
        { selectors.showMessage && <Message { ...selectors.message } className={ theme.form } />}
        { !selectors.showMessage && selectors.couldPay && <PaymentForm className={ theme.form } price={price} /> }
        { !selectors.showMessage && !selectors.couldPay && <OrderForm className={ theme.form } price={price}  /> }
      </div>
    </Drawer>
  )
}

export default Order;

export const localization = {
    body: {
      emptyDataSourceMessage: 'Даних не знайдено',
      addTooltip: 'Додати',
      deleteTooltip: 'Видалити',
      editTooltip: 'Bearbeiten',
      filterRow: {
        filterTooltip: 'Фільтер'
      },
      editRow: {
        deleteText: 'Ви впевнені, що бажаєте видалити це?',
        cancelTooltip: 'Відмінити',
        saveTooltip: 'Зберегти'
      }
    },
    grouping: {
      placeholder: 'Перетягніть заголовки ...',
      groupedBy: 'Згрупувати за:'
    },
    header: {
      actions: 'Дії'
    },
    pagination: {
      labelDisplayedRows: '{from}-{to} із {count}',
      labelRowsSelect: 'Рядків',
      labelRowsPerPage: 'Рядків на сторінці:',
      firstAriaLabel: 'Перша сторінка',
      firstTooltip: 'Перша сторінка',
      previousAriaLabel: 'Попередня сторінка',
      previousTooltip: 'Попередня сторінка',
      nextAriaLabel: 'Наступна сторінка',
      nextTooltip: 'Наступна сторінка',
      lastAriaLabel: 'Останя сторінка',
      lastTooltip: 'Останя сторінка'
    },
    toolbar: {
      addRemoveColumns: 'Добавити або видалити колонки',
      nRowsSelected: '{0} рядок(ів) обрано',
      showColumnsTitle: 'Показати колонки',
      showColumnsAriaLabel: 'Показати колонки',
      exportTitle: 'Експорт',
      exportAriaLabel: 'Експорт',
      exportName: 'Експортувати як CSV',
      searchTooltip: 'Пошук',
      searchPlaceholder: 'Пошук'
    }
  }
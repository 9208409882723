import * as utils from '../utils';
import loadImage from 'blueimp-load-image';

export const onChange = (states) => (e)=> {
  if (e.target.files && e.target.files.length > 0) {
    loadImage(
      e.target.files[0],
      (img) => {
        let { crop } = states
        crop.x = 0
        crop.y = 0
        const data = img.toDataURL(`image/jpeg`);
        const imageAtt = utils.picture.getImageAtt(data);
        states.setSrc(data);
        states.setImageFormat(imageAtt.format);
        states.setImageName(imageAtt.name);
        states.setOpenDialog(true);
        states.setCrop(crop);
      },
      { orientation: true, canvas: true }
    );
  }
};

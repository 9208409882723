import React, { FC } from 'react';

import { PageHeader } from 'components/application/sections';
import { IMain } from './Main.interface';

import * as hooks from './hooks';
import * as utils from './utils';
import ArticlePhoto from 'images/article.jpeg';
import theme from './Main.module.scss';
import { Filters, Articles } from './components';

const Main: FC<IMain> = ({
  
}) => {

  const { states, selectors, loadMore } = hooks.useMain();

  return (
    <div className={ theme.root }>
      <PageHeader
        picture={ ArticlePhoto }
        label="Поради"
      />
      <section className={ theme.articlesSection }>
        <Filters fixFilters={ states.fixFilters } />
        <Articles 
          fixFilters={ states.fixFilters }
          selectors={ selectors }
          articles={ utils.prepare.articles(selectors.articles) }
          loadMore={ loadMore }
        />
      </section>
    </div>
  );
}

export default Main;

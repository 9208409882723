import React, { FC } from 'react';
import classNames from 'classnames';

import { Icon } from 'common/ui';
import { IInputSearch } from './InputSearch.interface';
import theme from './InputSearch.module.scss';

const InputSearch: FC<IInputSearch> = ({
  className,
  value,
  onChange,
}) => {
  return (
    <div className={ classNames([theme.root],
        className,)}
    >
      <Icon name="search" className={ theme.icon } />
      <input 
        value={ value } 
        type="text" 
        placeholder="Пошук" 
        onChange={ (e: any) => onChange(e.target.value) }
      />
      <div className={ theme.line } />
      {
        value && <Icon
          name="close" 
          className={ theme.close }
          onClick={ () => onChange('') }
        />
      }
    </div>
  );
}

export default InputSearch;

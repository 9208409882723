import { preparedBackForm } from 'common/utils/preparedData';
import { store } from 'store/indexApplication';

import * as types from '../order.constants';

export const update = (values) => {
  const { products } = store.getState().basket;
  
  store.dispatch({
    type: types.ORDER_UPDATE,
    payload: preparedBackForm({ ...values, products })
  });
};

export const create = (values) => {
  const { products } = store.getState().basket;

  store.dispatch({
    type: types.ORDER_CREATE,
    payload: preparedBackForm({ products, ...values })
  });
};

import { call, put, take, cancel, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { post } from 'common/utils/api'

export function* authorizeResponse(response: any) {
  if (response.failure) {
    yield put({type: 'LOGIN_ERROR', payload: { error: response.error}})
    return false
  }

  yield put({type: 'LOGIN_SUCCESS', payload: response.data})
  localStorage.setItem('TTheLittleToken', response.data.token)
  return true
}

export function* checkAuthentification(user: any) {
  const token = localStorage.getItem('TTheLittleToken')
  if (token && user.id) { return true }
  if (token) {
    const response = yield call(post, 'auth/user_info')
    yield put({type: 'LOGIN_SUCCESS', payload: { user: response.data}})
    if (response.success) { return true }
  }

  return false
}

function* authorize(email?: string, password?: string) {
    const response = yield call(post, 'auth/login', { email, password })
    const authorized = yield call(authorizeResponse, response)
    if (!authorized) { return false }
    yield put(push('/admin'));
}

function* checkLogin(user: any) {
  const auth = yield call(checkAuthentification, user)
  if (auth) { return true }
  yield put(push('/admin/login'));
  return false
}

function* loginType(action: any) {
  switch(action.type) {
    case 'LOGIN_CHECK': {
      return yield call(checkLogin, action.payload.user)
    }
    case 'LOGIN_REQUEST': {
      const { email, password } = action.payload
      return yield call(authorize, email, password)
    }
  }
}

export function* loginAdminFlow() {
  while (true) {
    let action = yield take(['LOGIN_CHECK', 'LOGIN_REQUEST'])

    const task = yield call(loginType, action)
    if (!task) { continue }

    action = yield take(['LOGOUT', 'LOGIN_ERROR'])
    if (action.type === 'LOGOUT') {
      localStorage.removeItem('TTheLittleToken')
      yield put(push('/admin/login'))
    }
  }
}

import { useSelector, shallowEqual } from 'react-redux';

const useSelectors = () => {
  const { 
    warehouses, loadedWarehouses, cities, loadedCities, cityId,
  } = useSelector((state: any) => state.novaPosta, shallowEqual);

  return {
    warehouses,
    loadedWarehouses,
    cities,
    loadedCities,
    cityId,
  }
}

export default useSelectors;

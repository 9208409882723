import { call, put, takeLatest } from 'redux-saga/effects'
import { post } from 'common/utils/api'

import * as types from 'reducers/admin/products/products.constants';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* fetchProducts(action: any) {
  try {
    const response = yield call(post, 'products/get_select', { ...action.payload })
    yield put({type: types.SELECT_SUCCESS, payload: response.data })
   } catch (error) {
    yield put({type: types.SELECT_FAIL, error})
   }
}

function* selectedProducts(action: any) {
  try {
    const response = yield call(post, 'products/get_selected', { ...action.payload })
    yield put({type: types.SELECT_REQUIRED_SUCESS, payload: response.data })
   } catch (error) {
    yield put({type: types.SELECT_FAIL, error})
   }
}

export function* productsSelectFetchFlow() {
  yield takeLatest(types.SELECT_FETCH, fetchProducts)
}

export function* productsSelectedFetchFlow() {
  yield takeLatest(types.SELECT_REQUIRED_FETCH, selectedProducts)
}

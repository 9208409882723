import { combineReducers } from 'redux';
import { form } from './form.reducer'
import { table } from './table.reducer'
import { select } from './select.reducer'

export const tagsData = () => combineReducers({
  form,
  table,
  select
});

import React, { FC } from 'react';
import classNames from 'classnames';

import { ButtonProps } from './Button.interface';

import theme from './Button.module.scss';

const Button: FC<ButtonProps> = ({
  className,
  label,
  variant,
  onClick,
  type = '',
  disabled

}) => (
  <button
    disabled={disabled}
    className={ classNames(theme[variant], theme.root, className) }
    onClick={ onClick }
    type={ type }
  >
    { label }
  </button>
);

export default Button;

import React, { FC } from 'react';
import cn from 'classnames';

import { Collapse } from 'common/ui';
import { articlesActions } from 'reducers/application';

import * as hooks from './hooks';
import { IMobileSorting } from './MobileSorting.interface';
import theme from './MobileSorting.module.scss';

const MobileSorting: FC<IMobileSorting> = ({
  className,
  type,
  params,
}) => {
  const { isActive, actions } = hooks.useMobileSorting(type);

  return (
    <div className={ cn(theme.root, className) } >
      <Collapse title="Спочатку показати">
       <ul>
          { params.map((param: any, index: number) => (
            <li className={cn({
              [theme.optionActive]: isActive(param.value)
              }, theme.option)}
              key={ index }
              onClick={ () => actions.changeSorting(param.value) }
            >
              { param.label }
            </li>
           )) }
        </ul>
      </Collapse>
    </div>
  );
}

export default MobileSorting;

import { initialState } from './metaTags.model';
import * as types from './metaTags.constants';

export const metaTags = (state = initialState, action: any) => {
  switch(action.type) {
    case types.CHANGE_TAGS: {
      return {
        ...state,
        ...initialState,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

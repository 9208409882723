import React, { useState } from 'react';

const useStates = () => {
  const [refreshId, setRefreshId] = useState(0);
  const updateComponent = () => setRefreshId(refreshId + 1);

  return {
    refreshId,
    setRefreshId,
    updateComponent,
  }
}

export default useStates;

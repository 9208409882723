import React, { FC } from 'react';
import classNames from 'classnames';
import ReactQuill from 'react-quill';

import { IHtmlEditor } from './HtmlEditor.interface';

import theme from './HtmlEditor.module.scss';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'image', 'video'],
  ['clean']                                         // remove formatting button
];

const HtmlEditor: FC<IHtmlEditor> = ({
  className,
  id,
  input,
  label,
  meta: { touched, error },
  elementChange,
  ...props
}) => {
  return (
    <div className={ classNames({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      { label && <label className={ theme.label } htmlFor={ id }>
          { label }
        </label>
      }

      <div className={ theme.inputField }>
        <ReactQuill
          value={ input.value }
          modules={{
            toolbar: toolbarOptions,
          }}
          onChange={(event) => {
            input.onChange(event)
            if(elementChange) { elementChange(event) }
          }}
        />
      </div>

      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
  )
};

export default HtmlEditor;

import { call, put, takeLatest, fork } from 'redux-saga/effects'
import { startSubmit, stopSubmit, reset } from 'redux-form';

import { store } from 'store/indexApplication';
import { post } from 'common/utils/api';
import { preparedFormData } from 'common/utils/preparedData';

import * as types from 'reducers/application/question/question.constants';

function* questionResponse(response, message = null) {
  if (response.success && response.data.success) {
    if (message) { yield put(stopSubmit('question')) }
    yield put({type: types.QUESTION_CREATE_SUCCESS, payload: response.data});
    yield put(reset('question'));
  } else {
    yield put({type: types.QUESTION_CREATE_FALSE, payload: { error: response.data.errors }})
    if (response.data.messages) {
      yield put(stopSubmit('question', response.data.messages))
    }
  }
}

function* createQuestion(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('question'))
    const response = yield call(post, 'clients/questions/create', formData);

    yield call(questionResponse, response, "Запитання було відправлено");
  } catch (error) {
    yield put({type: types.QUESTION_CREATE_FALSE, error})
  }
}

export function* createFlow() {
  yield takeLatest(types.QUESTION_CREATE, createQuestion)
}



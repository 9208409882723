import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { join } from 'lodash';

import * as hooks from './';

const useColors = () => {

  const { 
    colors, filters,
  } = useSelector((state: any) => state.filters, shallowEqual);

  useEffect(() => {
  }, [join(colors, '-')]);
  return {
    colors: filters.colors,
    selected: colors,
  }
}

export default useColors;

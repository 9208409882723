import React, { FC } from 'react';
import classNames from 'classnames';

import * as hooks from './hooks';
import Select from 'react-select';
import { Label } from '../';
import { ISelect } from './SelectAsync.interface';
import theme from './Select.module.scss';

const SelectMain: FC<ISelect> = ({
  className,
  id,
  input,
  label,
  meta: { touched, error },
  options = [],
  elementChange = null,
  closeMenuOnSelect = true,
  isMulti = false,
  ...props
}) => {
  const { 
    value, onSelectChange,
  } = hooks.useSelect(options, input, elementChange);

  return (
    <div className={ classNames({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      <Label value={ input.value } label={ label } htmlFor={ id } />
      <Select
        { ...props }
        closeMenuOnSelect={ closeMenuOnSelect }
        value={value}
        placeholder={ label || "Обрати..." }
        noOptionsMessage={ () => 'Нема даних' }
        isMulti={isMulti}
        options={options}
        isOptionDisabled={(option: any) => option.disabled}
        className={ theme.inputField }
        styles={{
          menu: style => {
            return { ...style, zIndex: '100' }
          }
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#d9ead5',
            primary: '#4A4A4A',
          },
        })}
        onChange={ onSelectChange }
      />
      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
)};

export default SelectMain;

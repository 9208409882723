import React, { FC, Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import className from 'classnames'
import {
  Snackbar, IconButton, Slide, TransitionProps
} from '@material-ui/core';

import { Icon } from 'common/ui'
import { hideSnackbar } from 'reducers/admin/snackbar/snackbar.actions'
import { SnackbarProps } from './Snackbar.interface'
import theme from './Snackbar.module.scss'

const SnackbarLayout: FC<SnackbarProps> = () => {
  const { isOpen, message, type } = useSelector((state: any) => state.snackbar, shallowEqual);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={hideSnackbar}
      TransitionComponent={ (props: TransitionProps)=> <Slide {...props} direction='left'/> }
    >

      <div className={className(theme[type], theme.root)}>
        { message }
         <Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={hideSnackbar}>
            <Icon name="close" />
          </IconButton>
        </Fragment>
      </div>
    </Snackbar>
  );
}

export default SnackbarLayout

import React, { FC, useEffect, useState } from 'react';

import * as utils from '../utils';

const setStates = (open: boolean) => {
  const [isOpen, setIsOpen] = useState(open);

  return {
    isOpen,
    setIsOpen,
  }
}

export default setStates;
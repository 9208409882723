import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { post, get } from 'common/utils/api';

import * as types from 'reducers/application/articles/articles.constants';

function* hundleArticle(action: any) {
  try {
      const response = yield call(get, 'clients/articles/show', action.payload)
      if (response.success) {
        yield put({ type: types.ARTICLE_FETCH_SUCCESS, payload: response.data })
      } else {
        yield put({ type: types.ARTICLE_FETCH_ERROR, errors: response.errors })
      }
   } catch (error) {
      yield put({ type: types.ARTICLE_FETCH_ERROR, error })
   }
}

export function* article() {
  yield takeLatest(types.ARTICLE_FETCH, hundleArticle);
}

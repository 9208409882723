import React, { FC } from 'react';

import { IMobileFilters } from './MobileFilters.interface';
import { 
  FilterCategories, FilterMobileSorting 
} from 'components/application/sections';
import { Prices, Colors } from '../';

const MobileFilters: FC<IMobileFilters> = ({
}) => {

  return (
    <>
      <FilterCategories type="product" />
      <Prices />
      <Colors />
      <FilterMobileSorting
        type="products"
        params={[
          { label: 'Популярні', value: 'popular' },
          { label: 'Дешеві', value: 'priceAsc' },
          { label: 'Дорогі', value: 'priceDesc' },
        ]}
      />
    </>
  );
}

export default MobileFilters;

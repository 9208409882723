import { ReactReduxContext, useDispatch } from 'react-redux';
import { useEffect, useContext } from 'react';

import { novaPostaActions } from 'reducers/both';

import * as hooks from './';

const useAddress = (formName: string) => {
  const selectors = hooks.useSelectors();
  const state = hooks.useStates();
  const { store } = useContext(ReactReduxContext)
  const type = hooks.form.selector(store, formName, 'address.type');

  useEffect(() => {
    if (!selectors.loadedCities) {
      novaPostaActions.fetchCities(store)();
    }
  }, [])

  return {
    dispatch: useDispatch(),
    selectors,
    type,
    store,
    state,
  }
}

export default useAddress;

import React, { useState } from 'react';

import * as utils from '../utils';

const setStates = () => {
  const [width, setWidth] = useState('auto');
  const [height, setHeight] = useState('auto');

  return {
    width,
    setWidth,
    height,
    setHeight,
  }
}

export default setStates;

import React, { FC } from 'react';

import { PageHeader } from 'components/application/sections';
import { IContacts } from './Contacts.interface';

import ContactsPhoto from 'images/contact.jpeg';
import theme from './Contacts.module.scss';
import { QuestionForm, Message } from './components';
import * as hooks from './hooks';

const Contacts: FC<IContacts> = ({
  
}) => {
  const { selectors} = hooks.useContacts();

  return (
    <div className={ theme.root }>
      <PageHeader
        picture={ ContactsPhoto }
        label="Контакти"
      />
      <section className={ theme.body }>
        <div className={ theme.info }>
          <p>Телефон: +38 (068) 88 717 88 (зв’язуйтеся за допомогою Viber/Telegram. Телефон для технічних дзвінків.)</p>
          <p>Email: info@t-the-little.com</p>
        </div>
        <div className={ theme.form }>
          <QuestionForm />
        </div>
        { selectors.showMessage && <Message /> }
      </section>
    </div>
  );
}

export default Contacts;

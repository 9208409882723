import * as discountUtils from './discount';

export interface IPrice {
  price: number;
  count: number;
}

export const totalValues = (data: Array<IPrice> = []) => {
  const sum = data.reduce((sum: number, hash: IPrice) => {
    return sum + hash.price * hash.count;
  }, 0);

  const count = data.reduce((sum: number, hash: IPrice) => {
    return sum + hash.count;
  }, 0);

  return { sum, count }
}

export const price = (data: Array<IPrice> = [], paymentType?: string, promocode?: any) => {
  const { sum, count } = totalValues(data);
  const discount = discountUtils.all(sum, count, paymentType, promocode);

  return {
    price: sum*(100 - discount.total)/100,
    discount: discount,
    totalPrice: sum,
  }
}

import React, { useEffect } from 'react';
import { findKey } from 'lodash';

import { novaPostaActions } from 'reducers/both';
import { errorTab } from 'common/utils/form';
import { store } from 'store/indexApplication';

import * as hooks from './';
import * as mHooks from '../../../hooks';

const useOrderForm = () => {
  const states = hooks.useStates();
  const selectors = hooks.useSelectors();
  const mSelectors = mHooks.useSelectors();
  const pType = mHooks.form.selector('payment.type');
  const label = (pType && pType != 'novaPosta') ? 'Перейти до оплати' : 'Замовити';
  const labelSending = (pType && pType != 'novaPosta') ? 'Збереження даних' : 'Замовлення';

  const errorChecks = {
    0: ['user'],
    1: ['address'],
    2: ['payment'],
  }

  useEffect(() => {
    const warehouseId = mHooks.form.selector('address.warehouseId');
    const warehouse2Id = mHooks.form.selector('address.warehouse2Id');
    
    if (warehouseId || warehouse2Id) {
      novaPostaActions.changeWarehouse1(store)('order')(warehouseId, warehouse2Id);
      novaPostaActions.fetchWarehouses(store)();
    }
  }, [selectors.order.id, mSelectors.warehouses.length]);

  return {
    states,
    selectors,
    label,
    labelSending,
    onSubmitFail: errorTab(errorChecks, states.setTabIndex),
  }
}

export default useOrderForm;

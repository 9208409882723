import { 
  defaultTableState, defaultFormState, defaultSelectState
} from 'reducers/admin/helpers/model'

export const initialFormState = {
  ...defaultFormState,
  purchase: {},
  cleared: false,
};

export const initialTableState = {
  ...defaultTableState,
  purchases: [],
};

export const initialSelectState = {
  ...defaultSelectState,
  paidCostTypes: [],
  deliveryCostTypes: [],
  products: []
};

import { initialState } from './basket.model';
import * as types from './basket.constants';
import * as utils from './utils';

export const basket = (state = initialState, action: any) => {
  switch(action.type) {
    case types.ADD_PRODUCT: {
      return {
        ...state,
        products: utils.products.add(action.payload),
      };
    }
    case types.REMOVE_PRODUCT: {
      return {
        ...state,
        products: utils.products.remove(action.payload),
      };
    }
    case types.SYNC_DATA: {
      return {
        ...state,
        data: utils.data.sync({ state, ...action.payload }),
      };
    }
    case types.SYNC_LOCAL_STORAGE: {
      return {
        ...state,
        products: utils.localStorage.data(),
        syncedWithLStorage: true,
      };
    }
    case types.LOAD_EMPTY_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.LOADED_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case types.LOADED_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }
    case types.OPEN_MENU: {
      return {
        ...state,
        openMenu: action.payload.value,
      };
    }
    case types.CLEAR: {
      return {
        ...state,
        data: [],
        products: [],
        errors: '',
      };
    }
    default: {
      return state;
    }
  }
};

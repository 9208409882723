import React, { FC } from 'react';
import cn from 'classnames';
import { compact } from 'lodash';

import { IColors } from './Colors.interface';
import theme from './Colors.module.scss';

const Colors: FC<IColors> = ({ 
    className, 
    onChange,
    colors,
    selected = [],
    isMulti = false,
    title,
}) =>  {

  return(
    <div className={ cn([theme.root], className) }>
      { title && <div className={ theme.title }>{ title }</div> }
      <div className={ theme.colors }>
        { colors.map((color: any, index: number) => (
          <div key={index} className={ cn(theme.radio, {
            [theme.activeRadio]: selected.includes(color.id),
            }) }
          >
            <input type="radio" id={`radioColor${color.id}`} />
            <label 
              onClick={ ()=> onChange(color.id) }
              htmlFor={`radioColor${color.id}`}
              style={{
                backgroundImage: `url(${color.photo})`,
                backgroundColor: color.color,
              }}
            />
          </div>
        )) }
      </div>
    </div>
  )
}

export default Colors;

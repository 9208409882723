import { useSelector, shallowEqual } from 'react-redux';

const useSelectors = () => {
  const { form, orderShow, sending } = useSelector((state: any) => state.order, shallowEqual);

  return {
    ...form,
    orderShow,
    sending,
  }
}

export default useSelectors;

import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { post, get } from 'common/utils/api';

import * as types from 'reducers/application/products/products.constants'

function* hundleProducts(action: any) {
  try {
      const response = yield call(get, 'clients/products/load', action.payload)
      yield put({type: types.PRODUCTS_FETCH_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: types.PRODUCTS_FETCH_ERROR, error})
   }
}

export function* products() {
  yield takeLatest(types.PRODUCTS_FETCH, hundleProducts);
}


import { FormErrors } from 'redux-form';

import { errorHash } from 'common/utils/validation';
import { errors } from 'common/utils/localized';

export const payment = (values = {}): FormErrors => {
  const constraints = {
    type: {
      presence: { 
        allowEmpty: false,
        message: errors.empty,
      },
    },
  }

  return errorHash(values, constraints, { fullMessages: false })
};

export default payment;
import { FC } from 'react';
import { change }  from 'redux-form';

import * as types from './novaPosta.constants';

export interface IData {
  cityId?: number;
  warehouseId?: number;
  warehouse2Id?: number;
  store: any;
  formName?: string;
}

export const fetchWarehouses = (store: any) => (postomat) => {
  const { cityId, warehouseId, warehouse2Id, cities, warehouses } = store.getState().novaPosta;
  const w1Exists = !!warehouses.find((warehouse: any) => warehouse.id == warehouseId);
  const w2Exists = !!warehouses.find((warehouse: any) => warehouse.id == warehouse2Id);

  if (warehouses.length && w1Exists && w2Exists) { return }

  store.dispatch({
    type: types.FETCHING_WAREHOUSES,
    payload: { 
      city_id: cityId,
      warehouse_id: warehouseId, 
      warehouse_2_id: warehouse2Id,
      postomat,
    }
  })
}

export const changeWarehouses = (store: any) => (data: Array<any>) => {
  store.dispatch({
    type: types.WAREHOUSE_FETCH_SUCCESS,
    payload: { data }
  })
}

export const changeWarehouse1 = (store: any) => (formName: string = 'order') => (warehouseId: number, warehouse2Id: number | undefined) => {
  const { warehouses } = store.getState().novaPosta;
  const warehouse = warehouses.find((hash: any) => hash.id == warehouseId);
  const required = !!warehouse && warehouse.type == "поштомат";
  store.dispatch(change(formName, 'address.requiredW2', required));

  store.dispatch({
    type: types.CHANGE_WAREHOUSE_ID,
    payload: { warehouseId }
  })
  if (warehouse2Id) { changeWarehouse2(store)(warehouse2Id) }
  fetchWarehouses(store)(required);
}

export const changeWarehouse2 = (store: any) => (warehouse2Id: number) => {
  store.dispatch({
    type: types.CHANGE_WAREHOUSE_2_ID,
    payload: { warehouse2Id }
  })

  fetchWarehouses(store)(true);
}

export const changeCity = (store: any) => (cityId: number) => {
  store.dispatch({
    type: types.CHANGE_CITY,
    payload: { cityId },
  })
  fetchCities(store)();
  fetchWarehouses(store)(false);
}

export const fetchCities = (store: any) => () => {
  const { cityId, cities } = store.getState().novaPosta || {};
  const cityExists = !!cities.find((city: any) => city.id == cityId);

  if (cityExists && cities.length) { return };

  store.dispatch({
    type: types.FETCHING_CITIES,
    payload: { city_id: cityId },
  })
}

export const checkOrderData = (store: any) => () => {
  const state = store.getState();
  const { cityId: orderCityId, warehouseId: orderWarehouseId } = state.order.form.address || {};
  const { cityId } = state.novaPosta;
  if (!cityId && orderCityId) {
    changeCity(store)(orderCityId);
    fetchCities(store)();
  }
}

export const loadDataForOrder: FC<IData> = ({
  store,
  cityId,
  warehouseId,
  warehouse2Id,
  formName,
}) => {
  changeCity(store)(cityId);
  changeWarehouse1(store)(formName)(warehouseId, warehouse2Id);
  if (warehouse2Id) { store.dispatch(change(formName, 'address.requiredW2', true)) }
}

export const clear = (store: any) => {
  store.dispatch({
    type: types.CLEAR,
  })
}

import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { IRefund } from './Refund.interface';
import theme from './Refund.module.scss';
import * as hooks from './hooks';

const Refund: FC<IRefund> = ({
  
}) => {
  hooks.useRefund();

  return (
    <div className={ theme.root }>
      <div className={ theme.headerMain }>
        Обмін та повернення
      </div>

      <ul className={ theme.list }>
        <li>
          Ми здійснюємо передачу придбаних Вами товарів за допомогою сервісів доставки. Тому належність виробів перевірити Ви зможете безпосередньо у відділеннях при отриманні. Якщо Вас не задовільнить якість виробу або з інших причин — Ви можете відмовитися від отримання посилки. А ми у свою чергу, якщо була здійснена передоплата за товар на сайті або за реквізитами, при отриманні повернутої посилки протягом 10 днів повернемо Вам суму оплаченого Вами замовлення (Зверніть увагу, що комісії та інші додаткові платежі/витрати, які нам не були зараховані на розрахунковий рахунок — ми не зможемо Вам повернути). Якщо була обрана післяплата, то Ви просто відмовляєтеся від отримання і не оплачуєте за замовлення. 
        </li>
        <li>
          Отримане Вами замовлення підтверджує факт належного упакування та відповідної якості замовленого Вами товару. 
        </li>
        <li>
          Обмін та повернення здійснюється згідно чинного законодавства. Детальніше у <NavLink className={ theme.link } to="/contract">публічному договорі</NavLink>.
        </li>
      </ul>
    </div>
  );
}

export default Refund;

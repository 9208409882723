import React, { FC } from 'react';
import { reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import cn from 'classnames';

import { Button, SubmitButton } from 'common/ui';

import { FormProps } from './Form.interface';
import theme from './Form.module.scss';

const FormLayout: FC<FormProps> = ({
  onSubmit,
  handleSubmit,
  buttonLabel = 'Зберегти',
  savingLabel = 'Збереження',
  title,
  children,
  showButtons = true,
  className = '',
  saving = false,
  ...props
}) => {
  return (
    <div className={ theme.root }>
      { title && <h3 className={ theme.title }>{ title }</h3> }

      <form
        className={className} 
        onSubmit={ handleSubmit(onSubmit) }
      >

        <Grid container className={ theme.body }>
           { children }
        </Grid>
        {
          showButtons &&
          <SubmitButton
            sending={ saving }
          />
        }
      </form>
    </div>
  );
};

export default reduxForm({
  enableReinitialize: true,
})(FormLayout);

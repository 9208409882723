import React  from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';

import { Icon } from 'common/ui';
import theme from './Table.module.scss';

const sortedColumn = (columns, sorted) => {
  const id = sorted.id
  if (id !== 0 && !id) { return columns }

  let hash = columns[id]
  hash['defaultSort'] = sorted['type']
  columns[id] = hash
  return columns
}

const actionColumn = (columns, path, showPath) => {

  columns.push({
    filtering: false,
    width: 100,
    cellStyle: {
      maxWidth: 100,
    },
    render: rowData => { 
    const showLink = showPath ? `${showPath}/${rowData.id}` : `/admin/${path}/${rowData.id}`;

      return (
        <div>
          <Tooltip title="Переглянути">
            <NavLink to={showLink}
            onClick={ ()=> showPath && (window.location.href = showLink) }
            >
              <Icon name="visibility" className={ theme.icon } />
            </NavLink>
          </Tooltip>

          <Tooltip title="Редагувати">
            <NavLink to={`/admin/${path}/${rowData.id}/edit`}>
              <Icon name="edit" className={ theme.icon } />
            </NavLink>
          </Tooltip>
        </div>
    )}
  })
  return columns
}

export const preparedColumns = (columns: any, sorted: any = {}, path: string = '', showPath?: string,) => {
  let parsedColumns = sortedColumn(columns, sorted)
  parsedColumns = actionColumn(parsedColumns, path, showPath)
  return parsedColumns
}

export const handleOnOrderChange = (changeTableValue, columns) =>
  (orderedColumnId, orderDirection) => {
    if (orderDirection) {
      changeTableValue({
        sorted: {
          column: columns[orderedColumnId].backColumn || columns[orderedColumnId].field,
          type: orderDirection,
          id: orderedColumnId,
          searchFocus: false
        },
        page: 0
      })
    } else {
      changeTableValue({ sorted: {} })
    }
  }

export const handleOnChangeRowsPerPage = (changeTableValue) =>
  (event, element)=>
    changeTableValue({
      pageSize: element.props.value,
      page: 0,
      searchFocus: false
    })

import { store } from 'store/indexApplication';

import * as types from './recomendations.constants';

export const fetchData = (params: any = {}) => {
  const { limit, excludeIds, productId } = store.getState().recomendations;

  store.dispatch({
    type: types.FETCH_DATA,
    payload: { limit, productId, excludeIds, ...params }
  })
}

export const changeTitle = (title: string) => {
  store.dispatch({
    type: types.CHANGE_TITLE,
    payload: { title }
  })
}

export const changeExclude = (excludeIds: number[] = []) => {
  store.dispatch({
    type: types.CHANGE_EXCLUDE,
    payload: { excludeIds }
  })
}

export const changeProductId = (productId?: number) => {
  store.dispatch({
    type: types.CHANGE_PRODUCT_ID,
    payload: { productId }
  })
}
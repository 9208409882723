import { useSelector, shallowEqual } from 'react-redux';

const useSelectors = () => {
  const { data } = useSelector((state: any) => state.basket, shallowEqual);
  const { couldPay } = useSelector((state: any) => state.order, shallowEqual);

  return {
    data,
    couldPay,
  }
}

export default useSelectors;

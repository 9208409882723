import React, { FC } from 'react';
import cn from 'classnames';

import { ColorInputProps } from './Input.interface';
import { ChromePicker } from 'react-color';

import { ColorDialog } from './components'
import theme from './ColorInput.module.scss';

const ColorInput: FC<ColorInputProps> = ({
  className,
  id,
  input,
  label,
  meta: { touched, error },
  onColorChange,
  openDialog,
  dialogChange
}) => {
  const { value } = input

  return(
    <div className={ cn({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      { label && <label className={ theme.label } htmlFor={ id }>
          { label }
        </label>
      }

      <div
        className={ cn(theme.inputField, theme.addBtn) }
        onClick={()=>{ dialogChange(!openDialog) }}
      >
        { value &&
          <span className={ theme.color }  style={{  background: value }}/> ||
          <span className={ theme.add }>Натисни, щоб додати колір...</span>
        }
      </div>

      <ColorDialog
        openDialog={openDialog}
        value={ value }
        dialogChange={ dialogChange }
        onColorChange={ onColorChange }
      />

      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
  )};

export default ColorInput;

import { call, put, takeLatest } from 'redux-saga/effects'
import { post } from 'common/utils/api'

import {
  SELECT_FETCH_STATUSES,
  SELECT_STATUS_SUCCESS,
  SELECT_STATUS_FAIL,
} from 'reducers/admin/purchases/purchases.constants';

function* fetchPurchasesStatuses(action: any) {
  try {
      const response = yield call(post, 'purchases/get_statuses', { ...action.payload })
      yield put({type: SELECT_STATUS_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: SELECT_STATUS_FAIL, error})
   }
}

export function* purchasesSelectStatuesFetchFlow() {
  yield takeLatest(SELECT_FETCH_STATUSES, fetchPurchasesStatuses)
}

export const TAGS_TABLE_FETCH   = 'TAGS/TABLE/FETCH';
export const TAGS_TABLE_SUCCESS = 'TAGS/TABLE/SUCCESS';
export const TAGS_TABLE_FAIL    = 'TAGS/TABLE/FAIL';
export const TAGS_TABLE_VALUES  = 'TAGS/TABLE/VALUES';
export const TAGS_TABLE_SEARCH  = 'TAGS/TABLE/SEARCH';

export const TAG_FORM_FETCH   = 'TAGS/FORM/FETCH';
export const TAG_FORM_SUCCESS = 'TAGS/FORM/SUCCESS';
export const TAG_FORM_FAIL    = 'TAGS/FORM/FAIL';
export const TAG_FORM_UPDATE  = 'TAGS/FORM/UPDATE';
export const TAG_FORM_CREATE  = 'TAGS/FORM/CREATE';

export const TAG_SELECT_FETCH            = 'TAGS/SELECT/FETCH';
export const TAG_SELECT_SUCCESS          = 'TAGS/SELECT/SUCCESS';
export const TAG_SELECT_FAIL             = 'TAGS/SELECT/FAIL';
export const TAG_SELECT_REQUIRED_FETCH   = 'TAGS/SELECT/REQUIRED_FETCH';
export const TAG_SELECT_REQUIRED_SUCESS  = 'TAGS/SELECT/REQUIRED_SUCESS';
export const TAG_SELECT_CLEAR            = 'TAGS/SELECT/CLEAR';

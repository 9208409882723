import { store } from 'store/indexApplication';
import { orderActions, promocodesActions } from 'reducers/application';

import * as types from './basket.constants';
import * as utils from './utils';

export const addProduct = (params: any) => {
  const { productId, colorId } = params;
  const product = store.getState().basket.products.find((hash: any) =>{
    return (hash.productId == productId) && (hash.colorId == colorId) 
  })

  store.dispatch({
    type: types.ADD_PRODUCT,
    payload: params,
  })

  orderActions.order.changeCouldPay();

  product ? syncData(params) : loadEmptyData()
}

export const removeProduct = (params: any) => {
  store.dispatch({
    type: types.REMOVE_PRODUCT,
    payload: params
  })

  orderActions.order.changeCouldPay();
  syncData(params);
}

export const syncLStorage = () => {
  store.dispatch({
    type: types.SYNC_LOCAL_STORAGE
  })

  loadEmptyData();
}

export const loadEmptyData = () => {
  const { products } = store.getState().basket;
  if (!products.length) { return }

  store.dispatch({
    type: types.LOAD_EMPTY_DATA,
    payload: { products },
  })
}

export const syncData = (params: any) => {
  store.dispatch({
    type: types.SYNC_DATA,
    payload: params
  })
}

export const openMenu = (value: boolean) => {
  store.dispatch({
    type: types.OPEN_MENU,
    payload: { value },
  })
}

export const clear = () => {
  utils.localStorage.clear();
  promocodesActions.clearData();

  store.dispatch({
    type: types.CLEAR
  })
}
import { initialFormState } from './orders.model';
import {
  ORDER_FORM_FETCH,
  ORDER_FORM_SUCCESS,
  ORDER_FORM_FAIL,
  ORDER_FORM_UPDATE,
  ORDER_FORM_CREATE,
  ORDER_FORM_SYNC_USER,
  ORDER_FORM_CHECK_USER,
  ORDER_FORM_FETCH_INITIAL,
  ORDER_FORM_INITIAL_SUCCESS
} from './orders.constants';

export const form = (state = initialFormState, action: any) => {
  switch(action.type) {
    case ORDER_FORM_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case ORDER_FORM_UPDATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case ORDER_FORM_CREATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case ORDER_FORM_SUCCESS: {
      return {
        ...state,
        order: action.payload,
        loading: false,
        error: '',
      };
    }
    case ORDER_FORM_FETCH_INITIAL: {
      return {
        ...state,
        order: {},
        initial: {},
        loading: false,
        error: '',
      };
    }
    case ORDER_FORM_INITIAL_SUCCESS: {
      return {
        ...state,
        order: action.payload,
        initial: action.payload,
        checkInitial: true,
        loading: false,
        error: '',
      };
    }
    case ORDER_FORM_SYNC_USER: {
      return {
        ...state,
        order: {
          ...state.order,
          user: action.payload.user || state.order.user || {},
        },
        lastAddress: action.payload.address || {},
        loading: false,
        error: '',
      };
    }
    case ORDER_FORM_CHECK_USER: {
      return {
        ...state,
        order: action.payload.order,
        loading: true,
        error: '',
      };
    }
    case ORDER_FORM_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch order',
      };
    }
    default: {
      return state;
    }
  }
};

import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

const useHeader = () => {
  const { openMenu } = useSelector((state: any) => state.basket, shallowEqual);

  // const [isDrawerOpened, setIsDrawerOpened] = useState(openMenu);

  return {
    isDrawerOpened: openMenu,
  }
}

export default useHeader;

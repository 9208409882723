import React, { FC, useEffect, useState } from 'react';

const setStates = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [filtersSize, setFiltersSize] = useState(0);

  return {
    menuActive,
    setMenuActive,
    filtersSize,
    setFiltersSize,
  }
}

export default setStates;

import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { startSubmit, stopSubmit, reset } from 'redux-form'
import { post, patch } from 'common/utils/api'
import { store } from 'store/indexAdmin';
import { preparedFormData } from 'common/utils/preparedData'

import {
  OUTLAY_FORM_FETCH,
  OUTLAY_FORM_SUCCESS,
  OUTLAY_FORM_FAIL,
  OUTLAY_FORM_UPDATE,
  OUTLAY_FORM_CREATE,
} from 'reducers/admin/outlays/outlays.constants';
import { showSuccess, showError } from 'reducers/admin/snackbar/snackbar.actions'

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* outlayResponse(response, message = null) {
  if (response.success) {
    const { authorization: { outlay } } = store.getState()
    yield put(stopSubmit('outlay'))

    yield put({type: OUTLAY_FORM_SUCCESS, payload: response.data})
    const responseId = response.data.id

    if (responseId) {
      yield put(push(`/admin/outlays/${responseId}/edit`))
    }
    if (message) { showSuccess(message) }
  } else {
    yield put({type: OUTLAY_FORM_FAIL, payload: { error: response.error }})
    if (response.data.messages) {
      yield put(stopSubmit('outlays', response.data.messages))
    }
  }
}

function* fetchOutlay(action: any) {
  try {
    const response = yield call(post, 'outlays/get_info', action.payload)
    yield call(outlayResponse, response)
  } catch (error) {
    yield put({type: OUTLAY_FORM_FAIL, error})
    showError(error)
  }
}

function* updateOutlay(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('outlay'))
    const response = yield call(patch, 'outlays', formData)

    yield call(outlayResponse, response, "Видаток був оновлений")
  } catch (error) {
    yield put({type: OUTLAY_FORM_FAIL, error})
    showError(error)
  }
}

function* createOutlay(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('outlay'))
    const response = yield call(post, 'outlays', formData)

    yield call(outlayResponse, response, "Видаток був створений")
  } catch (error) {
    yield put({type: OUTLAY_FORM_FAIL, error})
    showError(error)
  }
}

export function* outlayFormFetchFlow() {
  yield takeLatest(OUTLAY_FORM_FETCH, fetchOutlay)
}

export function* outlayFormCreateFlow() {
  yield takeLatest(OUTLAY_FORM_CREATE, createOutlay)
}

export function* outlayFormUpdateFlow() {
  yield takeLatest(OUTLAY_FORM_UPDATE, updateOutlay)
}

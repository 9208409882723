export const warehouses2 = (data: Array<any>) => warehouses(data.filter((hash: any) => {
  return hash.type !== 'поштомат'
}))

export const warehouses = (data: Array<any>) => data.map((hash: any) => {
  const weight = hash.weight ? `, до ${hash.weight} кг` : '';

  return {
    value: hash.id,
    label: ` №${hash.number}: ${hash.type}${weight} (${hash.description})`
  }
})

export const cities = (data: Array<any>) => data.map((hash: any) => {
  return {
    value: hash.id,
    label: `${hash.type} ${hash.description}`
  }
})
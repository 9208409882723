import { uniqBy } from 'lodash';

export const success = (state: any, action: any) => {
  return {
    ...state,
    products: uniqBy([...state.products, ...action.payload], 'id'),
    loading: false,
    error: '',
  };
}

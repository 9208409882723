import React, { FC } from 'react';
import cn from 'classnames';

import { CarouselImages } from 'common/ui';

import { ISlider } from './Slider.interface';
import theme from './Slider.module.scss';

const Slider: FC<ISlider> = ({ className, images, product }) => (
  <div
    className={ cn([theme.root], className) }
  >
    <div className={ theme.title }>{ product.label }</div>
    <CarouselImages alt={ product.label } images={ images } />
  </div>
);

export default Slider;
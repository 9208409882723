import { initialFormState } from './outlays.model';
import {
  OUTLAY_FORM_FETCH,
  OUTLAY_FORM_SUCCESS,
  OUTLAY_FORM_FAIL,
  OUTLAY_FORM_UPDATE,
  OUTLAY_FORM_CREATE
} from './outlays.constants';

export const form = (state = initialFormState, action: any) => {
  switch(action.type) {
    case OUTLAY_FORM_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case OUTLAY_FORM_UPDATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case OUTLAY_FORM_CREATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case OUTLAY_FORM_SUCCESS: {
      return {
        ...state,
        outlay: action.payload,
        loading: false,
        error: '',
      };
    }
    case OUTLAY_FORM_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch outlay',
      };
    }
    default: {
      return state;
    }
  }
};

import { 
  defaultTableState, defaultFormState, defaultSelectState
} from 'reducers/admin/helpers/model'

export const initialFormState = {
  ...defaultFormState,
  promocode: {
  },
};

export const initialTableState = {
  ...defaultTableState,
  promocodes: []
};

export const initialSelectState = {
  ...defaultSelectState,
  promocodes: [],
};

import React, { useEffect } from 'react';
import { join } from 'lodash';

import * as hooks from './';


const useCarouselImages = (images) => {
  const states = hooks.useStates();

  useEffect(() => {
    states.setItemIndex(0);
  }, [join(images, '-')]);

  return {
    states,
  }
}

export default useCarouselImages;
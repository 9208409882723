import React, { FC } from 'react';
import cn from 'classnames';

import { filtersActions } from 'reducers/application';
import { Icon, GreyButton } from 'common/ui';

import * as hooks from './hooks';
import { IFilters } from './Filters.interface';
import theme from './Filters.module.scss';
import { MobileFilters } from '../';

const Filters: FC<IFilters> = ({
  className,
  fixFilters,
  type,
  children,
  mobileChild,
}) => {
  const { showClean, states, filterText } = hooks.useFilters(fixFilters);

  return (
    <div className={ cn({
        },
        [theme.root],
        className,
      )}
    >
      <div className={ theme.desctop } >
        <div className={ theme.title }>
          Сортувати за:
          { showClean && <div className={ theme.clean }
              onClick={ () => filtersActions.cleanFilters(type) }
            >
              Очистити 
              <Icon className={theme.icon} name="close-outlined"/>
            </div>
          }
        </div>
        { children }
      </div>

      <div className={ cn({
            [theme.fixed]: fixFilters
          }, theme.mobActions) }>

        <GreyButton 
          className={ cn(theme.mobileBtn) }
          label={ filterText }
          onClick={ ()=> states.setMenuActive(!states.menuActive) }
        />
      </div>
      <MobileFilters 
        states={ states }
        filterText={ filterText }
        type={ type }
      >
        { mobileChild }
      </MobileFilters>
    </div>
  );
}

export default Filters;

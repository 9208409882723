import React, { FC } from 'react';
import cn from 'classnames';
import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { priceView } from 'common/utils/humanView';
import { Picture } from 'common/ui';

import { IProduct } from './Product.interface';
import theme from './Product.module.scss';

const Product: FC<IProduct> = ({
  picture,
  className,
  label,
  price,
  path,
  message = "Купити",
  showTitle = false,
  photos,
  original = "550",
  showSalePrice,
  salePrice,
}) => {
  return (
    <div className={ cn({
        },
        [theme.root],
        className,
      )}
    >
      <NavLink
        className={ theme.photo }
        to={ path }
      >
        <Picture 
          {...photos}
          original={ original }
          sizes={[
            { screen: 769, picture: 250 }
          ]}
        />
        <div className={ theme.buy } >
          { message }
        </div>

      </NavLink>

      <NavLink 
        className={ cn({
          [theme.info]: !showTitle,
        }, theme.title) }
        to={ path }
      >
        <div className={ theme.name }>{ label }</div>
        { !!price && 
          <div className={ theme.price }>
            { showSalePrice && 
              <div className={theme.sale}>
                { priceView(salePrice) }
              </div>
            }
            { priceView(price) }
          </div>
        }
      </NavLink>
    </div>
  );
}

export default Product;

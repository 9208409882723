import React, { FC, useState, useCallback } from 'react';
import cn from 'classnames';

import { Icon } from 'common/ui';
import { IImages } from './Images.interface';
import theme from './Images.module.scss';

import { Image } from '../';

const Images: FC<IImages> = ({ 
  className, 
  states,
  removeImage,
  onSelectFile,
  id,
  moveImage,
}) =>  {
 
  return(
    <div className={ cn(theme.root, className) }>
      { states.images.map((image: any, index: number) => (
        <Image
          key={image.link}
          index={index}
          link={image.link}
          moveImage={moveImage}
          removeImage={ removeImage }
        />
      ))}
      <input
        type="file"
        accept="image/*"
        onChange={ onSelectFile }
        id={ id }
        className={ theme.pictureInput }
        multiple
      />
      <label className={ theme.addImage } htmlFor={ id }>
        <Icon name="add" className={ theme.addIcon }/>
        Фото
      </label>
    </div>
  )
}

export default Images;

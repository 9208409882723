import React, { FC, useEffect } from 'react';

import { metaTagsActions } from 'reducers/application';
import * as hooks from './';
import Photo from 'images/contact.jpeg';

const useContacts = () => {
  const selectors = hooks.useSelectors();

  useEffect(() => {
    metaTagsActions.change({
      title: 'T.The.Little - контактні дані',
      image: Photo,
    });
  }, [selectors.pathname]);

  return {
    selectors,
  }
}

export default useContacts;

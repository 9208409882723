export const initialState = {
  loading: false,
  loadedWarehouses: false,
  loadedCities: false,
  cities: [],
  cityId: null,
  warehouseId: null,
  warehouse2Id: null,
  warehouses: [],
  errors: '',
  query: '',
};

import React, { FC } from 'react';
import classNames from 'classnames';

import { IconProps } from './Icon.interface';
import theme from './Icon.module.scss';

const Icon: FC<IconProps> = ({ name, className, ...props}) => (
  <div
    {...props}
    className={ classNames(theme.root, className, 'material-icons') }
  >
    { name.split('-').join('_') }
  </div>
);

export default Icon;
import { initialState } from './recomendations.model';
import * as types from './recomendations.constants';

export const recomendations = (state = initialState, action: any) => {
  switch(action.type) {
    case types.FETCH_DATA: {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }
    case types.FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: null,
        data: action.payload,
      };
    }
    case types.FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }
    case types.CHANGE_TITLE: {
      return {
        ...state,
       title: action.payload.title,
      };
    }
    case types.CHANGE_EXCLUDE: {
      return {
        ...state,
       excludeIds: action.payload.excludeIds,
      };
    }
    case types.CHANGE_PRODUCT_ID: {
      return {
        ...state,
       productId: action.payload.productId,
      };
    }
    default: {
      return state;
    }
  }
};

import { uniq } from 'lodash'

import { initialSelectState } from './colors.model';
import {
  COLOR_SELECT_FETCH,
  COLOR_SELECT_SUCCESS,
  COLOR_SELECT_FAIL,
  COLOR_SELECT_REQUIRED_FETCH,
  COLOR_SELECT_REQUIRED_SUCESS,
  COLOR_SELECT_CLEAR,
} from './colors.constants';

export const select = (state = initialSelectState, action: any) => {
  switch(action.type) {
    case COLOR_SELECT_FETCH: {
      return {
        ...state,
        phrase: action.payload.phrase,
        selected: action.payload.selected,
        loading: true,
        error: '',
      };
    }
    case COLOR_SELECT_SUCCESS: {
      return {
        ...state,
        colors: action.payload,
        loading: false,
        error: '',
      };
    }
    case COLOR_SELECT_CLEAR: {
      return {
        ...state,
        colors: [],
        selected: [],
        loading: false,
        error: '',
      };
    }
    case COLOR_SELECT_REQUIRED_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case COLOR_SELECT_REQUIRED_SUCESS: {
      return {
        ...state,
        selected: uniq([...state.selected, ...action.payload]),
        loading: true,
        error: '',
      };
    }
    case COLOR_SELECT_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch colors',
      };
    }
    default: {
      return state;
    }
  }
};

import React, { FC } from 'react';
import cn from 'classnames';

import { Collapse } from 'common/ui';
import { filtersActions } from 'reducers/application';

import * as hooks from './hooks';
import { ICategories } from './Categories.interface';
import theme from './Categories.module.scss';

const Categories: FC<ICategories> = ({
  className,
  type,
}) => {

  const { isActive, categories } = hooks.useCategories();

  return (
    <div className={ cn({
        },
        [theme.root],
        className,
      )}
    >
      <Collapse 
        title="Категорією"
        open
      >
        <ul>
          <li className={cn({
            [theme.optionActive]: isActive(0)
            }, theme.option)}
            onClick={ ()=> filtersActions.changeCategory(0, type) }
          >
            Усі
          </li>
          { categories.map((category: any, index: number) => (
            <li className={cn({
              [theme.optionActive]: isActive(category.id)
              }, theme.option)}
              key={ index }
              onClick={ ()=> filtersActions.changeCategory(category.id, type) }
            >
              { category.name }
            </li>
           )) }
        </ul>
      </Collapse>
    </div>
  );
}

export default Categories;

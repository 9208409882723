import React, { FC } from 'react';
import cn from 'classnames';

import { basketActions } from 'reducers/application';
import * as hooks from './hooks';
import { IBasket } from './Basket.interface';

import theme from './Basket.module.scss';

const Basket: FC<IBasket> = ({
  className,
}) => {
  const { size } = hooks.useBasket();

  return (
    <div 
      className={ cn(theme.root, className) }
      onClick={ () =>  basketActions.openMenu(true) }
    >
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        version="1.1" 
        width="100%" 
        height="100%" 
        viewBox="0 0 170 134.5"
        data-hook="svg-basket"
      >
        <path d="M74.1 134.4c-8.7 0-16.2-7.4-16.2-16.2S65.3 102 74.1 102s16.2 7.4 16.2 16.2-7.4 16.2-16.2 16.2zm0-21.7c-3.1 0-5.6 2.5-5.6 5.6s2.5 5.6 5.6 5.6 5.6-2.5 5.6-5.6-2.5-5.6-5.6-5.6zM120.5 134.5c-8.7 0-16.2-7.4-16.2-16.2s7.4-16.2 16.2-16.2 16.2 7.4 16.2 16.2-7.4 16.2-16.2 16.2zm0-21.7c-3.1 0-5.6 2.5-5.6 5.6s2.5 5.6 5.6 5.6c3.1 0 5.6-2.5 5.6-5.6s-2.5-5.6-5.6-5.6z" />
        <path d="M141.2 92.1L53.5 92.1 23 10.6 0 10.6 0 0 30.4 0 61.2 81.6 133.5 81.6 152.4 30.4 38.5 30.4 34.8 19.9 167.9 19.9z"/>
      </svg>
      <div className={ theme.size }>
        { size }
      </div>
    </div>
  );
}

export default Basket;

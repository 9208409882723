import React, { FC } from 'react';
import classNames from 'classnames';

import { Grid } from '@material-ui/core';

import { IPageHeader } from './PageHeader.interface';
import theme from './PageHeader.module.scss';

const renderInformation = (label, render) => {
  if (render) { return render() }

  return (
     <h1>{ label }</h1>
  )
}

const PageHeader: FC<IPageHeader> = ({
  picture,
  className,
  label,
  render,
  animated = false,
}) => {
  return (
    <section className={ classNames({
          [theme.animated]: animated,
        },
        [theme.root],
        className,
      )}
    >
      <div className={ theme.info }>
        <div className={ theme.text }>
          { renderInformation(label, render) }
        </div>
      </div>
      <div 
        className={ theme.photo } 
        style={{ 
          backgroundImage: `url(${picture})`,
        }}
      />
    </section>
  );
}

export default PageHeader;

import { initialSelectState } from './outlays.model';
import {
  OUTLAY_SELECT_FETCH_STATUSES,
  OUTLAY_SELECT_STATUS_SUCCESS,
  OUTLAY_SELECT_STATUS_FAIL
} from './outlays.constants';

export const select = (state = initialSelectState, action: any) => {
  switch(action.type) {
    case OUTLAY_SELECT_FETCH_STATUSES: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case OUTLAY_SELECT_STATUS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: '',
      };
    }
    case OUTLAY_SELECT_STATUS_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch statuses',
      };
    }
    default: {
      return state;
    }
  }
};

import React, { FC } from 'react';
import classNames from 'classnames';

import { ILinks } from './Links.interface';

import theme from './Links.module.scss';
import { NavLink } from '../';

const Links: FC<ILinks> = ({
  className,
}) => {
  return (
    <div className={ classNames([theme.root], className) }>
      <NavLink 
        path="/"
        name="Головна"
      />
      <NavLink 
        path="/shop"
        name="Товари"
      />
      <NavLink 
        path="/articles"
        name="Поради"
      />
      <NavLink 
        path="/contacts"
        name="Контакти"
      />
      <div className={ theme.menu }>
        <div className={ theme.title }>Інфо</div>
        <ul className={ theme.list }>
          <li>
            <NavLink 
              path="/delivery"
              name="Доставка"
            />
          </li>
          <li>
            <NavLink 
              path="/payment"
              name="Оплата"
            />
          </li>
          <li>
            <NavLink 
              path="/refund"
              name="Обмін та повернення"
            />
          </li>
        </ul>
      </div>
      
    </div>
  );
}

export default Links;

import React, { FC } from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';

import { FileInput as File } from 'common/ui';
import { IFileInput } from './FileInput.interface'
import theme from './FileInput.module.scss';

const FileInput: FC<IFileInput> = ({
  name,
  label,
  onChange,
  className,
  ...props
}) => {

  return (
    <Field
      { ...props }
      className={ cn(theme.root, className) }
      name={ name }
      label={ label || name }
      id={ name }
      onChange={ onChange }
      component={ File }
    />
  )
}

export default FileInput;
import React, { useState } from 'react';

const useStates = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return {
    tabIndex,
    setTabIndex,
  }
}

export default useStates;

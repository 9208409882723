const useSelect = (options: Array<any>, input: any, elementChange: any) => {
  const value = options.find((option) => option.value === input.value)
  const onSelectChange = (event)=> {
    input.onChange(event.value)
    if(elementChange) { elementChange(event.value) }
  }

  return {
    value,
    onSelectChange,
  }
}

export default useSelect;

export const initialState = {
  filters: { prices: {}, colors: [], categories: [], tags: [] },
  categories: [],
  colors: [],
  tags: [],
  prices: [],
  loading: false,
  erros: null,
  loaded: false,
};

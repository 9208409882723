import React, { FC } from 'react';
import classNames from 'classnames';

import { IRadioCheck } from './RadioCheck.interface';
import theme from './RadioCheck.module.scss';

const RadioCheck: FC<IRadioCheck> = ({
  className,
  params,
  label,
  name,
  onChange,
}) => {
  return (
    <div className={ classNames(
        [theme.root],
        className,
      )}
    >
     <div className={ theme.name }>{ label }</div>
     {params.map((param, index) => (
        <div className={ theme.group } key={ index }>
          <input 
            type="radio" 
            id={`${name}${param.value}`} 
            name={ name } 
            value={ param.value }
            checked={ param.checked }
            onChange={ onChange }
          />
          <label htmlFor={`${name}${param.value}`}>{ param.label }</label>
        </div>
      ))}
    </div>
  );
}

export default RadioCheck;

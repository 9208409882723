import React, { FC } from 'react';

import { PageHeader } from 'components/application/sections';
import { IShow } from './Show.interface';
import { HtmlText } from 'common/ui';

import * as hooks from './hooks';
import ArticlePhoto from 'images/article.jpeg';
import theme from './Show.module.scss';

const Show: FC<IShow> = ({
  id,
}) => {
  const { article } = hooks.useShow(id);

  return (
    <div className={ theme.root }>
      <PageHeader
        picture={ article.photo }
        label={ article.name }
      />
      <div className={ theme.body }>
        <HtmlText className={ theme.description1 } html={ article.description1 } />
        { article.topic && <div className={ theme.topic }>
          <div className={ theme.text }>{ article.topic }</div>
        </div> }
        <HtmlText className={ theme.description2 } html={ article.description2 } />
      </div>
    </div>
  );
}

export default Show;

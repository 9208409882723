export const OUTLAYS_TABLE_FETCH      = 'OUTLAYS/TABLE/FETCH';
export const OUTLAYS_TABLE_SUCCESS    = 'OUTLAYS/TABLE/SUCCESS';
export const OUTLAYS_TABLE_FAIL       = 'OUTLAYS/TABLE/FAIL';
export const OUTLAYS_TABLE_VALUES     = 'OUTLAYS/TABLE/VALUES';
export const OUTLAYS_TABLE_SEARCH     = 'OUTLAYS/TABLE/SEARCH';
export const OUTLAYS_TABLE_DATE_RANGE = 'OUTLAYS/TABLE/DATE_RANGE';

export const OUTLAY_FORM_FETCH   = 'OUTLAYS/FORM/FETCH';
export const OUTLAY_FORM_SUCCESS = 'OUTLAYS/FORM/SUCCESS';
export const OUTLAY_FORM_FAIL    = 'OUTLAYS/FORM/FAIL';
export const OUTLAY_FORM_UPDATE  = 'OUTLAYS/FORM/UPDATE';
export const OUTLAY_FORM_CREATE  = 'OUTLAYS/FORM/CREATE';

export const OUTLAY_SELECT_FETCH_STATUSES = 'OUTLAYS/SELECT/FETCH_STATUSES';
export const OUTLAY_SELECT_STATUS_SUCCESS = 'OUTLAYS/SELECT/STATUS_SUCCESS';
export const OUTLAY_SELECT_STATUS_FAIL    = 'OUTLAYS/SELECT/STATUS_FAIL';

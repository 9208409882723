import { initialFormState } from './users.model';
import {
  USER_FORM_FETCH,
  USER_FORM_SUCCESS,
  USER_FORM_FAIL,
  USER_FORM_UPDATE,
  USER_FORM_CREATE
} from './users.constants';

export const form = (state = initialFormState, action: any) => {
  switch(action.type) {
    case USER_FORM_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case USER_FORM_UPDATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case USER_FORM_CREATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case USER_FORM_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: '',
      };
    }
    case USER_FORM_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch user',
      };
    }
    default: {
      return state;
    }
  }
};

import { 
  defaultTableState, defaultFormState, defaultSelectState
} from 'reducers/admin/helpers/model'

export const initialFormState = {
  ...defaultFormState,
  product: {
    colors: [{}]
  }
};

export const initialTableState = {
  ...defaultTableState,
  products: [],
};


export const initialSelectState = {
  ...defaultSelectState,
  products: [],
};
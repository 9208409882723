export const initialState = {
  data: [],
  page: 1,
  limit: 12,
  erros: null,
  loading: false,
  leftMore: true,
  sorting: 'popular',
  refresh: false,
  search: '',
  article: {
    tags: [],
  }
};

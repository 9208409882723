import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from 'store/indexApplication';
import RootApplication from './RootApplication';

const ApplicationApp = (props) => {
  return (
    <Provider store={ store }>
      <RootApplication/>
    </Provider>
  )
};

export default ApplicationApp;
import { preparedBackForm } from 'common/utils/preparedData';
import { store } from 'store/indexApplication';

import * as types from '../order.constants';
import * as actions from './';

export const start = ()=> {
  actions.order.setToStorage();
  actions.order.changeCouldPay();
}

export const site = ()=> {
  const { id: orderId, payment: { id: paymentId } } = store.getState().order.form;
  
  store.dispatch({
    type: types.PAYMENT_SITE,
    payload: { orderId, paymentId },
  })
}

export const update = (values: any) => {
  const { id: orderId, payment: { id: paymentId } } = store.getState().order.form;

  store.dispatch({
    type: types.PAYMENT_UPDATE,
    payload: preparedBackForm({ ...values, orderId, paymentId })
  });
};

import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects';

import { get } from 'common/utils/api';
import { preparedBackForm } from 'common/utils/preparedData'

import * as types from 'reducers/application/recomendations/recomendations.constants';

function* hundleData(action: any) {
  try {
      const response = yield call(get, 'clients/recomendations/load', preparedBackForm(action.payload))
      yield put({type: types.FETCH_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: types.FETCH_ERROR, error})
   }
}

export function* data() {
  yield takeLatest(types.FETCH_DATA, hundleData);
}


import { lazy } from 'react';

export const MainPage = lazy(() => import('./MainPage/MainPage'));
export const ShopPage = lazy(() => import('./ShopPage/ShopPage'));
export const ContactsPage = lazy(() => import('./ContactsPage/ContactsPage'));
export const NotFoundPage = lazy(() => import('./NotFoundPage/NotFoundPage'));
export const ProductShowPage = lazy(() => import('./ProductShowPage/ProductShowPage'));
export const ContractPage = lazy(() => import('./ContractPage/ContractPage'));
export const DeliveryPage = lazy(() => import('./DeliveryPage/DeliveryPage'));
export const PaymentPage = lazy(() => import('./PaymentPage/PaymentPage'));
export const RefundPage = lazy(() => import('./RefundPage/RefundPage'));
export const ArticleMainPage = lazy(() => import('./ArticlePages/Main/Main'));
export const ArticleShowPage = lazy(() => import('./ArticlePages/Show/Show'));

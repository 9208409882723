import { initialState } from './question.model';
import * as types from './question.constants';

export const question = (state = initialState, action: any) => {
  switch(action.type) {
    case types.QUESTION_CREATE: {
      return {
        ...state,
        sending: true,
      };
    }
    case types.QUESTION_CREATE_SUCCESS: {
      return {
        ...state,
        sending: false,
        showMessage: true,
      };
    }
    case types.QUESTION_CREATE_FALSE: {
      return {
        ...state,
        sending: false,
      };
    }
    case types.SHOW_MESSAGE: {
      return {
        ...state,
        showMessage: action.payload.showMessage,
      };
    }
    default: {
      return state;
    }
  }
};

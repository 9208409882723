import { store } from 'store/indexApplication';

import * as types from '../order.constants';

export const setData = () => {
  const { id: orderId } = store.getState().order.form;

  store.dispatch({
    type: types.MESSAGE_SET_DATA,
    payload: { orderId }
  });
};

export const show = (value: boolean) => {
  setData();

  store.dispatch({
    type: types.MESSAGE_SHOW,
    payload: value,
  });
};

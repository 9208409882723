export const USERS_TABLE_FETCH = 'USERS/TABLE/FETCH';
export const USERS_TABLE_SUCCESS = 'USERS/TABLE/SUCCESS';
export const USERS_TABLE_FAIL = 'USERS/TABLE/FAIL';
export const USERS_TABLE_VALUES = 'USERS/TABLE/VALUES';
export const USERS_TABLE_SEARCH = 'USERS/TABLE/SEARCH';

export const USER_FORM_FETCH = 'USERS/FORM/FETCH';
export const USER_FORM_SUCCESS = 'USERS/FORM/SUCCESS';
export const USER_FORM_FAIL = 'USERS/FORM/FAIL';
export const USER_FORM_UPDATE = 'USERS/FORM/UPDATE';
export const USER_FORM_CREATE = 'USERS/FORM/CREATE';

import React, { FC, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import * as payInfoUtils from 'components/application/PayInfo/utils';
import { price as preparePrice } from 'common/utils/calculate';
import { metaTagsActions } from 'reducers/application';
import * as hooks from './';

const usePayment = () => {
  const selectors = hooks.useSelectors();
  const products = payInfoUtils.prepare.products(selectors.basketData);

  const { price } = preparePrice(products, selectors.type, selectors.promocode);

  useEffect(() => {
    metaTagsActions.change({
      title: 'T.The.Little - оплата',
    });
  }, [selectors.pathname]);

  return {
    price,
  }
}

export default usePayment;

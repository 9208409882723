import http from 'axios'

import { showError } from 'reducers/admin/snackbar/snackbar.actions'

export const ok = (response: any, data: any = undefined) => {
  return {
    success:  true,
    failure:  false,
    response: response,
    status:   response.status,
    error:    undefined,
    data:     data || response.data
  }
}

export const fail = (error: any, data: any = {}) => {
  const errorData = (error.response && error.response.data) || data
  const errorMessage =  errorData.error || error.message
  showError(errorMessage)

  return {
    success:  false,
    failure:  true,
    response: error.response,
    status:   error.response.status,
    error:    errorMessage,
    data:     errorData
  }
}

export const get = (path: string, opts: any = {}) =>
  http
    .get(path, { params: opts })
    .then(ok)
    .catch(fail)

export const post = (path: string, opts: any = {}) =>
  http
    .post(path, opts)
    .then(ok)
    .catch(fail)

export const patch = (path: string, opts: any = {}) =>
  http
    .patch(path, opts)
    .then(ok)
    .catch(fail)

export const destroy = (path: string, opts: any = {}) =>
  http
    .delete(path, opts)
    .then(ok)
    .catch(fail)

import React, { FC } from 'react';
import { NavLink as Link } from 'react-router-dom';

import { Icon } from 'common/ui';

import { NavLinkProps } from './Input.interface';
import theme from './NavLink.module.scss';

const NavLink: FC<NavLinkProps> = ({
  path,
  icon,
  name,
  exact = true
}) => {
  return (
    <Link
      exact={exact}
      className={ theme.root }
      activeClassName={ theme.active }
      to={ path }
    >
      { icon && <Icon name={ icon } className={ theme.icon }/> }
      { name }
    </Link>
  )
}

export default NavLink

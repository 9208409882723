import { uniq } from 'lodash'

import { initialSelectState } from './promocodes.model';
import * as types from './promocodes.constants';

export const select = (state = initialSelectState, action: any) => {
  switch(action.type) {
    case types.PROMOCODE_SELECT_FETCH: {
      return {
        ...state,
        phrase: action.payload.phrase,
        selected: action.payload.selected,
        loading: true,
        error: '',
      };
    }
    case types.PROMOCODE_SELECT_SUCCESS: {
      return {
        ...state,
        promocodes: action.payload,
        loading: false,
        error: '',
      };
    }
    case types.PROMOCODE_SELECT_CLEAR: {
      return {
        ...state,
        promocodes: [],
        selected: [],
        loading: false,
        error: '',
      };
    }
    case types.PROMOCODE_SELECT_REQUIRED_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case types.PROMOCODE_SELECT_REQUIRED_SUCESS: {
      return {
        ...state,
        selected: uniq([...state.selected, ...action.payload]),
        loading: true,
        error: '',
      };
    }
    case types.PROMOCODE_SELECT_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch promocodes',
      };
    }
    default: {
      return state;
    }
  }
};

import React, { Suspense, FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import Loader from 'components/admin/layouts/Loader/Loader.layout';

import { application, NotFoundPageLayout } from 'common/layouts';
import { routes } from 'routes/application';
import { history } from 'store/indexApplication';

import 'common/styles/application.scss';

const Root: FC = () => {
  const renderComponent = (props: any, Component: any) => {
    const crumbs = routes
      .filter(({ path }) => props.match.path.includes(path))
      .map(({ path, ...rest }) => ({
        path: Object.keys(props.match.params).length
          ? Object.keys(props.match.params).reduce((path, param) => path.replace(
            `:${ param }`, props.match.params[param]
          ), path)
          : path,
        ...rest
      }));
    return (
      <application.MainLayout crumbs={ crumbs }>
        { props.match.path === '/*'
          ? <NotFoundPageLayout>
              <Suspense fallback={ <Loader/> }>
                <Component { ...props } />
              </Suspense>
            </NotFoundPageLayout>
          : <application.PageLayout crumbs={ crumbs }>
              <Suspense fallback={ <Loader/> }>
                <Component { ...props } />
              </Suspense>
            </application.PageLayout>
        }
      </application.MainLayout>
    );
  };
  return (
    <ConnectedRouter history={history}>
      <Switch>
        { routes.map(({ path, Component }, key) => {
              return <Route
                exact
                path={ path }
                key={ key }
                render={ props => renderComponent(props, Component) }
              />
        }) }
      </Switch>
    </ConnectedRouter>
  );
};

export default Root;

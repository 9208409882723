import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { startSubmit, stopSubmit, reset } from 'redux-form'
import { post, patch } from 'common/utils/api'
import { preparedFormData } from 'common/utils/preparedData'

import * as productTypes from 'reducers/admin/products/products.constants';
import {
  COLOR_SELECT_REQUIRED_FETCH
} from 'reducers/admin/colors/colors.constants';
import { showSuccess, showError } from 'reducers/admin/snackbar/snackbar.actions'

function* fetchSelectedColors(colors: any) {
  const selected = colors.map((color)=> color.colorId)
  if (selected.length > 0) {
    yield put({type: COLOR_SELECT_REQUIRED_FETCH, payload: { selected }})
  }
}


function* productResponse(response, message = null) {
  if (response.success) {
    yield put(stopSubmit('product'))

    yield put({type: productTypes.PRODUCT_FORM_SUCCESS, payload: response.data})
    yield put({type: productTypes.SELECT_CLEAR})
    yield fork(fetchSelectedColors, response.data.colors)
    yield put(push(`/admin/products/${response.data.id}/edit`))

    if (message) { showSuccess(message) }
  } else {
    yield put({type: productTypes.PRODUCT_FORM_FAIL, payload: { error: response.error }})
    if (response.data.messages) {
      yield put(stopSubmit('product', response.data.messages))
    }
  }
}

function* fetchProduct(action: any) {
  try {
    const response = yield call(post, 'products/get_info', action.payload)
    yield call(productResponse, response)
  } catch (error) {
    yield put({type: productTypes.PRODUCT_FORM_FAIL, error})
    showError(error)
  }
}

function* updateProduct(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('product'))
    const response = yield call(patch, 'products', formData)

    yield call(productResponse, response, "Товар був оновлений")
  } catch (error) {
    yield put({type: productTypes.PRODUCT_FORM_FAIL, error})
    showError(error)
  }
}

function* createProduct(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('product'))
    const response = yield call(post, 'products', formData)

    yield call(productResponse, response, "Товар був створений")
  } catch (error) {
    yield put({type: productTypes.PRODUCT_FORM_FAIL, error})
    showError(error)
  }
}

export function* productFormFetchFlow() {
  yield takeLatest(productTypes.PRODUCT_FORM_FETCH, fetchProduct)
}

export function* productFormCreateFlow() {
  yield takeLatest(productTypes.PRODUCT_FORM_CREATE, createProduct)
}

export function* productFormUpdateFlow() {
  yield takeLatest(productTypes.PRODUCT_FORM_UPDATE, updateProduct)
}

import React, { FC } from 'react';

import { Recomendations } from 'components/application';

import * as hooks from './hooks';
import * as utils from './utils';
import { IShow } from './Show.interface';
import theme from './Show.module.scss';
import { Slider, ShortInfo, Tabs } from './components';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Show: FC<IShow> = ({
  id,
}) => {
  const { product, states } = hooks.useShow(id);

  return (
    <div className={ theme.root }>
      <Slider 
        className={ theme.slider } 
        images={ utils.prepareData.images(product, states) }
        product={ product }
      />
      <ShortInfo 
        className={ theme.shortInfo } 
        product={ product }
        colorId={ states.colorId }
        colorChange={ utils.change.color(states) }
      />
      <Tabs 
        className={ theme.tabs }
        tabIndex={ states.tabIndex }
        onChange={ utils.change.tab(states) }
        tabs={ utils.prepareData.tabs(product) }
      />
      <Recomendations
        title="З цим товаром часто беруть"
        productId={ id }
      />
    </div>
  );
}

export default Show;

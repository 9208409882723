import { all } from 'redux-saga/effects';
import * as table from './table.saga'
import * as form from './form.saga';

export function* ordersFlow() {
  yield all([
    table.fetchFlow(),
    table.tableFetchFlow(),
    table.searchFlow(),
    table.fetchInitial(),
    form.orderFormFetchFlow(),
    form.orderFormCheckUser(),
    form.orderFormCreateFlow(),
    form.orderFormUpdateFlow(),
    form.orderFormFetchInitial(),
  ]);
}

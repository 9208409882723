import React, { FC } from 'react';
import cn from 'classnames';

import * as hooks from './hooks';
import { ISite } from './Site.interface';
import theme from './Site.module.scss';

const Site: FC<ISite> = ({ 
  className,
}) =>  {
  hooks.useSite();
  return(
    <div className={ cn(theme.root, className) }>
      <div id="liqpay_checkout" />
    </div>
  )
}

export default Site;

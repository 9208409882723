export const COLORS_TABLE_FETCH   = 'COLORS/TABLE/FETCH';
export const COLORS_TABLE_SUCCESS = 'COLORS/TABLE/SUCCESS';
export const COLORS_TABLE_FAIL    = 'COLORS/TABLE/FAIL';
export const COLORS_TABLE_VALUES  = 'COLORS/TABLE/VALUES';
export const COLORS_TABLE_SEARCH  = 'COLORS/TABLE/SEARCH';

export const COLOR_FORM_FETCH   = 'COLORS/FORM/FETCH';
export const COLOR_FORM_SUCCESS = 'COLORS/FORM/SUCCESS';
export const COLOR_FORM_FAIL    = 'COLORS/FORM/FAIL';
export const COLOR_FORM_UPDATE  = 'COLORS/FORM/UPDATE';
export const COLOR_FORM_CREATE  = 'COLORS/FORM/CREATE';

export const COLOR_SELECT_FETCH            = 'COLORS/SELECT/FETCH';
export const COLOR_SELECT_SUCCESS          = 'COLORS/SELECT/SUCCESS';
export const COLOR_SELECT_FAIL             = 'COLORS/SELECT/FAIL';
export const COLOR_SELECT_REQUIRED_FETCH   = 'COLORS/SELECT/REQUIRED_FETCH';
export const COLOR_SELECT_REQUIRED_SUCESS  = 'COLORS/SELECT/REQUIRED_SUCESS';
export const COLOR_SELECT_CLEAR            = 'COLORS/SELECT/CLEAR';

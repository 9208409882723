import React, { FC } from 'react';

import { IPayment } from './Payment.interface';
import theme from './Payment.module.scss';
import * as hooks from './hooks';

const Payment: FC<IPayment> = ({
  
}) => {
  hooks.usePayment();

  return (
    <div className={ theme.root }>
      <div className={ theme.headerMain }>
        Оплата
      </div>

      Оплатити своє замовлення на сайті t-the-little.com Ви можете наступними 3-ма способами:
      <ul className={ theme.listSecond }>
        <li>
          післяплатою (наложеним платижем від 500 грн і лише на Нову Пошту)
        </li>
        <li>
          відразу на сайті платіжною системою LiqPay при оформленні замовлення;
        </li>
        <li>
          при оформленні замовлення ви можете вибрати "Оплата за реквізитами" і після виконання покрокових вказівок та натискання кнопки «перейти до оплати» — ми надішлемо реквізити для оплати.
        </li>
      </ul>

      Зверніть увагу, що всі замовлення відправляються після коректного заповнення всіх полів та вибору способу оплати (після зарахування коштів).
      <br />
      Вироби з індивідуальної гравіюванням (іменні та індивідуальні вироби) беруться в роботу і відправляються тільки після повної оплати замовлення.
    </div>
  );
}

export default Payment;

import React, { FC, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { Paper, MenuList, MenuItem } from '@material-ui/core';

import { UserMenu } from './components';

import { Icon } from 'common/ui';
import Drawer from '../Drawer/Drawer.layout';

import Snackbar from 'components/admin/layouts/Snackbar/Snackbar.layout'
import { HeaderProps } from './Header.interface';
import theme from './Header.module.scss';


const HeaderLayout: FC<HeaderProps> = ({ crumbs }) => {
  const [isUserMenuOpened, setIsUserMenuOpened] = useState(false);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const { user } = useSelector((state: any) => state.authorization, shallowEqual);

  const openMenu = ()=> {
    if (user.id) {
      setIsUserMenuOpened((isOpened) => !isOpened)
    }
  }

  return (
    <header className={ theme.root }>
      <div className={ theme.logo }>
        {
         user.id &&
          <button
            className={ theme.menuButton }
            onClick={ () => setIsDrawerOpened(!isDrawerOpened) }
          >
            <Icon className={ theme.menuIcon } name="menu"/>
          </button>
        }

        <NavLink className={ theme.logoLink } to="/admin">
          T.The.Little
        </NavLink>
      </div>

      <div className={ theme.pageTitle }>
        { crumbs[crumbs.length - 1].title }
      </div>

      <div className={ theme.user }>
        <span
          className={ theme.userButton }
          onClick={openMenu}
        >
          {
            user.photo &&
            <span
              className={theme.avatar}
              style={{
                backgroundImage: `url(${user.photo})`
              }}
            />
          }
          <span>
            {`${user.fullName || ''}`}
          </span>
        </span>

        <UserMenu
          isOpened={ isUserMenuOpened }
          id={ user.id }
          onBackdropClick={ ()=> setIsUserMenuOpened(false) }
        />
      </div>
      <Drawer
        isOpened={ isDrawerOpened }
        onBackdropClick={ (isOpened: boolean) => setIsDrawerOpened(false) }
      />
      <Snackbar />
    </header>
  );
};

export default HeaderLayout;

import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { post } from 'common/utils/api'
import { store } from 'store/indexAdmin';

import {
  COLORS_TABLE_FETCH,
  COLORS_TABLE_FAIL,
  COLORS_TABLE_SUCCESS,
  COLORS_TABLE_VALUES,
  COLORS_TABLE_SEARCH,
} from 'reducers/admin/colors/colors.constants';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* fetchColors(action: any) {
  try {
      const response = yield call(post, 'colors/all', action.payload)
      yield put({type: COLORS_TABLE_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: COLORS_TABLE_FAIL, error})
   }
}

function* loadinData(payload: any = {}) {
  const response = yield call(post, 'colors/all', { ...payload })

  if (response.failure) {
    yield put({type: COLORS_TABLE_FAIL})
    return false
  }
  yield put({type: COLORS_TABLE_SUCCESS, payload: response.data})
}

function* searchTableColors(action: any) {
  yield call(delay, 500)
  yield call(fetchTableColors)
}

function* fetchTableColors(action: any) {
  const { getState } = store;
  const { colorsData: { table: { values } } } = getState()
  yield put({type: COLORS_TABLE_FETCH, payload: values})
}

export function* colorsFetchFlow() {
  yield takeLatest(COLORS_TABLE_FETCH, fetchColors)
}

export function* colorsTableFetchFlow() {
  yield takeLatest(COLORS_TABLE_VALUES, fetchTableColors)
}

export function* colorsTableSearchFlow() {
  yield takeLatest(COLORS_TABLE_SEARCH, searchTableColors)
}


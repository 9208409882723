import { flatten, compact } from 'lodash';

export const images = (product: any, states: any) => {
  const photos = product.colors.map((color: any) => {
    if ( states.colorId && states.colorId != color.id ) { return }
    return color.photos;
  })

  return flatten(compact(photos));
}


export const tabs = (product: any) => {
  let values = [];

  const order = [
    { type: 'description', label: 'Опис' },
    { type: 'details', label: 'Деталі' },
  ]

  order.forEach((tab: any) => {
    if (product[tab.type]) {
      values.push({
        label: tab.label,
        data: product[tab.type],
      })
    }
  })

  return values;
}
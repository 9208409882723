import React, { FC, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { isEmpty } from 'lodash';

import * as hooks from './';
import { productsActions, filtersActions, metaTagsActions } from 'reducers/application';

const useShop = () => {
  const { 
    data, page, leftMore, sorting, loading, search 
  } = useSelector((state: any) => state.products, shallowEqual);

  const { 
    filters, categories, colors, prices, loaded,
  } = useSelector((state: any) => state.filters, shallowEqual);
  const { pathname } = useSelector((state: any) => state.router.location, shallowEqual);

  const states = hooks.setStates();

  const loadMore = () => productsActions.fetchProducts();
  const listenScrollEvent = (e) => {
    states.setFixFilters(window.scrollY > 495);
  }

  useEffect(() => {
    if (!data.length && !loading && leftMore) { productsActions.fetchProducts(); } 
    if (!loaded) { filtersActions.fetchFilters() }

    window.addEventListener('scroll', listenScrollEvent, true);
    metaTagsActions.change({
      title: 'T.The.Little - товари'
    });

    return () => {
      window.removeEventListener("scroll", listenScrollEvent, true);
    }
  }, [page, pathname]);

  return {
    products: data,
    leftMore,
    loadMore,
    sorting,
    search,
    filters,
    states,
    loading,
  }
}

export default useShop;
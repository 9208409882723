export const FETCHING_WAREHOUSES     = "NOVA_POSTA/FETCHING_WAREHOUSES"
export const WAREHOUSE_FETCH_SUCCESS = "NOVA_POSTA/WAREHOUSE_FETCH_SUCCESS"
export const WAREHOUSE_FETCH_ERROR   = "NOVA_POSTA/WAREHOUSE_FETCH_ERROR"
export const CLEAR                   = "NOVA_POSTA/CLEAR"

export const FETCHING_CITIES    = "NOVA_POSTA/FETCHING_CITIES"
export const CHANGE_CITY        = "NOVA_POSTA/CHANGE_CITY"
export const CITY_FETCH_SUCCESS = "NOVA_POSTA/CITY_FETCH_SUCCESS"
export const CITY_FETCH_ERROR   = "NOVA_POSTA/CITY_FETCH_ERROR"

export const CHANGE_WAREHOUSE_ID   = "NOVA_POSTA/CHANGE_WAREHOUSE_ID"
export const CHANGE_WAREHOUSE_2_ID = "NOVA_POSTA/CHANGE_WAREHOUSE_2_ID"

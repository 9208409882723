import React, { FC, useState } from 'react';
import classNames from 'classnames'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { PictureInputProps } from './PictureInput.interface';
import { PictureDialog } from './components'

import theme from './PictureInput.module.scss';

const PictureInput = ({
  crop,
  croppedImageUrl,
  dialogChange,
  id,
  input,
  openDialog,
  label,
  onCropChange,
  onCropComplete,
  onImageLoaded,
  onSelectFile,
  src,
  className,
  extraCrop,
  meta: { touched, error }
}) => {

  return(
    <div className={ classNames({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      { label && <label className={ theme.label } htmlFor={ id }>
          { label }
        </label>
      }

      <div className={ theme.inputField }>
        <div>
          <input
            type="file"
            accept="image/*"
            onChange={onSelectFile}
            id={ theme.picture }
            className={ theme.pictureInput }
          />
          <label className={theme.picture} htmlFor={theme.picture}>
            {croppedImageUrl && (
              <img
                alt="Crop"
                src={croppedImageUrl}
              />
            ) || <span className={ theme.addBtn }>Натисни, щоб додати фото...</span> }
          </label>
        </div>

        <PictureDialog
          extraCrop={ extraCrop }
          openDialog={openDialog}
          dialogChange={dialogChange}
          src={src}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      </div>

      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
  );
}

export default PictureInput;

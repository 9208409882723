export const getImageAtt = (base64) => {
  const format = base64.split(';')[0].split('/')[1];
  if (format === 'png') {
    return {
      format: 'image/png',
      name: 'newFile.png'
    }
  }
  return {
    format: 'image/jpeg',
    name: 'newFile.jpg'
  }
}

export const remove = (states, input) => (index) => () => {
  let images = states.images;
  images.splice(index, 1);
  states.setImages(images);
  input.onChange(images);
}
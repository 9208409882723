import React, { FC } from 'react';
import cn from 'classnames';

import { Icon } from 'common/ui';

import * as utils from './utils';
import { IMessage } from './Message.interface';
import theme from './Message.module.scss';

const Message: FC<IMessage> = ({ 
  className,
  orderId,
}) =>  {
  return(
    <div className={ cn(theme.root, className) }>
      <div 
        className={ theme.header }
        onClick={ utils.main.close }
      >
        <div className={ theme.text }>Дякуємо за замовлення!</div>
        <Icon className={theme.icon} name="close-outlined" />
      </div>
      <div className={ theme.body }>
        <p>Номер вашого замовлення <strong>№ {orderId}</strong>.</p>
        <p>Очікуйте повідомлення про відправку.</p>
      </div>
    </div>
  )
}

export default Message;

import React, { FC, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { join } from 'lodash';

import { metaTagsActions } from 'reducers/application';

import * as hooks from './';
import {
  fetchProduct
} from 'reducers/application/products/products.actions';

const useShow = (id: string) => {
  const { product } = useSelector((state: any) => state.products, shallowEqual);
  const { pathname } = useSelector((state: any) => state.router.location, shallowEqual);

  const states = hooks.setStates();
  const colorIds = product.colors.map((color: any) => color.id)
  const noColor = !colorIds.find((id: number) => id == states.colorId)

  useEffect(() => {
    fetchProduct({ id });
    if ((!states.colorId || noColor) && product.colors.length) {
      states.setColorId(product.colors[0].id)
    }
    metaTagsActions.change({
      title: `T.The.Little - ${product.label}`,
      image: product.photo,
    })

  }, [pathname, product.label, join(colorIds, '-')]);

  return {
    product,
    states,
  }
}

export default useShow;

import { store } from 'store/indexApplication';
import { basketActions, promocodesActions } from 'reducers/application';

import * as types from '../order.constants';
import * as utils from '../utils';
import * as actions from './';

export const syncLStorage = () => {
  const { id } = utils.localStorage.data();

  if (id) {
    store.dispatch({
      type: types.FETCH_ORDER,
      payload: { id },
    })
  }
}

export const checkMessage = () => {
  const { form: { status }, orderShow } = store.getState().order;

  if (status !== 'wait_to_approve') { return }
  if (orderShow) { actions.message.show(true); }
  basketActions.clear();
  clear();
}

export const clear = () => {
  utils.localStorage.clear();
  promocodesActions.clearData();

  store.dispatch({
    type: types.CLEAR,
  })
}
import React, { FC, useEffect } from 'react';


const useMainLayout = () => {

  useEffect(() => {
    // including Google Analitics
    const sLoad = document.createElement('script');
    sLoad.src = 'https://www.googletagmanager.com/gtag/js?id=UA-192203429-1';
    sLoad.async = true;
    document.body.appendChild(sLoad);
    
    const script = document.createElement('script');
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){ dataLayer.push(arguments); }
      gtag('js', new Date());

      gtag('config', 'UA-192203429-1');
    `
    document.body.appendChild(script);

    // FACEBOOK Pixel code
    const fScript = document.createElement('script');
    fScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '312163539802612');
      fbq('track', 'PageView');
    `
    document.body.appendChild(fScript);

    const fNoScript = document.createElement('noscript');
    fNoScript.innerHTML = `
      <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=312163539802612&ev=PageView&noscript=1"
      />
    `
    document.body.appendChild(fNoScript);
  }, []);

  return {
  }
}

export default useMainLayout;
import React, { FC } from 'react';
import { Field } from 'redux-form';

import { Input } from 'common/ui';
import { InputProps } from './Input.interface'
import theme from './Input.module.scss';

const FormInput: FC<InputProps> = ({
  name,
  type = 'text',
  label,
  onChange = null
}) => {

  return (
    <Field
      className={ theme.root }
      name={ name }
      type={ type }
      label={ label || name }
      id={ name }
      onChange={ onChange }
      component={ Input }
    />
  )
}

export default FormInput;
import { all } from 'redux-saga/effects';
import {
  outlaysFetchFlow,
  outlaysTableFetchFlow,
  outlaysTableSearchFlow
} from './table.saga';

import {
  outlayFormFetchFlow,
  outlayFormCreateFlow,
  outlayFormUpdateFlow
} from './form.saga';

import {
  outlaysSelectStatuesFetchFlow,
} from './select.saga';

export function* outlaysFlow() {
  yield all([
    outlaysFetchFlow(),
    outlaysTableFetchFlow(),
    outlaysTableSearchFlow(),
    outlayFormFetchFlow(),
    outlayFormCreateFlow(),
    outlayFormUpdateFlow(),
    outlaysSelectStatuesFetchFlow()
  ]);
}

import { useSelector, shallowEqual } from 'react-redux';

const useSelectors = () => {
  const { pathname } = useSelector((state: any) => state.router.location, shallowEqual);
  const { order } = useSelector((state: any) => state, shallowEqual);
  const { data: basketData } = useSelector((state: any) => state.basket, shallowEqual);
  const { data: promocode } = useSelector((state: any) => state.promocodes, shallowEqual);

  const type: string = (((order || {}).form || {}).address || {}).type || ''; 

  return {
    type,
    basketData,
    pathname,
    promocode,
  }
}

export default useSelectors;

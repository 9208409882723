import React, { FC } from 'react';
import cn from 'classnames';

import { ISubmitButton } from './SubmitButton.interface';
import theme from './SubmitButton.module.scss';

const SubmitButton: FC<ISubmitButton> = ({ 
  className = '',
  property = 'white',
  label = "Зберегти",
  labelSending = "Збереження",
  onClick,
  disabled = false,
  sending = false,
}) => (
  <button
    type="submit"
    className={ cn({
        [theme.white]: property === 'white',
        [theme.grey]: property === 'grey',
        [theme.disabled]: disabled && !sending,
        [theme.sending]: sending,
      },
      [theme.root], 
      className,
    )}
    disabled={ disabled }
  >  
    <div className={ theme.body } >
      <span>{ sending ? labelSending : label }</span>
      <div className={ cn(theme.dotsBlock, { [theme.sending]: sending }) }>
        <div className={ cn(theme.dots, { [theme.sending]: sending }) } />
      </div>
    </div>
  </button>
);

export default SubmitButton;

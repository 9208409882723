import React, { FC } from 'react';
import cn from 'classnames';
import { Range } from 'rc-slider';

import { priceView } from 'common/utils/humanView';
import * as hooks from './hooks';

import { IRangeSlider } from './RangeSlider.interface';
import theme from './RangeSlider.module.scss';

import 'rc-slider/assets/index.css';

const RabgeSlider: FC<IRangeSlider> = ({ 
  className,
  min,
  max,
  onChange,
  value = [],
  ...props
}) => {
  const { states } = hooks.useRangeSlider(min, max, value);
  const colorGreen = '#5fb34c';

  return (
    <div
      className={ cn([theme.root], className) }
    >
      <Range
        { ...props }
        min={ min }
        max={ max }
        value={ states.value }
        onChange={ (value) => states.setValue(value) }
        onAfterChange={ onChange }
        handleStyle={[
          { 
            borderColor: colorGreen,
            boxShadow: `0 0 5px ${colorGreen}`,
          },
          { 
            borderColor: colorGreen,
            boxShadow: `0 0 5px ${colorGreen}`,
          },
        ]}
        trackStyle={[
          {
            background: colorGreen,
          }
        ]}
      />
      <div className={ theme.info }>
        <div>{priceView(states.value[0])}</div>
        <div>{priceView(states.value[1])}</div>
      </div>
    </div>
  )
}

export default RabgeSlider;
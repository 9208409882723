import React, { FC } from 'react';
import { Field } from 'redux-form';

import { CreatableSelect as Select } from 'common/ui';
import { ICreatableSelect } from './CreatableSelect.interface'
import theme from './CreatableSelect.module.scss';

const CreatableSelect: FC<ICreatableSelect> = ({
  name,
  label,
  ...props
}) => {

  return (
    <Field
      {...props}
      className={ theme.root }
      name={ name }
      label={ label || name }
      id={ name }
      component={ Select }
    />
  )
}

export default CreatableSelect;
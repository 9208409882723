import * as hooks from './';

const useBasketElements = () => {
  const selectors = hooks.useSelectors();
  const label = selectors.couldPay ? 'Оплатити замовлення' : 'Оформити замовлення'

  return {
    selectors,
    label,
  }
}

export default useBasketElements;

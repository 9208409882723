import { isArray, isString, compact } from 'lodash';

export const imageView = (link, value = false) => {
  return {
    link,
    value: value || link,
  }
}

export const imageObj = (value) => {
  if (value && isString(value)) { return imageView(value) }

  return value;
}

export const images = (value) => {
  if (isArray(value)) {
    return value.map(image => imageObj(image))
  }

  return compact([ imageObj(value) ])
}
import { initialSnackbarState } from './snackbar.model';
import {
  SHOW_SUCCESS,
  SHOW_ERROR,
  HIDE_SNACKBAR
} from './snackbar.constants';

export const snackbar = (state = initialSnackbarState, action: any) => {
  switch(action.type) {
    case SHOW_SUCCESS: {
      return {
        ...state,
        type: 'success',
        isOpen: true,
        message: action.payload.message,
      };
    }
    case SHOW_ERROR: {
      return {
        ...state,
        type: 'error',
        isOpen: true,
        message: action.payload.message,
      };
    }
    case HIDE_SNACKBAR: {
      return {
        ...state,
        isOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};

import { useSelector, shallowEqual } from 'react-redux';
import { formValueSelector } from 'redux-form';

const useSelectors = () => {
  const { order } = useSelector((state: any) => state, shallowEqual);
  const { data: promocode } = useSelector((state: any) => state.promocodes, shallowEqual);

  return {
    ...order,
    promocode,
  }
}

export default useSelectors;

import React, { FC } from 'react';

import theme from './Loader.module.scss';

const Loader: FC = () => {
  return (
    <div className={ theme.root }>
      <div className={ theme.ellipsis }>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
};

export default Loader;

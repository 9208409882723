import { FC } from 'react';
import { compact, cloneDeep } from 'lodash'

export interface IData {
  productId: number;
  colorId: number;
  state: any;
}

export const sync: FC<IData> = ({
  state,
  colorId,
  productId,
}) => {
  const product = state.products.find((product: any) => {
    return (productId == product.productId) && (colorId == product.colorId)
  })

  if (product) {
    return state.data.map((hash: any) => {
      let newHash = cloneDeep(hash);
      if ((hash.product.id == productId) && (hash.color.id == colorId)) {
        newHash.count = product.count;
      }
      return newHash;
    });
  } else {
    return remove({ state, colorId, productId })
  }
}

export const remove: FC<IData> = ({
  state,
  colorId,
  productId,
}) =>
  compact(state.data.map((hash: any) => {
    if ((hash.product.id == productId) && (hash.color.id == colorId)) {
      return
    }
    return hash;
  }));


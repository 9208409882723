import React, { FC } from 'react';
import cn from 'classnames';

import { Form, GreyButton, SubmitButton, Icon } from 'common/ui';
import { orderActions } from 'reducers/application';

import * as hooks from './hooks';
import { IPaymentForm } from './PaymentForm.interface';
import { Body } from './components';

import { validate } from './validate';
import theme from './PaymentForm.module.scss';


const PaymentForm: FC<IPaymentForm> = ({ 
  className,
  price,
}) =>  {
  const { 
    selectors, label, labelSending,
  } = hooks.usePaymentForm();

  return(
    <div className={ cn(theme.root, className) }>
      <div 
        className={ theme.header }
        onClick={ () => orderActions.order.show(false) }
      >
        <div className={ theme.text }>Оплата замовлення</div>
        <Icon className={theme.icon} name="close-outlined" />
      </div>
      <Form
        onSubmit={ orderActions.payment.update }
        form='payment'
        initialValues={ selectors.payment }
        validate={ validate }
        className={ theme.form }
        showButtons={ false }
      >
        <div className={ theme.body } >
          <Body price={ price } />
          <div className={ theme.grow } />
        </div>

        {  hooks.form.selector('type') !== 'site' &&
          <SubmitButton
            className={ theme.button }
            label={ label }
            labelSending={ labelSending }
            property="grey"
            disabled={ false }
            sending={ selectors.sending }
           /> 
        }
      </Form>
    </div>
  )
}

export default PaymentForm;

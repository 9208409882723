import { useSelector, shallowEqual } from 'react-redux';

const useSelectors = () => {
  const { question } = useSelector((state: any) => state, shallowEqual);
  const { pathname } = useSelector((state: any) => state.router.location, shallowEqual);
  
  return {
    ...question,
    pathname,
  }
}

export default useSelectors;

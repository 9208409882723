import { isEmpty } from 'lodash';

export const byPaymentType = (type: string) => {
  const types = {
    novaPosta: 0,
    site: 3,
    card: 5,
  }

  const timeDiscounts = getTimeDiscounts(type) || { value: 0 };

  return timeDiscounts.value || types[type] || 0;
}

export const byPriceValue = (sum: number, count: number) => {
  if (count > 1 && sum >= 1300) { return 5 }
  if (count > 1 && sum >= 1000) { return 3 }
  return 0;
}

export const all = (sum: number, productCount: number, paymentType?: string, promo?: any) => {
  const price = byPriceValue(sum, productCount);
  const payment = byPaymentType(paymentType);
  const promocode = isEmpty(promo) ? 0 : promo.discount || 0;
  const total = price + payment + promocode;

  return { price, payment, promocode, total }
}

export const getTimeDiscounts = (type: string) => {
  const types = [
    {
      name: "Знижка 11% на весь асортимент, за попередньої оплати",
      value: 11,
      start: new Date(2023, 10, 11),
      end: new Date(2023, 10, 12),
      types: ['site', 'novaPosta']
    },
  ]

  return types.find(hash => {
    return hash.types.includes(type) &&
      new Date() > hash.start &&
      new Date() < hash.end
  })
}

export const timeMessage = (message: string, type: string) => {
  const timeDiscounts = getTimeDiscounts(type) || { name: message };
  return timeDiscounts.name;
}

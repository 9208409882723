import ShopPhoto from 'images/shop.jpeg';

export const initialState = {
  title: "T.the.Little - посуд та аксесуари з харчового силікону для дітей та батьків",
  description: "Великий вибір посуду (тарілки, ложки, вилки, стакани), прорізувачі, брязкальця, слюнявчики, ніблери. Подарунки немовлятам та молодим батькам. Є наложений платіж.",
  image: `${window.location.origin}${ShopPhoto}`,
  keywords: [
    'дитячий посуд', 'силіконовий слюнявчик', 'посуд для першого прикорму',
    'перший прикорм', 'гризунки', 'ніблер', 'ніблери', 'пляшка', 'пляшечка',
    'ложка', 'ложечка', 'дитячий магазин', 'дитячі аксесуари', 'вилка', 'вилочка',
    'детская посуда', 'секційна тарілка', 'тарілка із розділювачами', 
    'тарілка із секціями',]
};

import { initialState } from './filters.model';
import * as types from './filters.constants';
import * as utils from './utils';

export const filters = (state = initialState, action: any) => {
  switch(action.type) {
    case types.FILTERS_FETCH: {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }
    case types.FILTERS_FETCH_SUCCESS: {
      const prices = action.payload.prices;

      return {
        ...state,
        loading: false,
        errors: null,
        loaded: true,
        filters: { ...action.payload },
        prices: [prices.min, prices.max],
      };
    }
    case types.FILTERS_CATEGORY_CHANGED: {
      return utils.change.category(state, action)
    }
    case types.FILTERS_TAG_CHANGED: {
      return utils.change.tag(state, action)
    }
    case types.FILTERS_COLOR_CHANGED: {
      return utils.change.color(state, action)
    }
    case types.FILTERS_PRICE_CHANGED: {
      return utils.change.price(state, action)
    }
    case types.FILTERS_CLEAN: {
      const { prices } = state.filters;

      return {
        ...state,
        colors: [],
        categories: [],
        tags: [],
        prices: [prices.min, prices.max],
      }
    }
    case types.FILTERS_FETCH_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }
    default: {
      return state;
    }
  }
};

import moment from 'moment';

const parse = (time) => {
	if (typeof(time) === 'string') {
		return moment.utc(time)
	} else {
		return moment(time)
	}
}

export const materialDateTime = (time) => parse(time).format('YYYY-MM-DDTHH:mm')
export const materialDate = (time) => parse(time).format('YYYY-MM-DD')

export const humanView = (time) => parse(time).locale('uk').format('DD MMMM YYYY, HH:mm')

export const monthStartYear = (year, month) => moment().year(year).month(month).startOf('month')
export const monthEndYear = (year, month) => moment().year(year).month(month).endOf('month')

import { useSelector, shallowEqual } from 'react-redux';

const useSelectors = () => {
  const { order } = useSelector((state: any) => state, shallowEqual);
  const { 
    warehouses, loadedWarehouses, cities, loadedCities, cityId,
  } = useSelector((state: any) => state.novaPosta, shallowEqual);
  const { data: basketData } = useSelector((state: any) => state.basket, shallowEqual);
  const { data: promocode } = useSelector((state: any) => state.promocodes, shallowEqual);

  const type: string = (((order || {}).form || {}).address || {}).type || ''; 

  return {
    ...order,
    type,
    warehouses,
    loadedWarehouses,
    cities,
    loadedCities,
    cityId,
    basketData,
    promocode,
  }
}

export default useSelectors;

import React, { FC } from 'react';

import * as hooks from './';

const useQuestionForm = () => {
  const selectors = hooks.useSelectors();

  return {
    selectors,
  }
}

export default useQuestionForm;

import { 
  defaultTableState, defaultFormState, defaultSelectState
} from 'reducers/admin/helpers/model';

export const initialFormState = {
  ...defaultFormState,
  slider: {},
};

export const initialTableState = {
  ...defaultTableState,
  sliders: []
};

export const initialSelectState = {
  ...defaultSelectState,
  sliders: [],
};

import React, { FC } from 'react';
import cn from 'classnames';

import { GreyButton } from 'common/ui';
import { PayInfo } from 'components/application';

import * as hooks from './hooks';
import * as utils from './utils';
import { BasketElement } from '../';
import { IBasketElements } from './BasketElements.interface';

import theme from './BasketElements.module.scss';

const BasketElements: FC<IBasketElements> = ({
  className,
}) => {
  const { selectors, label } = hooks.useBasketElements();

  if (!selectors.data.length) {
    return (
      <div className={ theme.root }>
        Корзина порожня
      </div>
    )
  }

  return (
    <>
      <ul 
        className={ cn(theme.root, theme.list, className) }
      >
        { selectors.data.map((product: any, index: number) => (
          <BasketElement { ...product } key={ index } />
        )) }
        
      </ul>
      <div className={ theme.grow } />
      <div className={ theme.info }>
        <PayInfo />
        <GreyButton
          label={ label }
          type="grey"
          className={ theme.button }
          onClick={ utils.order.open }
        />
      </div>
    </>
  );
}

export default BasketElements;

import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { post } from 'common/utils/api'
import { store } from 'store/indexAdmin';

import {
  OUTLAYS_TABLE_FETCH,
  OUTLAYS_TABLE_FAIL,
  OUTLAYS_TABLE_SUCCESS,
  OUTLAYS_TABLE_VALUES,
  OUTLAYS_TABLE_SEARCH,
} from 'reducers/admin/outlays/outlays.constants';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* fetchOutlays(action: any) {
  try {
      const response = yield call(post, 'outlays/all', action.payload)
      yield put({type: OUTLAYS_TABLE_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: OUTLAYS_TABLE_FAIL, error})
   }
}

function* loadinData(payload: any = {}) {
  const response = yield call(post, 'outlays/all', { ...payload })

  if (response.failure) {
    yield put({type: OUTLAYS_TABLE_FAIL})
    return false
  }
  yield put({type: OUTLAYS_TABLE_SUCCESS, payload: response.data})
}

function* searchTableOutlays(action: any) {
  yield call(delay, 500)
  yield call(fetchTableOutlays)
}

function* fetchTableOutlays(action: any) {
  const { getState } = store;
  const { outlaysData: { table: { values } } } = getState()
  yield put({type: OUTLAYS_TABLE_FETCH, payload: values})
}

export function* outlaysFetchFlow() {
  yield takeLatest(OUTLAYS_TABLE_FETCH, fetchOutlays)
}

export function* outlaysTableFetchFlow() {
  yield takeLatest(OUTLAYS_TABLE_VALUES, fetchTableOutlays)
}

export function* outlaysTableSearchFlow() {
  yield takeLatest(OUTLAYS_TABLE_SEARCH, searchTableOutlays)
}


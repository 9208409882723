import React, { FC } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import { ICategory } from './Category.interface';
import theme from './Category.module.scss';

const Category: FC<ICategory> = ({
  picture,
  className,
  label,
  path,
}) => {
  return (
    <NavLink 
      to={path}
      className={ classNames(
        [theme.root],
        className,
      )}
      style={{ 
        backgroundImage: `url(${picture})`,
      }}
    >
        <h3>{ label }</h3>
    </NavLink>
  );
}

export default Category;

import { FC } from 'react';

import { basketActions } from 'reducers/application';

export interface ICount {
  productId: number;
  colorId: number;
  value: number;
  number: number;
}

export const count: FC<ICount> = ({
  productId,
  colorId,
  value,
  number,
}) => (newValue: number) => {
  if (value < newValue) {
    basketActions.addProduct({ number, colorId, productId, count: newValue - value })
  } else {
    basketActions.removeProduct({ number, colorId, productId, count: value - newValue })
  }
}
import { useSelector, shallowEqual } from 'react-redux';

const useSelectors = () => {
  const { recomendations } = useSelector((state: any) => state, shallowEqual);
  const { pathname } = useSelector((state: any) => state.router.location, shallowEqual);
  const { products } = useSelector((state: any) => state.basket, shallowEqual);

  const basketIds = (products || []).map((hash: any) => hash.productId)

  return {
    ...recomendations,
    pathname,
    basketIds,
  }
}

export default useSelectors;

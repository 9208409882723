import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import { authorization } from './authorization/authorization.reducer';
import { usersData } from './admin/users/index.reducer';
import { outlaysData } from './admin/outlays/index.reducer';
import { purchasesData } from './admin/purchases/index.reducer';
import { colorsData } from './admin/colors/index.reducer';
import { tagsData } from './admin/tags/index.reducer';
import { articlesData } from './admin/articles/index.reducer';
import { categoriesData } from './admin/categories/index.reducer';
import { productsData } from './admin/products/index.reducer';
import { promocodesData } from './admin/promocodes/index.reducer';
import { ordersData } from './admin/orders/index.reducer';
import { sliders } from './admin/sliders/index.reducer';
import { snackbar } from './admin/snackbar/snackbar.reducer';

import { novaPosta } from './both/novaPosta/index.reducer';

export const rootAdminReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  usersData: usersData(),
  outlaysData: outlaysData(),
  purchasesData: purchasesData(),
  colorsData: colorsData(),
  tagsData: tagsData(),
  articlesData: articlesData(),
  productsData: productsData(),
  promocodesData: promocodesData(),
  ordersData: ordersData(),
  categoriesData: categoriesData(),
  sliders: sliders(),
  authorization,
  form,
  snackbar,
  novaPosta,
});

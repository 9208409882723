import { FC } from 'react';
import { isArray } from 'lodash';

const name = 'TTheLittleBasket';

export interface IProduct {
  productId: number;
  colorId: number;
  count?: number;
}

export const data = () => {
  const json = localStorage.getItem(name) || '[]';
  const array = JSON.parse(json);

  if (isArray(array)) {
    return array
  } else {
    clear()
    return [];
  }
}

export const product: FC<IProduct> = ({ productId, colorId }) =>
  data().find((hash: IProduct) => {
    return (hash.productId == productId) && (hash.colorId == colorId)
  }) || {}


export const set: FC<IProduct> = (product) => {
  let array = data();
  const index = array.findIndex((hash: IProduct) => {
    return (hash.productId == product.productId) && (hash.colorId == product.colorId)
  });

  if (index >= 0) {
    product.count ? array.splice(index, 1, product) : array.splice(index, 1);
  } else {
    product.count && array.push(product)
  }

  array.sort((a: IProduct, b: IProduct) => a.productId - b.productId);
  localStorage.setItem(name, JSON.stringify(array));
}

export const clear = () => {
  localStorage.removeItem(name)
}
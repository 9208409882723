export const TABLE_FETCH   = 'SLIDERS/TABLE/FETCH';
export const TABLE_SUCCESS = 'SLIDERS/TABLE/SUCCESS';
export const TABLE_FAIL    = 'SLIDERS/TABLE/FAIL';
export const TABLE_VALUES  = 'SLIDERS/TABLE/VALUES';
export const TABLE_SEARCH  = 'SLIDERS/TABLE/SEARCH';

export const FORM_FETCH   = 'SLIDERS/FORM/FETCH';
export const FORM_SUCCESS = 'SLIDERS/FORM/SUCCESS';
export const FORM_FAIL    = 'SLIDERS/FORM/FAIL';
export const FORM_UPDATE  = 'SLIDERS/FORM/UPDATE';
export const FORM_CREATE  = 'SLIDERS/FORM/CREATE';

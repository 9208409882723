import React, { FC } from 'react';
import cn from 'classnames';
import { FormSection } from 'redux-form';
import { compact } from 'lodash';

import { PayInfo } from 'components/application';
import { form } from '../../hooks';
import { promocodesActions } from 'reducers/application';

import { PCard, PNovaPosta, PSite } from '../';
import { RadioGroup, Input} from 'common/ui/FormElements';
import { IPayment } from './Payment.interface';
import theme from './Payment.module.scss';

const Payment: FC<IPayment> = ({ 
  className,
  price,
  promocode,
}) =>  {
  const pType = form.selector("payment.type");
  const aType = form.selector("address.type");

  return(
    <div className={ cn(theme.root, className) }>
      <FormSection name="payment">
        <RadioGroup
          name="type"
          label="Вид оплати"
          values={compact([
            aType == 'novaPosta' && price >= 500 && { value: 'novaPosta', label: 'Оплата при отримані (наложений платіж на новій пошті)' },
            { value: 'site', label: 'На сайті' },
            // { value: 'card', label: 'За реквізитами' },
          ])}
        />
        { pType == 'novaPosta' && <PNovaPosta className={ theme.type }/> }
        { pType == 'site' && <PSite className={ theme.type }/> }
        { pType == 'card' && <PCard className={ theme.type }/> }
        <Input name="promocode" label="Промокод" onChange={ promocodesActions.changeCode }/>
        <PayInfo back={ pType } delivery={ aType } />
      </FormSection>
    </div>
  )
}

export default Payment;

import { all } from 'redux-saga/effects';
import {
  purchasesFetchFlow,
  purchasesTableFetchFlow,
  purchasesTableSearchFlow
} from './table.saga';

import {
  purchaseFormFetchFlow,
  purchaseFormCreateFlow,
  purchaseFormUpdateFlow
} from './form.saga';

import {
  purchasesSelectStatuesFetchFlow,
} from './select.saga';

export function* purchasesFlow() {
  yield all([
    purchasesFetchFlow(),
    purchasesTableFetchFlow(),
    purchasesTableSearchFlow(),
    purchaseFormFetchFlow(),
    purchaseFormCreateFlow(),
    purchaseFormUpdateFlow(),
    purchasesSelectStatuesFetchFlow()
  ]);
}

import React, { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Icon } from 'common/ui';
import { IBreadcrumbs, IOption } from './Breadcrumbs.interface';

import theme from './Breadcrumbs.module.scss';

const Option: FC<IOption> = ({ pathname, value}) => {

  if (pathname) {
    return (
      <Link to={ pathname }>
        { value }
      </Link>
    )
  } else {
    return (
      <>{ value }</>
    )
  }
}

const Breadcrumbs: FC<IBreadcrumbs> = ({
  className,
  values = [],
  newPath,
}) => {
  return (
    <div className={ cn(theme.root, className) }>
      <ul className={ theme.links } >
        { values.map((hash: IOption, index: number) => (
          <li key={ index }>
            <Option { ...hash } />
          </li>  
        ))}
      </ul>
      { newPath && 
        <div className={ theme.new }>
          <Link to={newPath }>
            Додати
            <Icon className={ theme.icon } name="add" />
          </Link>
        </div>
      }
    </div>
)};

export default Breadcrumbs;

export const initialState = {
  orderShow: false,
  sending: false,
  order: {},
  couldPay: false,
  fetching: false,
  paying: false,
  showMessage: false,
  message: {},
  form: {
    address: {
      type: "novaPosta",
    }
  }
};

import { all } from 'redux-saga/effects';

import * as form from './form.saga';
import * as payment from './payment.saga';

export function* orderFlow() {
  yield all([
    form.createFlow(),
    form.updateFlow(),
    form.fetchFlow(),
    payment.fetchSite(),
    payment.fetchUpdate(),
  ]);
}

import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import * as hooks from './';
import * as utils from '../utils';

const useFilters = (fixFilters) => {
  const { 
    colors, filters, categories, prices, tags,
  } = useSelector((state: any) => state.filters, shallowEqual);

  const states = hooks.setStates();
  const filterSize = utils.selected.size({
    min: filters.prices.min,
    max: filters.prices.max,
    colors, categories, prices, tags
  });

  const filterText = filterSize ? `Фільтри (${filterSize})` : 'Фільтрувати'

  useEffect(() => {
    states.setFiltersSize(filterSize);
  }, [filterSize, fixFilters]);

  return {
    showClean: !!filterSize,
    states,
    filterText,
  }
}

export default useFilters;

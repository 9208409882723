const calcArray = (values, id) => {
  if (id == 0 ) { return [] }


  if (values.includes(id)) {
    const index = values.indexOf(id);
    values.splice(index, 1);
    return values;
  }

  values.push(id);
  return values;
}

export const category = (state: any, action: any) => {
  return {
    ...state,
    categories: [ ...calcArray(state.categories, action.payload.id) ],
  }
}

export const tag = (state: any, action: any) => {
  return {
    ...state,
    tags: [ ...calcArray(state.tags, action.payload.id) ],
  }
}

export const price = (state: any, action: any) => {
  return {
    ...state,
    prices: [ ...action.payload.prices ]
  }
}

export const color = (state: any, action: any) => {
  return {
    ...state,
    colors: [ ...calcArray(state.colors, action.payload.id) ],
  }
}

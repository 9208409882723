import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import * as hooks from './';
import * as reducerActions from 'reducers/application';


const useSorting = (type: string) => {
  const { sorting, search } = useSelector((state: any) => state[type], shallowEqual);
  const actions = reducerActions[`${type}Actions`];

  return {
    sorting,
    search,
    actions,
  }
}

export default useSorting;

import moment from 'moment'
import { startCase } from 'lodash';

import { yearStart } from './constants';

export const yearOptions = () => {
	const dateNow = new Date
  const length = dateNow.getFullYear() - yearStart + 1

  return Array.from({ length }, (_, i) => i + yearStart).map(year => {
    return { value: year, label: year }
  }).reverse()
}

export const monthOptions = () => {
  moment.locale('uk')

  return moment.months().map((month, index) => {
    return { value: index, label: startCase(month) }
  })
}

import { call, put, takeLatest } from 'redux-saga/effects'
import { post } from 'common/utils/api'

import * as types from 'reducers/admin/categories/categories.constants';

function* fetchCategories(action: any) {
  try {
      const response = yield call(post, 'categories/all', action.payload);
      if (response.success) {
        yield put({type: types.CATEGORY_SELECT_SUCCESS, payload: response.data})
      } else {
        yield put({type: types.CATEGORY_SELECT_FAIL, payload: response.errors})
      }
   } catch (error) {
      yield put({type: types.CATEGORY_SELECT_FAIL, error})
   }
}

export function* fetch() {
  yield takeLatest(types.CATEGORY_SELECT_FETCH, fetchCategories);
}

import { initialState, defaultState } from './authorization.model';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT
} from './authorization.constants';

export const authorization = (state = initialState, action: any) => {
  switch(action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        ...defaultState,
        loading: true
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        ...defaultState,
        user: action.payload.user
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        ...defaultState,
        error: action.payload.error,
      };
    }
    case LOGOUT: {
      return initialState
    }
    default: {
      return state;
    }
  }
};

import React, { FC } from 'react';

import { IContract } from './Contract.interface';
import theme from './Contract.module.scss';
import * as hooks from './hooks';

const Contract: FC<IContract> = ({
  
}) => {
  hooks.useContract();

  return (
    <div className={ theme.root }>
      <div className={ theme.header }>
        Публічний договір
      </div>

      <ol className={ theme.list }>
        <li>
          Загальні положення
          <br />
          <br />
          Ця Угода визначає умови використання Користувачами (Покупцями) матеріалів і сервісів сайту t-the-little.com (далі - Сайт, веб-сайт, інтернет-магазин).

          <ol>
            <li>Дана угода відповідно до ст. ст. 633, 641 і гл. 63 Цивільного кодексу України є публічним договором (офертою) і адресовано невизначеному колу осіб незалежно від статусу (фізична особа, юридична особа, фізична особа - підприємець), що бажають придбати товари в інтернет-магазині t-the-little.com.</li>
            <li>Оскільки ця Угода є публічною офертою, то отримуючи доступ до матеріалів Сайту Користувач (Покупець) вважається приєдналася до цієї Угоди.</li>
            <li>Адміністрація Сайту (власник сайту t-the-little.com і/або уповноважені ним особи) має право в будь-який час в односторонньому порядку змінювати умови цієї Угоди. Нова або змінена Угода стають дійсними після розміщення її на Сайті.</li>
            <li>Покупець погоджується з цією публічною офертою в цілому і без застережень при оформленні Замовлення на Сайті.</li>
            <li>Відвідувач (Покупець) погоджується з тим, що всі умови Угоди Користування йому зрозумілі і він приймає його повністю і беззастережно.</li>
          </ol>
        </li>

        <li>
          Порядок оформлення Замовлень, ціноутворення, обмін.
          <ol>
            <li>Відвідувач (Покупець) може оформити замовлення самостійно, шляхом натискання кнопки "купити" і "оформити замовлення" або написавши на зазначені електронні поштові адреси. Після оформлення замовлення покупцеві буде направлено повідомлення про підтвердження замовлення, на зазначені при оформленні замовлення контакти, і наявності товару або про передбачувану дату надходження товару в разі його відсутності. При оформленні замовлення за допомогою інших засобів зв’язку повідомлення здійснюється за допомогою тих самих джерел зв‘язку.</li>
            <li>У разі відсутності товару, вказаного на Сайті, внаслідок технічних неполадок, а також з інших причин, не залежних від Адміністрації, зазначене замовлення анулюється повністю або частково, а Відвідувач (Покупець) інформується шляхом направлення йому повідомлення.</li>
            <li>Відвідувач (Покупець) погоджується з тим, що ціна на товар, відкладений ним в "кошик", його асортимент і кількість, будуть актуальними лише на момент формування "кошика" і можуть змінюватися, якщо покупка не була остаточно оформлена шляхом натискання кнопки "оформити замовлення ", "купити".</li>
            <li>
              Відвідувач (Покупець) погоджується з тим, що придбаний ним Товар не підлягає обміну і поверненню в наступних випадках:
              <ul>
                <li>з моменту покупки Товару пройшло більше 14 календарних днів;</li>
                <li>з моменту придбання Товару не минуло 14 календарних днів, але товар був у вживанні, порушена цілісність упаковки і/або комплектність, відсутні бирки/цінника і т.д.</li>
                <li>відвідувач (покупець) відмовляється надати фото- або відеоматеріали бракованого і/або несправного на його погляд товару.</li>
              </ul>
            </li>
            <li>Цією Угодою Користувач (Покупець) підтверджує свою обізнаність у тому, що обміну або поверненню підлягає тільки новий товар, який не був у вживанні і не має слідів використання: подряпин, сколів, потертостей, повинні бути збережені: повний комплект товару, цілісність і всі компоненти упаковки, ярлики, заводське маркування та Відвідувач (Покупець) має оригінал документа, що підтверджує факт покупки відповідного Товару. Порушення будь-якого з цих пунктів залишає за Адміністрацією право відмовити Відвідувачу (Покупцеві) в обмін або повернення товару.</li>
            <li>При відмові Відвідувача (Покупця) від товару Адміністрація повертає йому суму, сплачену Покупцем, за винятком витрат Продавця на доставку та комісію від Покупця повернутого товару.</li>
          </ol>
        </li>

        <li>
          Обмеження відповідальності веб-сайту t-the-little.com
          <ol>
            <li>Адміністрація веб-сайту не несе ніякої відповідальності за будь-які помилки, друкарські помилки і неточності, які можуть бути виявлені в матеріалах, що містяться на даному веб-сайті. Адміністрація докладає всіх необхідних зусиль, щоб забезпечити точність і достовірність на сайті Інтернет-магазину інформації.</li>
            <li>Адміністрація не несе відповідальності за втрату Користувачем (Покупцем) можливості доступу до свого облікового запису - облікового запису на веб-сайті t-the-little.com (втрату логіна, пароля, іншої інформації).</li>
            <li>
              Адміністрація веб-сайту не несе відповідальності за:
              <ul>
                <li>затримки або збої в процесі здійснення операції, що виникли внаслідок непереборної сили, а також будь-якого випадку неполадок в телекомунікаційних, комп'ютерних, електричних та інших суміжних системах.</li>
                <li>дії систем переказів, банків, платіжних систем і за затримки пов'язані з їх роботою.</li>
                <li>належне функціонування веб-сайту, в разі, якщо Відвідувач (Покупець) не має необхідних технічних засобів для його використання, а також не несе ніяких зобов'язань по забезпеченню користувачів такими засобами.</li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          Авторське право адміністрації
          <ol>
            <li>Всі об'єкти, доступні за допомогою сервісів веб-сайту t-the-little.com, в тому числі елементи дизайну, текст, графічні зображення, ілюстрації, відео, програми, бази даних, музика, звуки і інші об'єкти, а також будь-який контент, розміщений на веб-сайті t-the-little.com, є об'єктами виняткових прав Адміністрації та інших правовласників.</li>
            <li>Використання контенту, а також будь-яких інших елементів сервісів можливо тільки в рамках функціоналу, пропонованого тим чи іншим сервісом. Ніякі елементи змісту сервісів веб-сайту t-the-little.com, а також будь-який контент, розміщений на сервісах веб-сайту t-the-little.com не можуть бути використані іншим чином без попереднього дозволу/згоди правовласника. Під використанням маються на увазі, в тому числі: відтворення, копіювання, переробка, поширення на будь-якій основі, відображення у розіграші і так далі. Використання Користувачем елементів змісту сервісів, а також будь-якого контенту для особистого некомерційного використання, допускається за умови збереження всіх знаків охорони авторського права, суміжних прав, товарних знаків, інших повідомлень про авторство, збереження імені (або псевдоніма) автора/найменування правовласника в незмінному вигляді, збереженні відповідного об'єкта в незмінному вигляді. Виняток становлять випадки, прямо передбачені законодавством України.</li>
          </ol>
        </li>

        <li>
          Продовження користування сервісами сайту t-the-little.com підтверджує факт приймання та виконання усіх умов зазначених в цьому договорі без подальших претензій до сторін цього договору.
        </li>
      </ol>

      <div className={ theme.address }>
        <div className={ theme.title }>Адреса та реквізити продавця:</div>
        <ul>
          <li>ФОП Козицька Наталя Ігорівна</li>
          <li>м. Київ, вул. Дегтярівська, буд. 43/9</li>
          <li>п/р UA373052990000026002023303332 в АТ КБ "ПРИВАТБАНК"</li>
          <li>ЄДРПОУ 3401602180</li>
          <li>тел. (068) 88 717 88</li>
        </ul>
      </div>
    </div>
  );
}

export default Contract;

import { FC } from 'react';
import { isArray } from 'lodash';

const name = 'TTheLittleOrder';

export const set = (order: any) => {
  localStorage.setItem(name, JSON.stringify({ id: order.id }));
}

export const data = () => {
  const json = localStorage.getItem(name) || '{}';

  return JSON.parse(json);
}

export const clear = () => {
  localStorage.removeItem(name)
}

import React, { useEffect, useCallback } from 'react';

import * as hooks from './';
import * as utils from '../utils';

const useMultiplePictureInput = (crop: any, input: any) => {
  const states = hooks.setStates(crop, input);
  const moveImage = useCallback(utils.dragAndDrop.moveImage(states, input),[states.images])

  useEffect(() => {
    states.setCroppedImageUrl(input.value);
    states.setImages(utils.prepareData.images(input.value));
  }, [input.value]);

  return {
    states,
    moveImage,
    onCropComplete: hooks.crop.onComplete(states),
    onSelectFile: hooks.input.onChange(states),
    onDialogClose: hooks.dialog.onClose(states, input, crop),
    removeImage: utils.picture.remove(states, input),
  }

}

export default useMultiplePictureInput;
export const ADD_PRODUCT        = 'BASKET/ADD_PRODUCT';
export const REMOVE_PRODUCT     = 'BASKET/REMOVE_PRODUCT';
export const SYNC_LOCAL_STORAGE = 'BASKET/SYNC_LOCAL_STORAGE';

export const LOAD_EMPTY_DATA = 'BASKET/LOAD_EMPTY_DATA';
export const LOADED_SUCCESS  = 'BASKET/LOADED_SUCCESS';
export const LOADED_ERROR    = 'BASKET/LOADED_ERROR';

export const SYNC_DATA = 'BASKET/SYNC_DATA';
export const CLEAR     = 'BASKET/CLEAR';
export const OPEN_MENU = 'BASKET/OPEN_MENU';

import { all } from 'redux-saga/effects';

import * as load from './load.saga';
import * as show from './show.saga';

export function* articlesFlow() {
  yield all([
    load.articles(),
    show.article(),
  ]);
}

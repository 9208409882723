import { 
  defaultTableState, defaultFormState, defaultSelectState
} from 'reducers/admin/helpers/model'

export const initialFormState = {
  ...defaultFormState,
  outlay: {}
};

export const initialTableState = {
  ...defaultTableState,
  outlays: [],
};

export const initialSelectState = {
  ...defaultSelectState,
  statuses: [],
  categories: [],
};

import React, { FC } from 'react';
import cn from 'classnames';
import { Carousel } from 'react-responsive-carousel';

import * as hooks from './hooks';
import { ICarouselImages } from './CarouselImages.interface';
import theme from './CarouselImages.module.scss';
import './CarouselImages.scss';

const CarouselImages: FC<ICarouselImages> = ({ 
  images = [],
  className,
  alt,
}) => {
  if (!images.length) { return null }
  const { states } = hooks.useCarouselImages(images);

  return(
    <div className={ cn('carousel-images', theme.root, className) }>
      <Carousel 
        useKeyboardArrows 
        infiniteLoop
        showStatus={ false }
        swipeable
        emulateTouch={ true }
        showIndicators={ false }
        showThumbs={ false } 
        selectedItem={ states.itemIndex }
        onChange={ (itemIndex: number) => { states.setItemIndex(itemIndex) } }
      >
        { images.map((image: string, index: number) => (
          <img
            key={ index }
            alt={ alt }
            className={ theme.image }
            src={ image }
          />
        )) }
      </Carousel>
      <div className={ theme.thumbsContainer }>
        <div className={ theme.thumbs }>
          {images.map((image: any, index: number) => (
            <div 
              key={index}
              className={ cn({
                [theme.active]: index == states.itemIndex,
              },
                theme.thumb) }
              onClick={ ()=> states.setItemIndex(index) }
            >
              <img
                alt={  alt }
                className={ cn({
                  [theme.active]: index == states.itemIndex,
                  }, theme.thumbImage) 
                }
                src={image}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CarouselImages;

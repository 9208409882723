import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { compact, join } from 'lodash'

import { startSubmit, stopSubmit, reset } from 'redux-form'
import { post, patch } from 'common/utils/api'
import { preparedFormData } from 'common/utils/preparedData'
import * as types from 'reducers/admin/orders/orders.constants'

import * as productTypes from 'reducers/admin/products/products.constants';
import { showSuccess, showError } from 'reducers/admin/snackbar/snackbar.actions'

function* orderResponse(response, message = null) {
  if (response.success) {
    yield put(stopSubmit('order'))

    yield put({type: types.ORDER_FORM_SUCCESS, payload: response.data.form})
    yield put({type: productTypes.SELECT_CLEAR})
    yield put(push(`/admin/orders/${response.data.form.id}/edit`))
    let messages = response.data.messages || [];
    if (message) { messages.unshift(message) }
    if(compact(messages).length) { showSuccess(join(messages, ", ")) }
  } else {
    yield put({type: types.ORDER_FORM_FAIL, payload: { error: response.error }})
    if (response.data.messages) {
      yield put(stopSubmit('order', response.data.messages))
    }
  }
}

function* fetchOrder(action: any) {
  try {
    const response = yield call(post, 'orders/get_info', action.payload)
    yield call(orderResponse, response)
  } catch (error) {
    yield put({type: types.ORDER_FORM_FAIL, error})
    showError(error)
  }
}

function* updateOrder(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('order'))
    const response = yield call(patch, 'orders', formData)
    
    if (response.data.success == false) {
      const errors = response.data.errors.products.map( hash => hash.text) 
      showError(errors)
      yield put({type: types.ORDER_FORM_FAIL, errors})
    } else {
      yield call(orderResponse, response, "Замовлення було оновлено")
    }
  } catch (error) {
    yield put({type: types.ORDER_FORM_FAIL, error})
  }
}

function* createOrder(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('order'))
    const response = yield call(post, 'orders', formData)

    yield call(orderResponse, response, "Замовлення було створено")
  } catch (error) {
    yield put({type: types.ORDER_FORM_FAIL, error})
    showError(error)
  }
}

function* checkUser(action: any) {
  const response = yield call(post, 'users/check', action.payload)

  yield put({type: types.ORDER_FORM_SYNC_USER, payload: response.data})
}

function* fetchInitial(action: any) {
  try {
    const response = yield call(post, 'orders/get_initial', action.payload)

    yield put({type: types.ORDER_FORM_INITIAL_SUCCESS, payload: response.data})
  } catch (error) {
    yield put({type: types.ORDER_FORM_FAIL, error})
    showError(error)
  }

}

export function* orderFormFetchFlow() {
  yield takeLatest(types.ORDER_FORM_FETCH, fetchOrder)
}

export function* orderFormCreateFlow() {
  yield takeLatest(types.ORDER_FORM_CREATE, createOrder)
}

export function* orderFormUpdateFlow() {
  yield takeLatest(types.ORDER_FORM_UPDATE, updateOrder)
}

export function* orderFormCheckUser() {
  yield takeLatest(types.ORDER_FORM_CHECK_USER, checkUser)
}

export function* orderFormFetchInitial() {
  yield takeLatest(types.ORDER_FORM_FETCH_INITIAL, fetchInitial)
}

import { useSelector, shallowEqual } from 'react-redux';
import { join } from 'lodash';

const useSelectors = () => {
  const { metaTags } = useSelector((state: any) => state, shallowEqual);
  const { pathname } = useSelector((state: any) => state.router.location, shallowEqual);

  return {
    ...metaTags,
    keywords: join(metaTags.keywords, ', '),
    pathname,
  }
}

export default useSelectors;

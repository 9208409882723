import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { ChromePicker } from 'react-color';

export default function ColorDialog({
  openDialog,
  dialogChange,
  onColorChange,
  value,
}) {
  return (
    <Dialog
      open={openDialog}
      onClose={()=> dialogChange(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <ChromePicker
          color={ value }
          onChangeComplete={ onColorChange }
        />
      </DialogContent>
    </Dialog>
  );
}

import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import * as reducerActions from 'reducers/application';

const useMobileSorting = (type: string) => {
  const { sorting } = useSelector((state: any) => state[type], shallowEqual);

  const isActive = (value: string) => value == sorting;
  const actions = reducerActions[`${type}Actions`];

  return {
    isActive,
    actions,
  }
}

export default useMobileSorting;

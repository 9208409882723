import React, { FC, Component } from 'react';
import { connect } from 'react-redux';

import { MainLayoutProps } from './MainLayout.interface';
import Header from 'components/admin/layouts/Header/Header.layout';
import theme from './MainLayout.module.scss';

class MainLayout extends Component<MainLayoutProps, {}> {

   componentDidMount() {
    const { userLoginCheck, user } = this.props
    userLoginCheck(user);
  }


  render() {
    const { children, crumbs } = this.props

    return (
      <div className={ theme.root }>
        <Header crumbs={ crumbs } />

        { children }

        <footer className={ theme.footer} >
          &copy; { (new Date).getFullYear() } T.The.Little. Вироби з харчового силікону
        </footer>
      </div>
    );
  }
};


const mapDispatchToProps = (dispatch: any) => ({
  userLoginCheck: (user: any) => dispatch({type: 'LOGIN_CHECK', payload: { user }})
})

const mapStateToProps = (state: any) => ({
  user: state.authorization.user
})

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);

import { useEffect } from 'react';

import { orderActions } from 'reducers/application';

import * as mHooks from '../../../hooks';

const useSite = () => {
  const selectors = mHooks.useSelectors();
  useEffect(() => {
    if (!selectors.orderShow) { return };

    window.LiqPayCheckoutCallback = () => {
      LiqPayCheckout.init({
        data: selectors.payment.data,
        signature: selectors.payment.signature,
        embedTo: "#liqpay_checkout",
        language: "uk",
        mode: "embed" // embed || popup
        }).on("liqpay.callback", (data: any) => {
          orderActions.payment.site();
        })
      };

    const serviceScript = document.createElement('script');
    serviceScript.src = 'https://static.liqpay.ua/libjs/checkout.js';
    serviceScript.async = true;
    document.body.appendChild(serviceScript);

    return () => {
      selectors.orderShow && document.body.removeChild(serviceScript);
    }
  }, [selectors.payment.id, selectors.payment.signature, selectors.orderShow]);
}

export default useSite;

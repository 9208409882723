import React, { FC } from 'react';
import { Field } from 'redux-form';

import { InstagramUrl as InstagramUrlComponent } from 'common/ui';
import { InstagramUrlProps } from './InstagramUrl.interface'
import theme from './InstagramUrl.module.scss';

const InstagramUrl: FC<InstagramUrlProps> = ({
  name,
  type = 'text',
  label
}) => {

  return (
    <Field
      className={ theme.root }
      name={ name }
      type={ type }
      label={ label || name }
      id={ name }
      component={ InstagramUrlComponent }
    />
  )
}

export default InstagramUrl;
import React, { FC } from 'react';
import classNames from 'classnames';

import { IMobileMenu } from './Menu.interface';
import * as hooks from './hooks';

import theme from './MobileMenu.module.scss';
import { Links } from '../Menu/components';
import { Drawer  } from '../';

const MobileMenu: FC<IMobileMenu> = ({
}) => {
  const { openMenu, setOpenMenu } = hooks.useMobileMenu();

  return (
    <div 
       className={ classNames({
          [theme.active]: openMenu,
        },
        [theme.root],
      )}
       onClick={ () => setOpenMenu(!openMenu) }
    >
        <div className={ theme.icon }/>
       <Drawer
        isOpened={ openMenu }
        onBackdropClick={ (isOpened: boolean) => setOpenMenu(false) }
      >
        <Links className={ theme.mobileLinks} />
      </Drawer>
    </div>
  );
}

export default MobileMenu;

import React, { useEffect } from 'react';

import * as hooks from './';

const usePictureDialog = (src: string) => {
  const states = hooks.setStates();

  useEffect(() => {
  }, []);

  return {
    states,
  }
}

export default usePictureDialog;
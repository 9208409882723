import React, { FC } from 'react';
import { Field } from 'redux-form';

import { MultiSelect } from 'common/ui';
import { IMultiSelect } from './MultiSelect.interface'
import theme from './MultiSelect.module.scss';

const MultiSelectContainer: FC<IMultiSelect> = ({
  name,
  label,
  ...props
}) => {

  return (
    <Field
      {...props}
      className={ theme.root }
      name={ name }
      label={ label || name }
      id={ name }
      component={ MultiSelect }
    />
  )
}

export default MultiSelectContainer;
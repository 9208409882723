import { all } from 'redux-saga/effects';
import {
  productsFetchFlow,
  productsTableFetchFlow,
  productsTableSearchFlow
} from './table.saga';

import {
  productFormFetchFlow,
  productFormCreateFlow,
  productFormUpdateFlow
} from './form.saga';

import {
  productsSelectFetchFlow,
  productsSelectedFetchFlow
} from './select.saga'

export function* productsFlow() {
  yield all([
    productsFetchFlow(),
    productsTableFetchFlow(),
    productsTableSearchFlow(),
    productFormFetchFlow(),
    productFormCreateFlow(),
    productFormUpdateFlow(),
    productsSelectFetchFlow(),
    productsSelectedFetchFlow()
  ]);
}

import React, { FC } from 'react';
import cn from 'classnames';
import MaskedInput from 'react-text-mask'

import { PhoneInputProps } from './PhoneInput.interface';
import { Label } from '../';

import theme from './PhoneInput.module.scss';

const PhoneInput: FC<PhoneInputProps> = ({
  className,
  id,
  input,
  type = 'text',
  label,
  meta,
  onChange = null,
  ...props
}) => {
  const { touched, error } = meta
  return(
  <div className={ cn({
    [theme.error]: touched && error,
  }, theme.root, className) }
  >
    <div className={ cn(theme.inputField, { [theme.error]: touched && error }) }>
      <Label value={ input.value } label={ label } htmlFor={ id } />
      <MaskedInput
        { ...input }
        mask={['+', '3', '8', ' ', '(', /[0]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
        placeholder="+38 (093) 123-45-67"
        onChange={ (event) => {
          input.onChange(event)
          if (onChange) { onChange(event) }
        } }
      />

    </div>

    { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
  </div>
)};

export default PhoneInput;

import { isObject, isUndefined, isNull, snakeCase, isArray, flattenDeep } from 'lodash'

export const preparedBackForm = (values) => {
  let hash = {}
  Object.keys(values).map((key) => {
    const value = values[key]
    if (!isNull(value) && !isUndefined(value)) {
      hash[snakeCase(key)] = value
    }
  })
  return hash
}

const arrayAllElements = (name, element) => {
  if (!isObject(element) && !isNull(element) && !isUndefined(element)) {
    return {key: name, value: element}
  }

  if (isArray(element)) {
    return element.map((value, index) => {
      return arrayAllElements(`${name}[${index}]`, value)
    })
  }

  if (isObject(element) && element instanceof Blob) {
    return {key: name, value: element, name: element.name}
  }

  if (isObject(element)) {
    return Object.keys(element).map((key)=> {
      return arrayAllElements(`${name}[${snakeCase(key)}]`, element[key])
    })
  }
}

export const prepareMultiplePictures = (values = []) =>
  values.map((hash: any, index: number) => { 
    if (isObject(hash)) { return { value: hash.value, position: index } }

    return { value: hash, position: (index + 1) } 
  })

export const preparedFormData = (values) => {
  const fd = new FormData()
  const tt = flattenDeep(arrayAllElements('', values));
  flattenDeep(arrayAllElements('', values)).forEach((element)=> {
    if (isUndefined(element)) { return }

    if (element.name) {
      fd.append(element.key, element.value, element.name)
    } else {
      fd.append(element.key, element.value)
    }
  })

  return fd
}

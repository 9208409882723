import { application } from 'pages';

export const mainRoutes = [
  {
    path: '/',
    Component: application.MainPage,
  },
  {
    path: '/shop',
    Component: application.ShopPage,
  },
  {
    path: '/articles',
    Component: application.ArticleMainPage,
  },
  {
    path: '/delivery',
    Component: application.DeliveryPage,
  },
  {
    path: '/payment',
    Component: application.PaymentPage,
  },
  {
    path: '/refund',
    Component: application.RefundPage,
  },
  {
    path: '/articles/:id',
    Component: application.ArticleShowPage,
  },
  {
    path: '/contacts',
    Component: application.ContactsPage,
  },
  {
    path: '/contract',
    Component: application.ContractPage,
  },
  {
    path: '/products/:id',
    Component: application.ProductShowPage,
  },
  {
    path: '/*',
    Component: application.NotFoundPage,
  },
];

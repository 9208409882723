import { FC } from 'react';

import { basketActions } from 'reducers/application';

export interface IBuy {
  colorId: number;
  productId: number;
  number: number;
}

export const buy: FC<IBuy> = ({ colorId, productId, number }) => () => {
  basketActions.addProduct({ colorId, productId, number });
  basketActions.openMenu(true);
}
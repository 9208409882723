import { store } from 'store/indexApplication';

import * as types from './articles.constants';

export const fetchArticles = () => {
  const { limit, page, sorting, search } = store.getState().articles;
  const { categories, tags } = store.getState().filters;

  store.dispatch({
    type: types.ARTICLES_FETCH,
    payload: { page, limit, sorting, search, categories, tags }
  })
}

export const changeSorting = (sorting: string) => {
  store.dispatch({
    type: types.ARTICLES_SORTING_CHANGE,
    payload: { sorting }
  })

  fetchArticles();
}

export const filterChange = ()  => {
  store.dispatch({
    type: types.ARTICLES_FLTER_CHANGE,
  });

  fetchArticles();
}

export const search = (search: string) => {
  store.dispatch({
    type: types.ARTICLES_SEARCH,
    payload: { search }
  })

  fetchArticles();
}

export const fetchArticle = (params: any) => {
  store.dispatch({
    type: types.ARTICLE_FETCH,
    payload: params
  })
}

import React, { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { GreyButton } from 'common/ui';
import { PageHeader, Category } from 'components/application/sections';
import { Recomendations } from 'components/application';

import * as hooks from './hooks';
import MainPhoto from 'images/main.jpeg';
import CategoryPhoto1 from 'images/category_1.jpeg';
import CategoryPhoto2 from 'images/category_2.jpeg';
import CategoryPhoto3 from 'images/category_3.jpeg';
import theme from './Main.module.scss';

const Main: FC<any> = ({
  
}) => {
  hooks.useMain();

  return (
    <>
      <PageHeader
        picture={ MainPhoto }
        render={ () => (
          <>
            <h2>
              Дитячі вироби із харчового силікону
            </h2>
            <NavLink to='/shop'>
              <GreyButton
                label="До покупок"
              />
            </NavLink>
          </>
        )}
        animated
      />

       <section className={ theme.categories }>
          <Category
            label="Розвивальні іграшки"
            picture={ CategoryPhoto1 }
            path="/shop"
          />

          <Category
            label="Посуд"
            picture={ CategoryPhoto2 }
            path="/shop"
          />
          
          <Category
            label="Для батьків"
            picture={ CategoryPhoto3 }
            path="/shop"
          />
      </section>
      <Recomendations title="Найпопулярніше сьогодні" />
    </>
  );
}

export default Main;

import React, { FC } from 'react';
import cn from 'classnames';

import { IPsite } from './PSite.interface';
import theme from './PSite.module.scss';

const PSite: FC<IPsite> = ({
  className
}) => {
  return (
    <div className={ cn(theme.root, className) }>
       Оплата на сайті відразу.
       <br />
       <br />
       Натисніть кнопку <strong>Перейти до оплати</strong>, щоб відобразилася форма для оплати Вашого замовлення.
    </div>
  )
}

export default PSite;

import { all } from 'redux-saga/effects';
import {
  fetchFlow,
  tableFetchFlow,
  tableSearchFlow
} from './table.saga';

import {
  formFetchFlow,
  formCreateFlow,
  formUpdateFlow
} from './form.saga';

import {
  selectFetchFlow,
  selectedFetchFlow
} from './select.saga'

export function* tagsFlow() {
  yield all([
    fetchFlow(),
    tableFetchFlow(),
    tableSearchFlow(),
    formFetchFlow(),
    formCreateFlow(),
    formUpdateFlow(),
    selectFetchFlow(),
    selectedFetchFlow()
  ]);
}

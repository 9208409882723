import { isEqual } from 'lodash';

import { store } from 'store/indexApplication';

import * as types from '../order.constants';
import * as utils from '../utils';
import * as actions from './';

export const show = (value: boolean) => {
  const { showMessage } = store.getState().order;
  if (showMessage && !value) { actions.message.show(false) }

  store.dispatch({
    type: types.ORDER_SHOW,
    payload: { value },
  })
}

export const setToStorage = () => {
  const { form } = store.getState().order;

  utils.localStorage.set(form);
}

export const changeCouldPay = () => {
  const { form } = store.getState().order;
  const { products } = store.getState().basket;
  const fProducts = utils.prepare.products(form.products);
  const bProducts = utils.prepare.products(products);
  const value = isEqual(fProducts, bProducts);

  store.dispatch({
    type: types.COULD_PAY,
    payload: value
  });
}

import { call, put, takeLatest } from 'redux-saga/effects'
import { post } from 'common/utils/api'

import * as types from 'reducers/admin/tags/tags.constants';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

const preparedValues = (tags) =>
  tags.map((tag) => {
    return {
      value: tag.id,
      label: tag.name
    }
  })

function* fetchData(action: any) {
  try {
      const response = yield call(post, 'tags/get_select', { ...action.payload })
      yield put({type: types.TAG_SELECT_SUCCESS, payload: preparedValues(response.data)})
   } catch (error) {
      yield put({type: types.TAG_SELECT_FAIL, error})
   }
}

function* selectedData(action: any) {
  try {
      const response = yield call(post, 'tags/get_selected', { ...action.payload })
      yield put({type: types.TAG_SELECT_REQUIRED_SUCESS, payload: preparedValues(response.data)})
   } catch (error) {
      yield put({type: types.TAG_SELECT_FAIL, error})
   }
}

export function* selectFetchFlow() {
  yield takeLatest(types.TAG_SELECT_FETCH, fetchData)
}

export function* selectedFetchFlow() {
  yield takeLatest(types.TAG_SELECT_REQUIRED_FETCH, selectedData)
}

import React, { FC, useEffect } from 'react';

import * as hooks from './';

const useCollapse = (open: boolean) => {

  const states = hooks.setStates(open);

  useEffect(() => {
  }, [open]);

  return {
    states,
  }
}

export default useCollapse;
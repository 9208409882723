import { useSelector, shallowEqual } from 'react-redux';

const usePayInfo = () => {
  const { data } = useSelector((state: any) => state.basket, shallowEqual);
  const { data: promocode } = useSelector((state: any) => state.promocodes, shallowEqual);

  return {
    data,
    promocode
  }
}

export default usePayInfo;

import { 
  defaultTableState, defaultFormState
} from 'reducers/admin/helpers/model'

export const initialFormState = {
  ...defaultFormState,
  order: {
    products: [],
    discount: 0,
    price: 0,
    paid: 0,
    totalPrice: 0,
    profit: 0,
    address: {
      type: "novaPosta",
    },
  },
  user: {},
  lastAddress: {}
};

export const initialTableState = {
  ...defaultTableState,
  values: {
    ...defaultTableState.values,
    selectedStatuses: []
  },
  orders: [],
  statuses: []
};

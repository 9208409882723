export const PRODUCTS_FETCH          = 'PRODUCTS/FETCH';
export const PRODUCTS_FETCH_SUCCESS  = 'PRODUCTS/FETCH_SUCCESS';
export const PRODUCTS_FETCH_ERROR    = 'PRODUCTS/FETCH_ERROR';
export const PRODUCTS_SORTING_CHANGE = 'PRODUCTS/SORTING_CHANGE';
export const PRODUCTS_SEARCH         = 'PRODUCTS/SEARCH';
export const PRODUCTS_FLTER_CHANGE   = 'PRODUCTS/FLTER_CHANGE';

export const PRODUCT_FETCH         = 'PRODUCTS/FETCH_ONE';
export const PRODUCT_FETCH_SUCCESS = 'PRODUCTS/FETCH_ONE_SUCCESS';
export const PRODUCT_FETCH_ERROR   = 'PRODUCTS/FETCH_ONE_ERROR';

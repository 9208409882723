import React from 'react';

export const novaPosta = (price: number, back: string = '') => {
  if (back == 'novaPosta') {
    return (
      <>
        За тарифами нової пошти (безкоштовної доставки на наложений платіж <strong>не має</strong>, 
        незалежно від суми замовлення. При попередній оплаті на сайті або за реквізитами на суму від 1500 грн.
        доставка <strong>безкоштовна</strong>)
      </>
    )
  }

  if (price >= 1500) { return <strong>безкоштовна</strong> }
  
  return (
    <>
      За тарифами нової пошти (при замовленні на суму від 1500 грн., 
      доставка <strong>безкоштовна</strong>)
    </>
  )
}
import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { post } from 'common/utils/api'
import { store } from 'store/indexAdmin';

import {
  TABLE_FETCH,
  TABLE_FAIL,
  TABLE_SUCCESS,
  TABLE_VALUES,
  TABLE_SEARCH,
} from 'reducers/admin/purchases/purchases.constants';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* fetchPurchases(action: any) {
  try {
      const response = yield call(post, 'purchases/all', action.payload)
      yield put({type: TABLE_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: TABLE_FAIL, error})
   }
}

function* loadinData(payload: any = {}) {
  const response = yield call(post, 'purchases/all', { ...payload })

  if (response.failure) {
    yield put({type: TABLE_FAIL})
    return false
  }
  yield put({type: TABLE_SUCCESS, payload: response.data})
}

function* searchTablePurchases(action: any) {
  yield call(delay, 500)
  yield call(fetchTablePurchases)
}

function* fetchTablePurchases(action: any) {
  const { getState } = store;
  const { purchasesData: { table: { values } } } = getState()
  yield put({type: TABLE_FETCH, payload: values})
}

export function* purchasesFetchFlow() {
  yield takeLatest(TABLE_FETCH, fetchPurchases)
}

export function* purchasesTableFetchFlow() {
  yield takeLatest(TABLE_VALUES, fetchTablePurchases)
}

export function* purchasesTableSearchFlow() {
  yield takeLatest(TABLE_SEARCH, searchTablePurchases)
}


import React, { FC } from 'react';

import theme from './NotFoundPageLayout.module.scss';

const NotFoundPageLayout: FC = ({ children }) => {
  return (
    <div className={ theme.root }>
      <main className={ theme.content }>
        <section className={ theme.section }>
          <div className={ theme.sectionContent }>
            { children }
          </div>
        </section>
      </main>
    </div>
  );
};

export default NotFoundPageLayout;

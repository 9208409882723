import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Icon } from 'common/ui';

export default function PictureDialog({
  openDialog,
  dialogChange,
  onImageLoaded,
  onComplete,
  onChange,
  src,
  crop,
  extraCrop
}) {
  return (
    <div>
    <Dialog
      open={openDialog}
      onClose={()=> dialogChange(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
          <span>
            {src && (
              <ReactCrop
                { ...extraCrop }
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onComplete}
                onChange={onChange}
              />
            )}
          </span>
      </DialogContent>
    </Dialog>
    </div>
  );
}

import React, { FC, useEffect, useState } from 'react';

const setStates = () => {
  const [fixFilters, setFixFilters] = useState(false);

  return {
    fixFilters,
    setFixFilters,
  }
}

export default setStates;

export const TABLE_FETCH   = 'PURCHASES/TABLE/FETCH';
export const TABLE_SUCCESS = 'PURCHASES/TABLE/SUCCESS';
export const TABLE_FAIL    = 'PURCHASES/TABLE/FAIL';
export const TABLE_VALUES  = 'PURCHASES/TABLE/VALUES';
export const TABLE_SEARCH  = 'PURCHASES/TABLE/SEARCH';

export const FORM_FETCH   = 'PURCHASES/FORM/FETCH';
export const FORM_SUCCESS = 'PURCHASES/FORM/SUCCESS';
export const FORM_FAIL    = 'PURCHASES/FORM/FAIL';
export const FORM_UPDATE  = 'PURCHASES/FORM/UPDATE';
export const FORM_CREATE  = 'PURCHASES/FORM/CREATE';

export const SELECT_FETCH_STATUSES = 'PURCHASES/SELECT/FETCH_STATUSES';
export const SELECT_STATUS_SUCCESS = 'PURCHASES/SELECT/STATUS_SUCCESS';
export const SELECT_STATUS_FAIL    = 'PURCHASES/SELECT/STATUS_FAIL';

import React, { FC } from 'react';
import cn from 'classnames';

import { InputProps } from './Input.interface';
import { Label } from '../';

import theme from './Input.module.scss';

const Input: FC<InputProps> = ({
  className,
  id,
  input,
  type = 'text',
  label,
  meta: { touched, error },
  onChange,
  ...props
}) => {
  return (
    <div className={ cn({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      <Label value={ input.value } label={ label } htmlFor={ id } />
      <div className={ cn(theme.inputField, { [theme.error]: touched && error }) }>
        <input 
          { ...input }
          id={ id }
          type={type}
          placeholder={ label }
          onChange={(event) => {
            input.onChange(event)
            if(onChange) { onChange(event) }
          }}
        />
      </div>

      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
  )
};

export default Input;

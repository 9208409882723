import React, { FC } from 'react';
import { Field } from 'redux-form';

import { HtmlEditor as HtmlEditorComponent } from 'common/ui';
import { IHtmlEditor } from './HtmlEditor.interface'
import theme from './HtmlEditor.module.scss';

const HtmlEditor: FC<IHtmlEditor> = ({
  name,
  label,
  onChange = null
}) => {

  return (
    <Field
      className={ theme.root }
      name={ name }
      label={ label || name }
      id={ name }
      elementChange={ onChange }
      component={ HtmlEditorComponent }
    />
  )
}

export default HtmlEditor;
import React, { FC, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { metaTagsActions } from 'reducers/application';
import * as hooks from './';

const useDelivery = () => {
  const { pathname } = useSelector((state: any) => state.router.location, shallowEqual);

  useEffect(() => {
    metaTagsActions.change({
      title: 'T.The.Little - доставка',
    });
  }, [pathname]);

  return {
  }
}

export default useDelivery;

import React, { FC } from 'react';
import cn from 'classnames';
import { split, max, times, constant, concat, join } from 'lodash'
import MaskedInput from 'react-text-mask'

import { InstagramUrlProps } from './InstagramUrl.interface';

import theme from './InstagramUrl.module.scss';
import { Label } from '../';

const InstagramUrl: FC<InstagramUrlProps> = ({
  className,
  id,
  input,
  type = 'text',
  label,
  meta: { touched, error },
  ...props
}) => (
  <div className={ cn({
    [theme.error]: touched && error,
  }, theme.root, className) }
  >
    <div className={ cn(theme.inputField, { [theme.error]: touched && error }) }>
      <Label value={ input.value } label={ label } htmlFor={ id } />
      <MaskedInput
        { ...input }
        mask={(rowUrl)=> {
          const path = split(rowUrl, '.com/', 2)[1]
          const nick = split(path, '/', 2)[0]
          const regular = /[a-zA-Z0-9\.-]/g
          const allowedSymbols = /[a-z0-9A-Z\-.]/
          const defaultMask = ['h', 't', 't', 'p', 's', ':', '/', '/', 'w', 'w', 'w', '.', 'i', 'n', 's', 't', 'a', 'g', 'r', 'a', 'm', '.', 'c', 'o', 'm', '/']
          const neededMask = times(max([(nick.match(regular) || '').length, 1]), constant(allowedSymbols))
          return concat(defaultMask, neededMask, '/')
        }}
        placeholder={ label || "https://www.instagram.com/t.the.little/" }
      />

    </div>

    { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
  </div>
);

export default InstagramUrl;

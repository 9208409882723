import React, { FC } from 'react';
import cn from 'classnames';

import * as hooks from './hooks';
import { ICollapse } from './Collapse.interface';
import theme from './Collapse.module.scss';

const Collapse: FC<ICollapse> = ({
  className,
  children,
  title,
  onClick,
  open = false,
}) => {
  const { states } = hooks.useCollapse(open);

  return (
    <div className={ cn({
        },
        [theme.root],
        className,
      )}
    >
      <div className={ theme.title } onClick={ ()=> states.setIsOpen(!states.isOpen) }>
        <span>{ title }</span>
        <div className={cn({[theme.iconOpen]: !states.isOpen}, theme.icon )}/>
      </div>
      <div className={ cn({ [theme.bodyOpen]: states.isOpen, 
        }, theme.body) }>
        { children }
      </div>
    </div>
  );
}

export default Collapse;

import { store } from 'store/indexApplication';

import * as types from './products.constants';

export const fetchProducts = () => {
  const { limit, page, sorting, search } = store.getState().products;
  const { categories, prices, colors } = store.getState().filters;

  store.dispatch({
    type: types.PRODUCTS_FETCH,
    payload: { page, limit, sorting, search, categories, prices, colors }
  })
}

export const changeSorting = (sorting: string) => {
  store.dispatch({
    type: types.PRODUCTS_SORTING_CHANGE,
    payload: { sorting }
  })

  fetchProducts();
}

export const filterChange = ()  => {
  store.dispatch({
    type: types.PRODUCTS_FLTER_CHANGE,
  });

  fetchProducts();
}

export const search = (search: string) => {
  store.dispatch({
    type: types.PRODUCTS_SEARCH,
    payload: { search }
  })

  fetchProducts();
}

export const fetchProduct = (params: any) => {
  store.dispatch({
    type: types.PRODUCT_FETCH,
    payload: params
  })
}

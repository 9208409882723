import { initialFormState } from './tags.model';
import * as types from './tags.constants';

export const form = (state = initialFormState, action: any) => {
  switch(action.type) {
    case types.TAG_FORM_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case types.TAG_FORM_UPDATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case types.TAG_FORM_CREATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case types.TAG_FORM_SUCCESS: {
      return {
        ...state,
        tag: action.payload,
        loading: false,
        error: '',
      };
    }
    case types.TAG_FORM_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch tag',
      };
    }
    default: {
      return state;
    }
  }
};

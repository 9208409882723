import { FormErrors } from 'redux-form';

import { user, address, payment } from './validations';

export const validate = (values): FormErrors => {

  return {
    user: user(values.user || {}),
    address: address(values.address || {}),
    payment: payment(values.payment || {}),
  }
}

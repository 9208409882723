import React, { FC } from 'react';
import cn from 'classnames';
import { compact } from 'lodash';

import { PayInfo } from 'components/application';
import { PNovaPosta } from '../../../';
import { RadioGroup } from 'common/ui/FormElements';
import * as mHooks from '../../hooks';

import { Site, Card } from '../';
import { IBody } from './Body.interface';
import theme from './Body.module.scss';

const Body: FC<IBody> = ({ 
  className,
  price,
}) =>  {
  const mSelectors = mHooks.useSelectors();

  const pType = mHooks.form.selector('type');
  const aType = mSelectors.address.type;

  return(
    <div className={ cn(theme.root, className) }>
      <RadioGroup
        name="type"
        label="Вид оплати"
        values={compact([
          aType == 'novaPosta' && price >= 500 && { value: 'novaPosta', label: 'Оплата при отримані (наложений платіж на новій пошті)' },
          { value: 'site', label: 'На сайті' },
          // { value: 'card', label: 'За реквізитами' },
        ])}
      />
      { pType == 'novaPosta' && <PNovaPosta className={ theme.type }/> }
      { pType == 'site' && <Site className={ theme.type }/> }
      { pType == 'card' && <Card className={ theme.type }/> }
      <PayInfo back={ pType } delivery={ aType } />
    </div>
  )
}

export default Body;

import React, { FC, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import MaterialTable from 'material-table';
import { Grid, TablePagination } from '@material-ui/core';

import Loader from 'components/admin/layouts/Loader/Loader.layout';
import { humanValue } from 'common/utils/humanView'
import { openNewTableForm } from 'reducers/admin/helpers/actions';
import { TableProps } from './Table.interface';
import { localization } from './Table.attributes'
import {
  preparedColumns,
  handleOnOrderChange,
  handleOnChangeRowsPerPage
} from './Table.container'
import theme from './Table.module.scss';

const TableLayout: FC<TableProps> = ({
  data, columns, changeTableValue, stateTable,
  searchTable, path, title = '', options = {},
  showPath,
}) => {

  const { totalCount, values, loading, extraInfo = [] } = useSelector((state: any) => state[stateTable].table, shallowEqual);
  const defaultOptions = {
    pageSizeOptions: [10, 25, 50],
    pageSize: values.pageSize,
    emptyRowsWhenPaging: false,
    searchText: values.query,
    searchAutoFocus: values.searchFocus
  }

  return (
    <div className={ theme.table }>
      {
        extraInfo.length &&
        <Grid container spacing={3} className={theme.extraInfo}>
          {extraInfo.map((info, index) =>
            <Grid item lg={2} md={3} key={index}>
              <strong>{info.name}:</strong> {humanValue(info)}
            </Grid>
          )}
        </Grid> || null
      }
      <MaterialTable
        title={ title }
        columns={ preparedColumns(columns, values.sorted, path, showPath) }
        data={ data }
        isLoading={ loading }
        localization={localization}
        options={{
          ...defaultOptions,
          ...options
        }}
         actions={[
          {
            icon: 'add',
            tooltip: 'Додати',
            isFreeAction: true,
            onClick: (event) => openNewTableForm(path)
          }
        ]}
        onOrderChange={ handleOnOrderChange(changeTableValue, columns) }
        onSearchChange={ searchTable }
        components={{
          Pagination: props => (
            <TablePagination { ...props }
              count={ totalCount }
              page={ values.page }
              onChangePage={ (event, page) => {
                changeTableValue({ page,
                  searchFocus: false
                })
              }}
              onChangeRowsPerPage={ handleOnChangeRowsPerPage(changeTableValue) }
            />
          ),
          OverlayLoading: (props) => (
            <Loader />
          ),
        }}
      />
    </div>
  );
}

export default TableLayout;

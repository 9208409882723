import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { MenuList, MenuItem } from '@material-ui/core'

import { logout } from 'reducers/authorization/authorization.actions'
import { UserMenuProps } from './UserMenu.interface';
import theme from './UserMenu.module.scss';

const UserMenu: FC<UserMenuProps> = ({ isOpened, onBackdropClick, id }) => {
  return createPortal(
      <div className={ classNames({
        [theme.isOpened]: isOpened,
      }, theme.root) }>
        <div
          className={ theme.backdrop }
          onClick={ onBackdropClick }
        ></div>
        <MenuList
          autoFocusItem={isOpened}
          onKeyDown={onBackdropClick}
        >
          <MenuItem>
            <NavLink to={`/admin/users/${id}/edit`}>
              Редагувати
            </NavLink>
          </MenuItem>
          <MenuItem onClick={ logout }>Вихід</MenuItem>
        </MenuList>
    </div>,
    document.body
  )
};

export default UserMenu;

import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { startSubmit, stopSubmit, reset } from 'redux-form'
import { post, patch } from 'common/utils/api'
import { store } from 'store/indexAdmin';
import { preparedFormData } from 'common/utils/preparedData'

import {
  FORM_FETCH,
  FORM_SUCCESS,
  FORM_FAIL,
  FORM_UPDATE,
  FORM_CREATE,
} from 'reducers/admin/purchases/purchases.constants';
import { showSuccess, showError } from 'reducers/admin/snackbar/snackbar.actions'

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* purchaseResponse(response, message = null) {
  if (response.success) {
    const { authorization: { purchase } } = store.getState()
    yield put(stopSubmit('purchase'))

    yield put({type: FORM_SUCCESS, payload: response.data})
    const responseId = response.data.id

    if (responseId) {
      yield put(push(`/admin/purchases/${responseId}/edit`))
    }
    if (message) { showSuccess(message) }
  } else {
    yield put({type: FORM_FAIL, payload: { error: response.error }})
    if (response.data.messages) {
      yield put(stopSubmit('purchases', response.data.messages))
    }
  }
}

function* fetchPurchase(action: any) {
  try {
    const response = yield call(post, 'purchases/get_info', action.payload)
    yield call(purchaseResponse, response)
  } catch (error) {
    yield put({type: FORM_FAIL, error})
    showError(error)
  }
}

function* updatePurchase(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('purchase'))
    const response = yield call(patch, 'purchases', formData)

    yield call(purchaseResponse, response, "Позиція калькулятора була оновлена")
  } catch (error) {
    yield put({type: FORM_FAIL, error})
    showError(error)
  }
}

function* createPurchase(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('purchase'))
    const response = yield call(post, 'purchases', formData)

    yield call(purchaseResponse, response, "Позиція калькулятора була створена")
  } catch (error) {
    yield put({type: FORM_FAIL, error})
    showError(error)
  }
}

export function* purchaseFormFetchFlow() {
  yield takeLatest(FORM_FETCH, fetchPurchase)
}

export function* purchaseFormCreateFlow() {
  yield takeLatest(FORM_CREATE, createPurchase)
}

export function* purchaseFormUpdateFlow() {
  yield takeLatest(FORM_UPDATE, updatePurchase)
}

import { uniq } from 'lodash'

import { initialSelectState } from './categories.model';
import * as types from './categories.constants';

export const select = (state = initialSelectState, action: any) => {
  switch(action.type) {
    case types.CATEGORY_SELECT_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case types.CATEGORY_SELECT_SUCCESS: {
      return {
        ...state,
        categories: action.payload,
        loading: false,
        error: '',
      };
    }
    case types.CATEGORY_SELECT_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch categories',
      };
    }
    default: {
      return state;
    }
  }
};

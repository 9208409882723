import { initialFormState } from './purchases.model';
import {
  FORM_FETCH,
  FORM_SUCCESS,
  FORM_FAIL,
  FORM_UPDATE,
  FORM_CREATE
} from './purchases.constants';

export const form = (state = initialFormState, action: any) => {
  switch(action.type) {
    case FORM_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case FORM_UPDATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case FORM_CREATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case FORM_SUCCESS: {
      return {
        ...state,
        purchase: action.payload,
        loading: false,
        error: '',
      };
    }
    case FORM_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch purchase',
      };
    }
    default: {
      return state;
    }
  }
};

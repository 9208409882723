import { initialState } from './articles.model';
import * as types from './articles.constants';

export const articles = (state = initialState, action: any) => {
  switch(action.type) {
    case types.ARTICLES_FETCH: {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }
    case types.ARTICLES_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        refresh: false,
        errors: null,
        data: state.refresh ? action.payload.data : [...state.data, ...action.payload.data],
        page: ++state.page,
        leftMore: action.payload.leftMore,
      };
    }
    case types.ARTICLES_FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }
    case types.ARTICLES_SORTING_CHANGE: {
      return {
        ...state,
        sorting: action.payload.sorting,
        page: 1,
        errors: null,
        leftMore: true,
        refresh: true,
      };
    }
    case types.ARTICLES_SEARCH: {
      return {
        ...state,
        search: action.payload.search,
        page: 1,
        errors: null,
        leftMore: true,
        refresh: true,
      };
    }
    case types.ARTICLES_FLTER_CHANGE: {
      return {
        ...state,
        page: 1,
        errors: null,
        leftMore: true,
        refresh: true,
      };
    }
    case types.ARTICLE_FETCH: {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }
    case types.ARTICLE_FETCH_SUCCESS: {
      return {
        ...state,
        article: action.payload,
        loading: false,
        errors: null,
      };
    }
    case types.ARTICLE_FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }
    default: {
      return state;
    }
  }
};

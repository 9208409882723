import { initialFormState } from './products.model';
import {
  PRODUCT_FORM_FETCH,
  PRODUCT_FORM_SUCCESS,
  PRODUCT_FORM_FAIL,
  PRODUCT_FORM_UPDATE,
  PRODUCT_FORM_CREATE
} from './products.constants';

export const form = (state = initialFormState, action: any) => {
  switch(action.type) {
    case PRODUCT_FORM_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case PRODUCT_FORM_UPDATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case PRODUCT_FORM_CREATE: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case PRODUCT_FORM_SUCCESS: {
      return {
        ...state,
        product: action.payload,
        loading: false,
        error: '',
      };
    }
    case PRODUCT_FORM_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch product',
      };
    }
    default: {
      return state;
    }
  }
};

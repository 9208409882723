import React, { FC } from 'react';
import cn from 'classnames';
import { Grid } from '@material-ui/core';

import { Form, SubmitButton } from 'common/ui';
import { Input, TextField } from 'common/ui/FormElements';
import { questionActions } from 'reducers/application';

import { IQuestionForm } from './QuestionFrom.interface';
import theme from './QuestionForm.module.scss';
import { validate } from './validate';
import * as hooks from './hooks';

const QuestionFrom: FC<IQuestionForm> = ({
  className,
}) => {

   const { selectors } = hooks.useQuestionForm();

  return (
    <div className={ cn(className) }>
      <div className={ theme.header }>
        Виникли питання?
      </div>
      <div className={ theme.info }>
        Надішліть ваше запитання нам, і наш менеджер зв'яжеться з вами.
      </div>
       <Form
        onSubmit={ questionActions.formCreate }
        form='question'
        initialValues={ selectors.form }
        validate={ validate }
        className={ theme.form }
        showButtons={ false }
      >
        <Grid item md={12} xs={12}>
          <Input name="email" label="Email" type="email" />
          <TextField name="question" label="Запитання" />
          <SubmitButton
            className={ theme.button }
            label="Надіслати"
            labelSending="Надсилання"
            property="grey"
            disabled={ false }
            sending={ selectors.sending }
           /> 
        </Grid>
      </Form>
    </div>
  );
}

export default QuestionFrom;

import { call, put, takeLatest } from 'redux-saga/effects'
import { post } from 'common/utils/api'

import * as types from 'reducers/admin/promocodes/promocodes.constants';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

const preparedValues = (promocodes) =>
  promocodes.map((promocode) => {
    return {
      value: promocode.id,
      label: promocode.name
    }
  })

function* fetchData(action: any) {
  try {
      const response = yield call(post, 'promocodes/get_select', { ...action.payload })
      yield put({type: types.PROMOCODE_SELECT_SUCCESS, payload: preparedValues(response.data)})
   } catch (error) {
      yield put({type: types.PROMOCODE_SELECT_FAIL, error})
   }
}

function* selectedData(action: any) {
  try {
      const response = yield call(post, 'promocodes/get_selected', { ...action.payload })
      yield put({type: types.PROMOCODE_SELECT_REQUIRED_SUCESS, payload: preparedValues(response.data)})
   } catch (error) {
      yield put({type: types.PROMOCODE_SELECT_FAIL, error})
   }
}

export function* selectFetchFlow() {
  yield takeLatest(types.PROMOCODE_SELECT_FETCH, fetchData)
}

export function* selectedFetchFlow() {
  yield takeLatest(types.PROMOCODE_SELECT_REQUIRED_FETCH, selectedData)
}

import React, { FC } from 'react';
import cn from 'classnames';

import { IPNovaPosta } from './PNovaPosta.interface';
import theme from './PNovaPosta.module.scss';

const PNovaPosta: FC<IPNovaPosta> = ({
  className
}) => {

  return (
    <div className={ cn(theme.root, className) }>
      При попередній оплаті через сайт Вам буде нараховано <strong>додаткову знижку</strong>.
      <br/>
      <br/>
      Щоб отримати найбільшу знижку, рекомендуємо здійснювати попередню оплату <strong>за реквізитами</strong>.
    </div>
  )
}

export default PNovaPosta;

import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { startSubmit, stopSubmit, reset } from 'redux-form'
import { post, patch } from 'common/utils/api'
import { store } from 'store/indexAdmin';
import { preparedFormData } from 'common/utils/preparedData'

import {
  USER_FORM_FETCH,
  USER_FORM_SUCCESS,
  USER_FORM_FAIL,
  USER_FORM_UPDATE,
  USER_FORM_CREATE,
} from 'reducers/admin/users/users.constants';
import { LOGIN_SUCCESS } from 'reducers/authorization/authorization.constants'
import { showSuccess, showError } from 'reducers/admin/snackbar/snackbar.actions'

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* userResponse(response, message = null) {
  if (response.success) {
    const { authorization: { user } } = store.getState()
    yield put(stopSubmit('user'))

    yield put({type: USER_FORM_SUCCESS, payload: response.data})
    const responseId = response.data.id

    if (user.id === responseId) {
      yield put({type: LOGIN_SUCCESS, payload: { user: response.data }})
    }
    if (responseId) {
      yield put(push(`/admin/users/${responseId}/edit`))
    }
    if (message) { showSuccess(message) }
  } else {
    yield put({type: USER_FORM_FAIL, payload: { error: response.error }})
    if (response.data.messages) {
      yield put(stopSubmit('user', response.data.messages))
    }
  }
}

function* fetchUser(action: any) {
  try {
    const response = yield call(post, 'users/get_info', action.payload)
    yield call(userResponse, response)
  } catch (error) {
    yield put({type: USER_FORM_FAIL, error})
    showError(error)
  }
}

function* updateUser(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('user'))
    const response = yield call(patch, 'users', formData)

    yield call(userResponse, response, "Користувач був оновлений")
  } catch (error) {
    yield put({type: USER_FORM_FAIL, error})
    showError(error)
  }
}

function* createUser(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('user'))
    const response = yield call(post, 'users', formData)

    yield call(userResponse, response, "Користувач був створений")
  } catch (error) {
    yield put({type: USER_FORM_FAIL, error})
    showError(error)
  }
}

export function* userFormFetchFlow() {
  yield takeLatest(USER_FORM_FETCH, fetchUser)
}

export function* userFormCreateFlow() {
  yield takeLatest(USER_FORM_CREATE, createUser)
}

export function* userFormUpdateFlow() {
  yield takeLatest(USER_FORM_UPDATE, updateUser)
}

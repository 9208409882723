import { store } from 'store/indexApplication';

import * as types from './constants';

export const fetchData = (params: any = {}) => {
  const { code } = store.getState().promocodes;

  store.dispatch({
    type: types.FETCH_DATA,
    payload: { code, ...params }
  })
}

export const changeCode = (event: any) => {
  const code = event.target.value || '';
  const { data } = store.getState().promocodes;

  store.dispatch({
    type: types.CHANGE_CODE,
    payload: { code }
  })

  if (code && code.length > 7) {
    fetchData();
  }

  if (code && code.length < 8 && data.discount) {
    clearData();
  }
}

export const clearData = () => {
  store.dispatch({
    type: types.CLEAR_DATA,
  })
}
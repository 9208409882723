import React, { FC } from 'react';
import classNames from 'classnames';

import { IGreyButton } from './GreyButton.interface';
import theme from './GreyButton.module.scss';

const GreyButton: FC<IconProps> = ({ 
  className = '',
  type = 'white',
  label,
  onClick,
  disable = false,
}) => (
  <div
    className={ classNames({
        [theme.white]: type === 'white',
        [theme.grey]: type === 'grey',
        [theme.disable]: disable,
      },
      [theme.root], 
      className,
    )}
    onClick={ (e) => { !disable && onClick && onClick(e) } }
  >
    { label }
  </div>
);

export default GreyButton;
import { 
  materialDate, monthStartYear, monthEndYear 
} from 'common/utils/dateTime'

export const initialValues = () => {
	const dateNow = new Date
  const year = dateNow.getFullYear();
  const month = dateNow.getMonth();

  return {
    year,
    month,
    from: materialDate(monthStartYear(year, month)),
    to: materialDate(monthEndYear(year, month)),
    active: false
  }
}

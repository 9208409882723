import { 
  defaultTableState, defaultFormState, defaultSelectState
} from 'reducers/admin/helpers/model'

export const initialFormState = {
  ...defaultFormState,
  tag: {
    synonyms: [],
  },
};

export const initialTableState = {
  ...defaultTableState,
  tags: []
};

export const initialSelectState = {
  ...defaultSelectState,
  tags: [],
};

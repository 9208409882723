import React, { FC, useState } from 'react';
import classNames from 'classnames'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Icon } from 'common/ui';
import { MultiplePictureInputProps } from './MultiplePictureInput.interface';
import { PictureDialog, Images } from './components'
import * as hooks from './hooks';

import theme from './MultiplePictureInput.module.scss';

const MultiplePictureInput = ({
  crop,
  id,
  input,
  label,
  className,
  extraCrop,
  meta: { touched, error },

}) => {
  const { 
    states, onCropComplete, onSelectFile, onDialogClose, removeImage, moveImage,
  } = hooks.useMultiplePictureInput(crop, input);
  return(
    <div className={ classNames({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      { label && <label className={ theme.label } htmlFor={ id }>
          { label }
        </label>
      }

      <div className={ theme.inputField }>
        <Images 
          states={states} 
          removeImage={ removeImage }
          id={ id }
          onSelectFile={ onSelectFile }
          moveImage={ moveImage }
        />

        <PictureDialog
          extraCrop={ extraCrop }
          openDialog={states.openDialog}
          dialogClose={ onDialogClose }
          src={states.src}
          crop={states.crop}
          onImageLoaded={ states.setImageRef }
          onComplete={onCropComplete}
          onChange={states.setCrop}
        />
      </div>

      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
  );
}

export default MultiplePictureInput;

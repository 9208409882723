import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as hooks from './hooks';
import { IMainLayout } from './MainLayout.interface';
import { Header } from 'components/application/layouts';
import theme from './MainLayout.module.scss';
import { MetaTags } from './components';


const MainLayout: FC<IMainLayout> = ({
  crumbs,
  children,
}) => {
  hooks.useMainLayout();

  return (
    <div className={ theme.root }>
      <MetaTags />
      <Header crumbs={ crumbs } />

      { children }

      <footer className={ theme.footer} >
        <ul className={ theme.columns }>
          <li>&copy; { (new Date).getFullYear() } T.The.Little. Вироби з харчового силікону</li>
          <li><Link to='/contract'>Публічний договір</Link></li>
        </ul>
      </footer>
    </div>
  );
}

export default MainLayout;

import { initialState } from './novaPosta.model';
import * as types from './novaPosta.constants';

export const novaPosta = (state = initialState, action: any) => {
  switch(action.type) {
    case types.FETCHING_WAREHOUSES: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.WAREHOUSE_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        warehouses: action.payload.data,
        loadedWarehouses: true,
      };
    }
    case types.CHANGE_WAREHOUSE_ID: {
      return {
        ...state,
        warehouseId: action.payload.warehouseId,
        warehouse2Id: null,
      };
    }
    case types.CHANGE_WAREHOUSE_2_ID: {
      return {
        ...state,
        warehouse2Id: action.payload.warehouse2Id,
      };
    }
    case types.WAREHOUSE_FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }
    case types.FETCHING_CITIES: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.CHANGE_CITY: {
      return {
        ...state,
        loadedWarehouses: false,
        cityId: action.payload.cityId,
        warehouseId: null,
        warehouse2Id: null,
      };
    }
    case types.CLEAR: {
      return {
        ...state,
        loadedWarehouses: false,
        cityId: null,
        warehouseId: null,
        warehouse2Id: null,
      };
    }
    case types.CITY_FETCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        cities: action.payload.data,
        loadedCities: true,
      };
    }
    case types.CITY_FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }
    default: {
      return state;
    }
  }
};

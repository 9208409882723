import React, { FC } from 'react';
import { Field } from 'redux-form';

import { ColorInput as ColorInputComponent } from 'common/ui';
import { ColorInputProps } from './PictureInput.interface'
import theme from './ColorInput.module.scss';

const ColorInput: FC<ColorInputProps> = ({
  name,
  label,
  crop,
  ...extraCrop
}) => {
  return (
    <Field
      className={ theme.root }
      name={ name }
      type='text'
      label={ label || name }
      id={ name }
      component={ ColorInputComponent }
      crop={ crop }
      extraCrop={ extraCrop }
    />
  )
}

export default ColorInput;

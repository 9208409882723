import { useSelector, shallowEqual } from 'react-redux';

const useSelectors = () => {
  const { question } = useSelector((state: any) => state, shallowEqual);
 
  return {
    ...question,
  }
}

export default useSelectors;

import { all } from 'redux-saga/effects';

import * as warehouses from './warehouses.saga';
import * as cities from './cities.saga';

export function* novaPostaFlow() {
  yield all([
    warehouses.fetch(),
    cities.fetch(),
  ]);
}

import React, { FC } from 'react';
import { Field } from 'redux-form';

import { DateTime } from 'common/ui';
import { DateTimeProps } from './DateTime.interface'
import theme from './DateTime.module.scss';

const FormDateTime: FC<DateTimeProps> = ({
  name,
  label,
  onChange = null,
  ...props
}) => {

  return (
    <Field
      { ...props }
      className={ theme.root }
      name={ name }
      label={ label || name }
      id={ name }
      elementChange={onChange}
      component={ DateTime }
    />
  )
}

export default FormDateTime;
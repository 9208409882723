import validate from 'validate.js'
import { isEmpty, isArray, isPlainObject, compact, join } from 'lodash'

validate.validators.phone = (value, _options, _key, _attributes) => {
  try {
    const regular = /^\+38 \(0\d{2}\) \d{3}-\d{2}-\d{2}/g
    if (!value) { return null }

    const result = value.match(regular)
    if (!result) { return 'має невірний формат' }

    return null
  } catch (e) {
    return 'має невірний формат'
  }
}

validate.validators.instagramUrl = (value, _options, _key, _attributes) => {
  try {
    const regular = /^https:\/\/www.instagram.com\/(\w|.|-)*\//g
    if (!value) { return null }

    const result = value.match(regular)
    if (!result) { return 'має невірний формат' }

    return null
  } catch (e) {
    return 'має невірний формат'
  }
}

validate.validators.requiredArray = (value, options, key, attributes) => {
  const valid = isArray(value) && isPlainObject(value[0])
  
  if (valid) { return null }

  return 'не можуть бути пустими'
}

validate.validators.presentOneOfMany = (value, options, key, attributes) => {
  const constraints = { presence: { allowEmpty: false } }
  const valid = options.columns.some((column) => {
    const error = validate.single(attributes[column], constraints)
    return isEmpty(error)
  })

  if (!valid) {
    return `або ${options.translates.join(' або ')} має бути заповненим`
  }
  return null
}

validate.validators.array = (values = [], constraints, key, attributes) =>{
  const errors = compact(values.map((object)=> {
    return errorHash(object, constraints) || undefined
  }))

  if (errors.length === 0) { return null }

  return errors
}

validate.validators.file = (value, constraints, key, attributes) => {
  if (!value) { return null }
  if (constraints.formats) {
    const format = value.name.split('.').pop().toLowerCase();
    
    if (!constraints.formats.includes(format)) {
      return `невірний формат файлу, дозволені формати: ${join(constraints.formats, ', ')}`
    }
  }

  return null;
}

const errorHash = (values: any, constraints: any, options: any = {}) =>
  validate(values, constraints, options)

export { errorHash }

import React, { FC } from 'react';
import { Grid, Button } from '@material-ui/core';

import { Form, Icon } from 'common/ui';
import { DateTime, RadioGroup, Select } from 'common/ui/FormElements';
import { yearOptions, monthOptions } from 'common/utils/selectData'
import { validate } from './validate';
import theme from './DateRange.module.scss'

const Message = ({ setActiveSort }) =>
  <Grid container spacing={3} className={theme.gritOpen}>
    <Grid item lg={2} md={3}>
      <Button
        variant="outlined" 
        onClick={() => setActiveSort(true)}
        startIcon={<Icon className={theme.btnIcon} name="date-range-outlined" />}
      >
        Сортувати за датою
      </Button>
    </Grid>
  </Grid>

const FormWithData = ({
  table, initialValues, form, recalculateRange, setActiveSort
})=> {
  return (
    <Form
      onSubmit={ () => {} }
      form={form}
      initialValues={ initialValues }
      validate={ validate }
      showButtons={false}
      className={theme.root}
    >
      <Grid container spacing={3}>
        <Grid item lg={2} md={3}>
          <DateTime 
            name="from"
            label="Від"
            type="date"
          />
        </Grid>

        <Grid item lg={2} md={3}>
          <DateTime 
            name="to"
            label="До"
            type="date"
          />
        </Grid>

        <Grid item lg={2} md={3}>
          <Select 
            name="year"
            label="Рік"
            options={yearOptions()}
            onChange={(year) => {
              recalculateRange(year)
            }}
          />
        </Grid>

        <Grid item lg={2} md={3}>
          <Select 
            name="month"
            label="Місяць"
            options={monthOptions()}
            onChange={(month) => {
              recalculateRange(null, month)
            }}
          />
        </Grid>
        <Grid item lg={2} md={3} className={theme.gritClose}>
          <Button
            variant="outlined"
            startIcon={<Icon className={theme.btnIcon} name="close-outlined" />}
            onClick={() => setActiveSort(false)}
          >
            Очистити сортування
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}

const DateRange = ({
  activeSort, setActiveSort, ...props
})=> {
  return (
    <>
      {
        activeSort && <FormWithData 
          {...props}
          setActiveSort={setActiveSort}
        /> || <Message setActiveSort={setActiveSort} />

      }
    </>
  )    
}

export default DateRange

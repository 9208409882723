import { uniq } from 'lodash'

import { initialSelectState } from './products.model';
import * as types from './products.constants';
import * as utils from './utils';

export const select = (state = initialSelectState, action: any) => {
  switch(action.type) {
    case types.SELECT_FETCH: {
      return {
        ...state,
        phrase: action.payload.phrase,
        selected: action.payload.selected,
        loading: true,
        error: '',
      };
    }
    case types.SELECT_SUCCESS: {
      return utils.select.success(state, action);
    }
    case types.SELECT_SUCCESS: {
      return utils.select.success(state, action);
    }
    case types.SELECT_CLEAR: {
      return {
        ...state,
        products: [],
        selected: [],
        loading: false,
        error: '',
      };
    }
    case types.SELECT_REQUIRED_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case types.CHANGE_SELECTED: {
      return {
        ...state,
        selected: uniq([...state.selected, ...action.payload.selected]),
      };
    }
    case types.SELECT_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch products',
      };
    }
    default: {
      return state;
    }
  }
};

import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { startSubmit, stopSubmit, reset } from 'redux-form'
import { post, patch } from 'common/utils/api'
import { preparedFormData } from 'common/utils/preparedData'

import * as types from 'reducers/admin/sliders/sliders.constants';
import { showSuccess, showError } from 'reducers/admin/snackbar/snackbar.actions'

function* objectResponse(response, message = null) {
  if (response.success) {
    yield put(stopSubmit('slider'))

    yield put({type: types.FORM_SUCCESS, payload: response.data})
    yield put(push(`/admin/sliders/${response.data.id}/edit`))

    if (message) { showSuccess(message) }
  } else {
    yield put({type: types.FORM_FAIL, payload: { error: response.error }})
    if (response.data.messages) {
      yield put(stopSubmit('slider', response.data.messages))
    }
  }
}

function* fetchObject(action: any) {
  try {
    const response = yield call(post, 'admin/sliders/get_info', action.payload)
    yield call(objectResponse, response)
  } catch (error) {
    yield put({type: types.FORM_FAIL, error})
    showError(error)
  }
}

function* updateObject(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('slider'))
    const response = yield call(patch, 'admin/sliders', formData)

    yield call(objectResponse, response, "Слайдер був оновлений")
  } catch (error) {
    yield put({type: types.FORM_FAIL, error})
    showError(error)
  }
}

function* createObject(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('slider'))
    const response = yield call(post, 'admin/sliders', formData)

    yield call(objectResponse, response, "Слайдер був створений")
  } catch (error) {
    yield put({type: types.FORM_FAIL, error})
    showError(error)
  }
}

export function* formFetchFlow() {
  yield takeLatest(types.FORM_FETCH, fetchObject)
}

export function* formCreateFlow() {
  yield takeLatest(types.FORM_CREATE, createObject)
}

export function* formUpdateFlow() {
  yield takeLatest(types.FORM_UPDATE, updateObject)
}

import React, { FC } from 'react';
import cn from 'classnames';

import { Collapse, RangeSlider } from 'common/ui';
import { filtersActions } from 'reducers/application';

import * as hooks from './hooks';
import { IPrices } from './Prices.interface';
import theme from './Prices.module.scss';

const Prices: FC<IPrices> = ({
  className,
}) => {
  const { min, max } = hooks.usePrices();

  return (
    <div className={ cn({
        },
        [theme.root],
        className,
      )}
    >
      <Collapse 
        title="Ціною"
      >
        <RangeSlider 
          min={ min }
          max={ max }
          onChange={ filtersActions.changePrices }
        />
      </Collapse>
    </div>
  );
}

export default Prices;

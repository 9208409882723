import React, { FC } from 'react';
import cn from 'classnames';

import { IFileInput } from './FileInput.interface';
import { Icon } from 'common/ui';

import * as utils from './utils';
import theme from './FileInput.module.scss';

const FileInput: FC<IFileInput> = ({
  className,
  id,
  input,
  label,
  meta: { touched, error },
  onChange,
  ...props
}) => {

  return (
    <div className={ cn({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      <label className={ cn(theme.label, { 
        [theme.error]: touched && error,
        [theme.loaded]: input.value,
        })}  htmlFor={ id }>
        <Icon className={ theme.icon } name="save-alt-outlined" />
        <span>{ input.value && input.value.name || label }</span>
      </label>
      <input 
        { ...props }
        className={ theme.input }
        id={ id }
        type="file"
        placeholder={ label }
        onChange={ utils.change.file(input, onChange) }
      />

      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
  )
};

export default FileInput;

import React, { FC } from 'react';
import classNames from 'classnames';

import { TextFieldProps } from './TextField.interface';
import { Label } from '../';

import theme from './TextField.module.scss';

const TextField: FC<TextFieldProps> = ({
  className,
  id,
  input,
  type = 'text',
  label,
  meta: { touched, error },
}) => (
  <div className={ classNames({
    [theme.error]: touched && error,
  }, theme.root, className) }
  >
    <Label value={ input.value } label={ label } htmlFor={ id } />
    <div className={ theme.inputField }>
      <textarea 
        { ...input }
        placeholder={ label }
        className={ theme.area }
        type={type} 
        id={ id } 
        rows={ 5 } 
      />
    </div>

    { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
  </div>
);

export default TextField;

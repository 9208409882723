import { lazy } from 'react';


export const LoginPage = lazy(() => import('./LoginPage/LoginPage'));
export const NotFoundPage = lazy(() => import('./NotFoundPage/NotFoundPage'));
export const DashboardPage = lazy(() => import('./DashboardPage/DashboardPage'));
export const SlidersTable = lazy(() => import('./SlidersPages/SlidersTable'));
export const SliderForm = lazy(() => import('./SlidersPages/SliderForm'));
export const UsersTable = lazy(() => import('./UsersPage/UsersTable'));
export const UserForm = lazy(() => import('./UsersPage/UserForm'));
export const OutlaysTable = lazy(() => import('./OutlaysPage/OutlaysTable'));
export const OutlayForm = lazy(() => import('./OutlaysPage/OutlayForm'));
export const ColorsTable = lazy(() => import('./ColorsPage/ColorsTable'));
export const ColorForm = lazy(() => import('./ColorsPage/ColorForm'));
export const TagsTable = lazy(() => import('./TagsPages/TagsTable'));
export const TagForm = lazy(() => import('./TagsPages/TagFormPage'));
export const ArticlesTable = lazy(() => import('./ArticlesPages/ArticlesTable'));
export const ArticleForm = lazy(() => import('./ArticlesPages/ArticleForm'));
export const ProductsTable = lazy(() => import('./ProductsPage/ProductsTable'));
export const ProductForm = lazy(() => import('./ProductsPage/ProductForm'));
export const OrdersTable = lazy(() => import('./OrdersPage/OrdersTable'));
export const OrderForm = lazy(() => import('./OrdersPage/OrderForm'));
export const PurchasesTable = lazy(() => import('./PurchasesPage/PurchasesTable'));
export const PurchaseForm = lazy(() => import('./PurchasesPage/PurchaseForm'));
export const PromocodesTable = lazy(() => import('./PromocodesPages/PromocodesTable'));
export const PromocodeForm = lazy(() => import('./PromocodesPages/PromocodeForm'));

import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ReactCrop from 'react-image-crop';

import { Icon } from 'common/ui';

import * as hooks from './hooks';
import theme from './PictureDialog.module.scss';

import 'react-image-crop/dist/ReactCrop.css';

export default function PictureDialog({
  openDialog,
  dialogClose,
  onImageLoaded,
  onComplete,
  onChange,
  src,
  crop,
  extraCrop
}) {

  const { states } = hooks.usePictureDialog(src);

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={ dialogClose }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className={ theme.picture }>
            <div style={{ 
              }} >
              {src && (
                <ReactCrop
                  { ...extraCrop }
                  src={src}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={onImageLoaded}
                  onComplete={onComplete}
                  onChange={onChange}
                />
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

import React, { FC } from 'react';
import cn from 'classnames';

import { ILabel } from './Label.interface';
import SelectAsyncLayout from './Label.layout'
import theme from './Label.module.scss';


const Label: FC<ILabel> = ({
  className,
  label,
  value,
  htmlFor,
}) => {
  const active = (value || [0, ''].includes(value)) && label;

  return (
    <label className={ cn(theme.root, {
        [theme.active]: active,
      }, className) }
      htmlFor={ htmlFor }
    >
      { label }
    </label>
  )};

export default Label;

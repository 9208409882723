export const PRODUCTS_TABLE_FETCH   = 'PRODUCTS/TABLE/FETCH';
export const PRODUCTS_TABLE_SUCCESS = 'PRODUCTS/TABLE/SUCCESS';
export const PRODUCTS_TABLE_FAIL    = 'PRODUCTS/TABLE/FAIL';
export const PRODUCTS_TABLE_VALUES  = 'PRODUCTS/TABLE/VALUES';
export const PRODUCTS_TABLE_SEARCH  = 'PRODUCTS/TABLE/SEARCH';

export const PRODUCT_FORM_FETCH   = 'PRODUCTS/FORM/FETCH';
export const PRODUCT_FORM_SUCCESS = 'PRODUCTS/FORM/SUCCESS';
export const PRODUCT_FORM_FAIL    = 'PRODUCTS/FORM/FAIL';
export const PRODUCT_FORM_UPDATE  = 'PRODUCTS/FORM/UPDATE';
export const PRODUCT_FORM_CREATE  = 'PRODUCTS/FORM/CREATE';

export const SELECT_FETCH            = 'PRODUCTS/SELECT/FETCH';
export const SELECT_SUCCESS          = 'PRODUCTS/SELECT/SUCCESS';
export const SELECT_FAIL             = 'PRODUCTS/SELECT/FAIL';
export const SELECT_REQUIRED_FETCH   = 'PRODUCTS/SELECT/REQUIRED_FETCH';
export const SELECT_REQUIRED_SUCESS  = 'PRODUCTS/SELECT/REQUIRED_SUCESS';
export const SELECT_CLEAR            = 'PRODUCTS/SELECT/CLEAR';
export const CHANGE_SELECTED         = 'PRODUCTS/SELECT/CHANGE_SELECTED';

import React, { FC, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { metaTagsActions } from 'reducers/application';

import * as hooks from './';
import {
  fetchArticle
} from 'reducers/application/articles/articles.actions';

const useShow = (id: string | number) => {
  const { article } = useSelector((state: any) => state.articles, shallowEqual);

  useEffect(() => {
    fetchArticle({ id });
    metaTagsActions.change({
      title: `T.The.Little - ${article.name}`,
      image: article.photo,
      keywords: (article.tags || []).map((tag: any) => tag.label),
    });
  }, [id, article.name]);

  return {
    article,
  }
}

export default useShow;

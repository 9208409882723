import { useState } from 'react';

const useMobileMenu = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return {
    openMenu,
    setOpenMenu,
  }
}

export default useMobileMenu;

import React, { FC } from 'react';
import cn from 'classnames';

import { filtersActions } from 'reducers/application';
import { Icon, GreyButton } from 'common/ui';
import { Drawer } from 'components/application/layouts';

import { IMobileFilters } from './MobileFilters.interface';
import theme from './MobileFilters.module.scss';

const MobileFilters: FC<IMobileFilters> = ({
  className,
  states,
  filterText,
  type,
  children,
}) => {
  return (
    <Drawer
      isOpened={ states.menuActive }
      onBackdropClick={ () => states.setMenuActive(false) }
      className={ theme.drawer }
    >
      <div 
        className={ theme.header }
        onClick={ () => states.setMenuActive(false) }
      >
        { filterText }
        <Icon className={theme.icon} name="close-outlined" />
      </div>
      <div className={cn(theme.root, className)}>

        <div className={ theme.body }>
          { children }
        </div>

        <GreyButton
          className={ theme.cleanBtn }
          label="Очистити"
          disable={ !states.filtersSize }
          onClick={ () => filtersActions.cleanFilters(type) }
        />
      </div>
  </Drawer>
 );
}

export default MobileFilters;

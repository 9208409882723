import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { post } from 'common/utils/api'
import { store } from 'store/indexAdmin';

import * as types from 'reducers/admin/articles/articles.constants';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* fetchData(action: any) {
  try {
      const response = yield call(post, 'articles/all', action.payload)
      yield put({type: types.TABLE_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: types.TABLE_FAIL, error})
   }
}

function* loadinData(payload: any = {}) {
  const response = yield call(post, 'articles/all', { ...payload })

  if (response.failure) {
    yield put({type: types.TABLE_FAIL})
    return false
  }
  yield put({type: types.TABLE_SUCCESS, payload: response.data})
}

function* fetchTable(action: any) {
  const { getState } = store;
  const { articlesData: { table: { values } } } = getState()
  yield put({type: types.TABLE_FETCH, payload: values})
}

function* searchTable(action: any) {
  yield call(delay, 500)
  yield call(fetchTable)
}

export function* fetchFlow() {
  yield takeLatest(types.TABLE_FETCH, fetchData)
}

export function* tableFetchFlow() {
  yield takeLatest(types.TABLE_VALUES, fetchTable)
}

export function* tableSearchFlow() {
  yield takeLatest(types.TABLE_SEARCH, searchTable)
}


import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import Navigation from '../Navigation/Navigation.layout';

import { DrawerProps } from './Drawer.interface';
import theme from './Drawer.module.scss';

const Drawer: FC<DrawerProps> = ({ isOpened, onBackdropClick }) => {
  return createPortal(
    <div className={ classNames({
      [theme.isOpened]: isOpened,
    }, theme.root) }>
      <div
        className={ theme.backdrop }
        onClick={ () => onBackdropClick(false) }
      ></div>

      <div className={ theme.paper }>
        <Navigation/>
      </div>
    </div>,
    document.body
  )
};

export default Drawer;

import React, { FC } from 'react';

import { PageHeader } from 'components/application/sections';
import { GreyButton } from 'common/ui';

import { IShop } from './Shop.interface';
import * as hooks from './hooks';
import * as utils from './utils';

import ShopPhoto from 'images/shop.jpeg';
import theme from './Shop.module.scss';
import { Products, Filters } from './components';

const Shop: FC<IShop> = ({
  
}) => {
  const { products, leftMore, loadMore, sorting, search, filters, states, loading } = hooks.useShop();

  return (
    <div className={ theme.root }>
      <PageHeader
        picture={ ShopPhoto }
        label="Товари"
      />
      <section className={ theme.productsSection }>
        <Filters fixFilters={ states.fixFilters } />
        <Products
          fixFilters={ states.fixFilters }
          products={ utils.preparedData.products(products) }
          leftMore={ leftMore }
          loadMore={ loadMore }
          sorting={ sorting }
          search={ search }
          loading={ loading }
        />
      </section>
    </div>
  );
}

export default Shop;

export const PROMOCODES_TABLE_FETCH   = 'PROMOCODES/TABLE/FETCH';
export const PROMOCODES_TABLE_SUCCESS = 'PROMOCODES/TABLE/SUCCESS';
export const PROMOCODES_TABLE_FAIL    = 'PROMOCODES/TABLE/FAIL';
export const PROMOCODES_TABLE_VALUES  = 'PROMOCODES/TABLE/VALUES';
export const PROMOCODES_TABLE_SEARCH  = 'PROMOCODES/TABLE/SEARCH';

export const PROMOCODE_FORM_FETCH   = 'PROMOCODES/FORM/FETCH';
export const PROMOCODE_FORM_SUCCESS = 'PROMOCODES/FORM/SUCCESS';
export const PROMOCODE_FORM_FAIL    = 'PROMOCODES/FORM/FAIL';
export const PROMOCODE_FORM_UPDATE  = 'PROMOCODES/FORM/UPDATE';
export const PROMOCODE_FORM_CREATE  = 'PROMOCODES/FORM/CREATE';

export const PROMOCODE_SELECT_FETCH            = 'PROMOCODES/SELECT/FETCH';
export const PROMOCODE_SELECT_SUCCESS          = 'PROMOCODES/SELECT/SUCCESS';
export const PROMOCODE_SELECT_FAIL             = 'PROMOCODES/SELECT/FAIL';
export const PROMOCODE_SELECT_REQUIRED_FETCH   = 'PROMOCODES/SELECT/REQUIRED_FETCH';
export const PROMOCODE_SELECT_REQUIRED_SUCESS  = 'PROMOCODES/SELECT/REQUIRED_SUCESS';
export const PROMOCODE_SELECT_CLEAR            = 'PROMOCODES/SELECT/CLEAR';

import React, { FC } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { DateTimeProps } from './DateTime.interface';
import { materialDate, materialDateTime } from 'common/utils/dateTime';

import theme from './DateTime.module.scss';

const formData = (type, value) => {
  switch (type) {
    case 'date': 
      return materialDate(value);
    default:
      return materialDateTime(value)
  }
}

const DateTime: FC<DateTimeProps> = ({
  className,
  id,
  input,
  label,
  type = "datetime-local",
  meta: { touched, error },
  elementChange,
  ...props
}) => {
  return (
    <div className={ classNames({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      { label && <label className={ theme.label } htmlFor={ id }>
          { label }
        </label>
      }

      <div className={ theme.dateTiemField }>
        <TextField
          { ...props }
          id={ id }
          type={ type }
          value={ input.value && formData(type, input.value) }
          className={ classNames({
              [theme.errorDateTime]: touched && error,
            })}
          onChange={ (event) => { 
            input.onChange(event)
            if (elementChange) { elementChange(event) }
          }}
        />
      </div>

      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
  )
};

export default DateTime;

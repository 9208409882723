import React, { FC } from 'react';
import classNames from 'classnames';
import AsyncSelect from 'react-select/async';

import * as hooks from './hooks';
import { ISelectAsync } from './SelectAsync.interface';
import SelectAsyncLayout from './SelectAsync.layout'
import theme from './SelectAsync.module.scss';
import { Label } from '../';

const SelectAsync: FC<ISelectAsync> = ({
  options,
  input,
  className,
  id,
  label,
  meta: { touched, error },
  loadOptions,
  ...props
}) => {
  const { 
    value, onSelectChange
  } = hooks.useSelectAsync(options, input)
  
  return (
    <div className={ classNames({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      <Label value={ input.value } label={ label } htmlFor={ id } />
      <AsyncSelect
        { ...props }
        className={ theme.inputField }
        cacheOptions
        placeholder={ label || "Обрати..." }
        onChange={onSelectChange}
        value={value}
        defaultOptions={options}
        loadOptions={loadOptions}
        noOptionsMessage={ () => 'Нема даних' }
        isOptionDisabled={(option: any) => option.disabled}
        loadingMessage={ () => 'Завантаження...' }
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#d9ead5',
            primary: '#4A4A4A',
          },
        })}
      />

      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
   )};

export default SelectAsync;

import { preparedBackForm } from 'common/utils/preparedData';
import { store } from 'store/indexApplication';

import * as types from './question.constants';

export const formCreate = (values: any) => {
  store.dispatch({
    type: types.QUESTION_CREATE,
    payload: preparedBackForm({ ...values })
  });
}

export const message = (showMessage: boolean = false) => {
  store.dispatch({
    type: types.SHOW_MESSAGE,
    payload: { showMessage }
  });
}

import { uniq } from 'lodash'

import { initialSelectState } from './tags.model';
import * as types from './tags.constants';

export const select = (state = initialSelectState, action: any) => {
  switch(action.type) {
    case types.TAG_SELECT_FETCH: {
      return {
        ...state,
        phrase: action.payload.phrase,
        selected: action.payload.selected,
        loading: true,
        error: '',
      };
    }
    case types.TAG_SELECT_SUCCESS: {
      return {
        ...state,
        tags: action.payload,
        loading: false,
        error: '',
      };
    }
    case types.TAG_SELECT_CLEAR: {
      return {
        ...state,
        tags: [],
        selected: [],
        loading: false,
        error: '',
      };
    }
    case types.TAG_SELECT_REQUIRED_FETCH: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case types.TAG_SELECT_REQUIRED_SUCESS: {
      return {
        ...state,
        selected: uniq([...state.selected, ...action.payload]),
        loading: true,
        error: '',
      };
    }
    case types.TAG_SELECT_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch tags',
      };
    }
    default: {
      return state;
    }
  }
};

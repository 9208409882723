export const defaultTableState = {
  loading: false,
  error: '',
  totalCount: 0,
  extraInfo: [],
  checkInitial: false,
  values: {
    pageSize: 10,
    page: 0,
    query: '',
    sorted: {},
    range: {},
    searchFocus: false
  }
}

export const defaultFormState = {
  loading: false,
  error: '',
  initial: {},
  checkInitial: false
}

export const defaultSelectState = {
  selected: [],
  phrase: '',
  loading: false,
  error: ''
}

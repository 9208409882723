import React, { FC } from 'react';
import cn from 'classnames';

import { priceView } from 'common/utils/humanView';
import { Basket } from 'components/application/layouts';
import { Icon } from 'common/ui';

import * as utils from './utils';
import { IPrice } from './Price.interface';
import theme from './Price.module.scss';

const Price: FC<IPrice> = ({ 
    className, 
    product,
    colorId,
}) => {
  const number = product.colors.find((color: any) => color)
  return (
    <div
      className={ cn([theme.root], className) }
    >
      <h3 className={ cn(theme.price, {
        [theme.withSale]: product.showSalePrice,
        [theme.withoutSale]: !product.showSalePrice,

      }) }>
        <div className={ theme.mainPrice }>
          { priceView(product.price) }
        </div>
        {
          product.showSalePrice &&
          <div className={ theme.salePrice }>
            { priceView(product.salePrice) }
          </div>
        }
      </h3>
      <div className={ theme.order }
        onClick={ utils.actions.buy({
          colorId, 
          number: utils.find.number(product.colors, colorId),          
          productId: product.id 
        }) }
      >
        <Basket className={ theme.icon } />
        <span className={ theme.text }>Купити</span>
      </div>
    </div>
)};

export default Price;
// <div className={ theme.right }>
//   <div className={ theme.add }>
//     <Icon className={ theme.icon } name="favorite-border" />
//     В закладки
//   </div>
//   <div className={ theme.compare }>
//   <Icon className={ theme.icon } name="compare" />
//     Порівняти
//   </div>
// </div>

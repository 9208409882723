import { call, put, takeLatest } from 'redux-saga/effects'
import { post } from 'common/utils/api'

import {
  COLOR_SELECT_FETCH,
  COLOR_SELECT_SUCCESS,
  COLOR_SELECT_FAIL,
  COLOR_SELECT_REQUIRED_FETCH,
  COLOR_SELECT_REQUIRED_SUCESS,
} from 'reducers/admin/colors/colors.constants';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

const preparedValues = (colors) =>
  colors.map((color) => {
    return {
      value: color.id,
      label: color.name
    }
  })

function* fetchColors(action: any) {
  try {
      const response = yield call(post, 'colors/get_select', { ...action.payload })
      yield put({type: COLOR_SELECT_SUCCESS, payload: preparedValues(response.data)})
   } catch (error) {
      yield put({type: COLOR_SELECT_FAIL, error})
   }
}

function* selectedColors(action: any) {
  try {
      const response = yield call(post, 'colors/get_selected', { ...action.payload })
      yield put({type: COLOR_SELECT_REQUIRED_SUCESS, payload: preparedValues(response.data)})
   } catch (error) {
      yield put({type: COLOR_SELECT_FAIL, error})
   }
}

export function* colorsSelectFetchFlow() {
  yield takeLatest(COLOR_SELECT_FETCH, fetchColors)
}

export function* colorsSelectedFetchFlow() {
  yield takeLatest(COLOR_SELECT_REQUIRED_FETCH, selectedColors)
}

import React, { FC } from 'react';
import cn from 'classnames';

import { Collapse } from 'common/ui';
import { filtersActions } from 'reducers/application';

import * as hooks from './hooks';
import { ITags } from './Tags.interface';
import theme from './Tags.module.scss';

const Tags: FC<ITags> = ({
  className,
}) => {

  const { isActive, tags } = hooks.useTags();

  return (
    <div className={ cn({
        },
        [theme.root],
        className,
      )}
    >
      <Collapse 
        title="Тегами"
      >
        <ul>
          <li className={cn({
            [theme.optionActive]: isActive(0)
            }, theme.option)}
            onClick={ ()=> filtersActions.changeTag(0, 'article') }
          >
            Усі
          </li>
          { tags.map((tag: any, index: number) => (
            <li className={cn({
              [theme.optionActive]: isActive(tag.id)
              }, theme.option)}
              key={ index }
              onClick={ ()=> filtersActions.changeTag(tag.id, 'article') }
            >
              { tag.label }
            </li>
           )) }
        </ul>
      </Collapse>
    </div>
  );
}

export default Tags;

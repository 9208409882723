import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { startSubmit, stopSubmit, reset } from 'redux-form'
import { post, patch } from 'common/utils/api'
import { preparedFormData } from 'common/utils/preparedData'

import {
  COLOR_FORM_FETCH,
  COLOR_FORM_SUCCESS,
  COLOR_FORM_FAIL,
  COLOR_FORM_UPDATE,
  COLOR_FORM_CREATE,
  COLOR_SELECT_CLEAR,
} from 'reducers/admin/colors/colors.constants';
import { showSuccess, showError } from 'reducers/admin/snackbar/snackbar.actions'

function* colorResponse(response, message = null) {
  if (response.success) {
    yield put(stopSubmit('color'))

    yield put({type: COLOR_FORM_SUCCESS, payload: response.data})
    yield put({type: COLOR_SELECT_CLEAR})
    yield put(push(`/admin/colors/${response.data.id}/edit`))

    if (message) { showSuccess(message) }
  } else {
    yield put({type: COLOR_FORM_FAIL, payload: { error: response.error }})
    if (response.data.messages) {
      yield put(stopSubmit('color', response.data.messages))
    }
  }
}

function* fetchColor(action: any) {
  try {
    const response = yield call(post, 'colors/get_info', action.payload)
    yield call(colorResponse, response)
  } catch (error) {
    yield put({type: COLOR_FORM_FAIL, error})
    showError(error)
  }
}

function* updateColor(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('color'))
    const response = yield call(patch, 'colors', formData)

    yield call(colorResponse, response, "Колір був оновлений")
  } catch (error) {
    yield put({type: COLOR_FORM_FAIL, error})
    showError(error)
  }
}

function* createColor(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('color'))
    const response = yield call(post, 'colors', formData)

    yield call(colorResponse, response, "Колір був створений")
  } catch (error) {
    yield put({type: COLOR_FORM_FAIL, error})
    showError(error)
  }
}

export function* colorFormFetchFlow() {
  yield takeLatest(COLOR_FORM_FETCH, fetchColor)
}

export function* colorFormCreateFlow() {
  yield takeLatest(COLOR_FORM_CREATE, createColor)
}

export function* colorFormUpdateFlow() {
  yield takeLatest(COLOR_FORM_UPDATE, updateColor)
}

import { FormErrors } from 'redux-form';

import { errorHash } from 'common/utils/validation';
import { errors } from 'common/utils/localized';

export const validate = (values): FormErrors => {
  const constraints = {
    email: {
      presence: { 
        allowEmpty: false,
        message: errors.empty,
      },
    },
    question: {
      presence: {
        allowEmpty: false,
        message: errors.empty,
      },
    },
  }

  return errorHash(values, constraints, { fullMessages: false })
}

import React, { FC } from 'react';
import cn from 'classnames';

import {  } from 'common/ui/FormElements'
import { IOther } from './Other.interface';
import theme from './Other.module.scss';

const Other: FC<IOther> = ({ 
  className,
  formName,
}) =>  {
  return null;
}

export default Other;

import React, { FC } from 'react';
import classNames from 'classnames';
import Select from 'react-select';

import theme from './MultiSelect.module.scss';
import { IMultiSelect } from './MultiSelect.interface';

const MultiSelect: FC<IMultiSelect> = ({
  options = [],
  input,
  className,
  id,
  label,
  meta: { touched, error },
  elementChange = null,
  closeMenuOnSelect = true,
  ...props
}) => {
  const values = options.filter((option) => (input.value || []).includes(option.value));

  return (
    <div className={ classNames({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      { label && <label className={ theme.label } htmlFor={ id }>
          { label }
        </label>
      }

      <Select
        { ...props }
        closeMenuOnSelect={closeMenuOnSelect}
        value={values}
        options={options}
        className={ theme.inputField }
        isMulti
        styles={{
          menu: style => {
            return { ...style, zIndex: '100' }
          }
        }}
        onChange={(event) => {
          const values = event.map((hash: any) => hash.value)
          input.onChange(values)
          if(elementChange) { elementChange(values) }
        }}
      />
      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
)};
  
export default MultiSelect;

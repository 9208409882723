import {
  LOGOUT,
  LOGIN_REQUEST
} from './authorization.constants';
import { store } from 'store/indexAdmin';

const { dispatch } = store;

export const logout = () => {
  dispatch({
    type: LOGOUT
  });
};

export const login = (values: any) => {
  dispatch({
    type: LOGIN_REQUEST,
    payload: values
  });
};

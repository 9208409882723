import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { join } from 'lodash';

import * as hooks from './';

const useCategories = () => {

  const { 
    categories, filters,
  } = useSelector((state: any) => state.filters, shallowEqual);

  const isActive = (categoryId: number) => {
    if (categoryId == 0 && categories.length == 0) { return true }

    return categories.includes(categoryId);
  }

  useEffect(() => {
  }, [join(categories, '-')]);

  return {
    isActive,
    categories: filters.categories,
  }
}

export default useCategories;

import React, { useState } from 'react';

const useStates = () => {
  const [itemIndex, setItemIndex] = useState(0);

  return {
    itemIndex,
    setItemIndex,
  }
}

export default useStates;

import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { basketActions, orderActions } from 'reducers/application';

const useBasket = () => {
  const { products, syncedWithLStorage } = useSelector((state: any) => state.basket, shallowEqual);
  const size = products.reduce((sum: number, product: any) => {
    return sum + product.count
  }, 0);

  useEffect(() => {
    if (!syncedWithLStorage) { 
      basketActions.syncLStorage();
      orderActions.main.syncLStorage();
    }
  }, [syncedWithLStorage])

  return {
    size,
  }
}

export default useBasket;

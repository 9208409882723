import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { join } from 'lodash';

import * as hooks from './';

const useTags = () => {

  const { 
    tags, filters,
  } = useSelector((state: any) => state.filters, shallowEqual);

  const isActive = (tagId: number) => {
    if (tagId == 0 && tags.length == 0) { return true }

    return tags.includes(tagId);
  }

  useEffect(() => {
  }, [join(tags, '-')]);

  return {
    isActive,
    tags: filters.tags,
  }
}

export default useTags;

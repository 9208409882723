import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'common/utils/api';

import * as types from 'reducers/application/promocodes/constants'

function* hundlePromocodes(action: any) {
  try {
      const response = yield call(get, 'clients/promocodes/load', action.payload)
      if (response.success && response.data.success) {
        yield put({type: types.FETCH_SUCCESS, payload: response.data.data})
      } else {
        yield put({type: types.FETCH_ERROR, payload: response.data})
      }
   } catch (error) {
      yield put({type: types.FETCH_ERROR, payload: error})
   }
}

export function* promocodes() {
  yield takeLatest(types.FETCH_DATA, hundlePromocodes);
}


import React, { FC } from 'react';
import cn from 'classnames';

import { Collapse, Colors } from 'common/ui';
import { filtersActions } from 'reducers/application';

import * as hooks from './hooks';
import { IColors } from './Colors.interface';
import theme from './Colors.module.scss';

const ColorsContainer: FC<IColors> = ({
  className,
}) => {
  const { colors, selected } = hooks.useColors();

  return (
    <div className={ cn({
        },
        [theme.root],
        className,
      )}
    >
      <Collapse 
        title="Кольором"
      >
        <Colors
          colors={ colors }
          selected={ selected }
          onChange={ filtersActions.changeColors }
        />
      </Collapse>
    </div>
  );
}

export default ColorsContainer;

import React, { FC } from 'react';

import { NavLink } from './components';
import theme from './Navigation.module.scss';

const Navigation: FC = () => {
  return (
    <nav className={ theme.root }>
      <NavLink
        path='/admin/users'
        name='Користувачі'
        icon='people-outline'
      />
      <NavLink
        path='/admin/orders'
        name='Замовлення'
        icon='shopping-cart'
      />
      <NavLink
        path='/admin/products'
        name='Товари'
        icon='list-alt'
      />
      <NavLink
        path='/admin/tags'
        name='Теги'
        icon='more-outlined'
      />
      <NavLink
        path='/admin/articles'
        name='Статті'
        icon='library-books-outlined'
      />
      <NavLink
        path='/admin/colors'
        name='Кольори'
        icon='palette'
      />
      <NavLink
        path='/admin/promocodes'
        name='Промокоди'
        icon='discount'
      />
      <NavLink
        path='/admin/sliders'
        name='Слайди'
        icon='swipe-outlined'
      />
      <NavLink
        path='/admin/outlays'
        name='Витрати'
        icon='attach-money'
      />
      <NavLink
        path='/admin/purchases'
        name='Калькулятор'
        icon='keyboard-outlined'
      />
    </nav>
  )
};

export default Navigation;

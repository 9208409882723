import React, { FC, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { isEmpty } from 'lodash';

import * as hooks from './';
import { articlesActions, filtersActions, metaTagsActions } from 'reducers/application';
import Photo from 'images/article.jpeg';

const useMain = () => {
  const states = hooks.setStates();
  const selectors = hooks.useSelectors();

  const loadMore = () => articlesActions.fetchArticles();
  const listenScrollEvent = (e) => {
    states.setFixFilters(window.scrollY > 495);
  }

  useEffect(() => {
    if (!selectors.articles.length && !selectors.loading && selectors.leftMore) { 
      articlesActions.fetchArticles(); 
    } 
    if (!selectors.loaded) { filtersActions.fetchFilters() }

    window.addEventListener('scroll', listenScrollEvent, true);
    metaTagsActions.change({
      title: 'T.The.Little - поради',
      image: Photo,
    });

    return () => {
      window.removeEventListener("scroll", listenScrollEvent, true);
    }
  }, [selectors.page, selectors.pathname]);

  return {
    loadMore,
    states,
    selectors,
  }
}

export default useMain;
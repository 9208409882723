import React, { FC } from 'react';
import classNames from 'classnames';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

import { RadioGroupProps } from './RadioGroup.interface';

import theme from './RadioGroup.module.scss';

const RadioGroupLayout: FC<RadioGroupProps> = ({
  className,
  id,
  input,
  values,
  label,
  meta: { touched, error },
  elementChange,
  ...props
}) => {
  return (
    <div className={ classNames({
      [theme.error]: touched && error,
    }, theme.root, className) }
    >
      { label && <label className={ theme.label } htmlFor={ id }>
          { label }
        </label>
      }

      <div className={ theme.radioGroupField }>
        <RadioGroup
          { ...props }
          aria-label={id} 
          value={input.value} 
          onChange={(event) => {
            input.onChange(event)
            if(elementChange) { elementChange(event) }
          }}
         >
          {
            values.map((params, index) => {
              return (
                <FormControlLabel
                  { ...params }
                  key={index}
                  className={ theme.label }
                  control={<Radio color="default" size="small" />} 
                />
              )
            })
          }
         </RadioGroup>
      </div>

      { touched && error && <div className={ theme.errorMessage }>{ error }</div> }
    </div>
  )
};

export default RadioGroupLayout;

import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { rootApplicationReducer } from 'reducers';
import rootSaga from 'sagas/indexApplication';

import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

export const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const storeFactory = composeEnhancers(
  applyMiddleware(routerMiddleware(history), sagaMiddleware),
  )(
  createStore,
);

export const store = storeFactory(rootApplicationReducer(history));

sagaMiddleware.run(rootSaga);

import { FC } from 'react';
import { min } from 'lodash';

import * as utils from './';

export interface IProduct {
  productId: number;
  colorId: number;
  count?: number;
  number: number;
}

export const add: FC<IProduct> = ({ productId, colorId, count, number }) => {
  let product = utils.localStorage.product({ colorId, productId });

  if (product.count) {
    product.count = min([number, product.count + (count || 1)])
  } else {
    product = {
      colorId,
      productId,
      count: min([number, (count || 1)]) ,
    }
  }

  utils.localStorage.set(product);

  return utils.localStorage.data();
}

export const remove: FC<IProduct> = ({ productId, colorId, count, number }) => {
  let product = utils.localStorage.product({ colorId, productId });

  if (product.count && count && product.count > count) {
    product.count = min([number, product.count - count])
  } else {
    product.count = 0
  }

  utils.localStorage.set(product);

  return utils.localStorage.data();
}
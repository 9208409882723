export const onComplete = (states) => (crop) => {
   makeClientCrop(crop, states);
};

async function makeClientCrop(crop, states) {
  if (states.imageRef && crop.width && crop.height) {

    const croppedImageUrl = await getCroppedImg(
      states,
      crop,
    );
    states.setCroppedImageUrl(croppedImageUrl)
  }
}

const getCroppedImg = (states, crop) => {
  const canvas = document.createElement('canvas');
  const scaleX = states.imageRef.naturalWidth / states.imageRef.width;
  const scaleY = states.imageRef.naturalHeight / states.imageRef.height;
  canvas.width = Math.ceil(crop.width*scaleX);
  canvas.height = Math.ceil(crop.height*scaleY);
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    states.imageRef,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width*scaleX,
    crop.height*scaleY,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        return;
      }
      blob.name = states.imageName;
      const fileUrl = window.URL.createObjectURL(blob);
      states.setBlob(blob);
      resolve(fileUrl);
    }, states.imageFormat);
  });
}
import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { post, get } from 'common/utils/api';

import * as types from 'reducers/application/products/products.constants';

function* hundleProduct(action: any) {
  try {
      const response = yield call(get, 'clients/products/show', action.payload)
      if (response.success) {
        yield put({ type: types.PRODUCT_FETCH_SUCCESS, payload: response.data })
      } else {
        yield put({ type: types.PRODUCT_FETCH_ERROR, errors: response.errors })
      }
   } catch (error) {
      yield put({ type: types.PRODUCT_FETCH_ERROR, error })
   }
}

export function* product() {
  yield takeLatest(types.PRODUCT_FETCH, hundleProduct);
}


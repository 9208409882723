const useSelectAsync = (options: Array<any> = [], input: any) => {
  const value = options.find((option) => option.value === input.value)
  const onSelectChange = (option)=> input.onChange(option.value)

  return {
    value,
    onSelectChange,
  }
}

export default useSelectAsync;

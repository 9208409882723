import React, { FC } from 'react';

import { ILogo } from './Logo.interface';

import theme from './Logo.module.scss';

const Logo: FC<ILogo> = () => {
  return (
    <div className={ theme.root }>
      <svg 
        version="1.1" 
        x="0px" y="0px"
        viewBox="0 0 2927 648.1" 
      >
      <path d="M0.3,324.3c0-41.3,0.1-82.7-0.1-124c0-7.9,2.4-13.9,8-19.5c55.5-55.3,110.9-110.7,166-166.3C184,4.6,194.8-0.1,208.8,0
        c77.3,0.3,154.7,0.3,232,0c12.6,0,22.5,4.3,31.2,13.1c22,22.3,44,44.6,66.2,66.7c31.6,31.6,63.1,63.2,95.1,94.4
        c10.2,10,14.7,21.1,14.6,35.6c-0.4,77.7-0.3,155.3-0.1,233c0,11.6-4,20.4-12,28.4c-54.4,54.1-108.7,108.1-162.7,162.6
        c-9.9,10-20.5,14.4-34.6,14.3c-79-0.5-158-0.4-237-0.2c-9,0-15.7-2.7-22.1-9.1C122.7,581.5,65.6,524.5,8.5,467.6
        c-5.8-5.8-8.5-12-8.5-20.3c0.3-41,0.1-82,0.1-123C0.2,324.3,0.2,324.3,0.3,324.3z M502.6,440.6c37.3,0,67.3-30.2,67.1-67.5
        c-0.2-36.7-30.5-67.4-66.5-67.4c-38.2,0-67.6,29.4-67.7,67.8C435.4,411,465,440.7,502.6,440.6z"/>
      <path d="M1262.5,283.2c20.7-0.2,39.3-0.4,57.9-0.7c4.4-0.1,6.8,2,7.7,6.2c1.6,8.1,4,16.2,4.7,24.4c1.7,20.5,2.6,41.2,3.8,61.7
        c0.2,4,0.5,7.9,0.7,11.8c6.3,3,12.3,1.9,18.1,0.8c9.1-1.8,18.1-4.4,27.3-5.8c33.5-5.2,60.2,5.8,79.2,34.3
        c10.8,16.2,16,34.2,17.4,53.5c1.4,18.2,3.2,36.5,4.4,54.7c0.4,7.2,0.3,14.7-0.9,21.8c-3.5,19.9-3,39.7-0.3,59.6
        c1.3,9.5,1.4,19.2,1.4,28.9c0,3.7-1.7,7.4-2.3,9.7c-20.7-0.8-39.9-1.5-60.4-2.2c1.3-11.3,2.4-21.4,3.8-31.5c5.3-38.5,6-77,1.2-115.5
        c-0.5-3.6-1.2-7.2-2.1-10.8c-6.5-27.6-27-41.1-55.1-36.2c-9.7,1.7-18.5,5.4-26.2,11.6c-6.2,4.9-10.8,10.5-12,18.8
        c-0.7,4.8-3.3,9.4-5,14.1c-5.3,15-4.5,30.1-1.2,45.3c6.2,28.8,8.2,58,7,87.4c-0.1,2.7-0.1,5.4-0.7,8c-1.3,6.1-4.2,8.9-10.4,8.6
        c-12.6-0.7-25.2-2-37.8-3.2c-4.4-0.4-6.4-3.1-6.3-7.5c0.1-3.3,0.4-6.7,0.3-10c-0.1-13.3-0.3-26.6-0.2-39.9c0-6.9,1-13.9,1-20.8
        c0.1-16.3,1.7-32.8-0.4-48.8c-5-38.4-8.5-76.8-6.2-115.5c0.4-7.3-0.1-14.7-0.1-22c0.1-12,0.3-23.9,0.4-35.9
        c0.2-13.8-2.4-27.1-5.9-40.4C1264.2,293.8,1263.7,289.6,1262.5,283.2z M1454.4,422.7c-4,3.1-6.8,5.3-9.7,7.4
        c-2.5,1.8-5.7,3.4-7.9,0.3c-2.4-3.4,0.6-5.2,3.4-6.8c3.1-1.8,6-3.8,8.9-5.7c-5.3-12.2-7.7-13.1-18-7.1c-2.9,1.7-5.5,3.8-8.5,5.3
        c-3.1,1.6-6.4,2.6-9.5,3.8c-3-5.1-0.5-7.5,2.4-9.2c2.5-1.5,5.6-2.1,8.3-3.3c3.1-1.4,6.2-3,9.1-4.5c-4.2-11.6-15.9-10-34-2.1
        c-3.8-8.3-9.1-12.2-17.6-10.9c-11.8,1.8-23.5,4.4-35.1,7.3c-7.8,1.9-12.3,7.4-12.9,16c2.7,1.9,5.2,3.7,8.9,6.2
        c-6.3,4.6-11.7,8.6-17.2,12.6c-2.7-7.5-4.9-13.5-7-19.7c-0.3-0.9-0.2-2.2,0.3-2.9c4.3-6.8,1.4-12.6-2.1-17.3
        c0.9-4.2,1.5-7.4,2.3-10.5c0.7-2.7,0.9-5.9,2.5-8c3.6-4.7,4-9.1,0.2-13.5c-3.8-4.5-3.2-8.5,0.9-12.2c4.1-3.7,3.6-7.5,2.1-12.5
        c-1.6-5.2-1.9-11.1-1.3-16.6c0.6-5.6-0.8-9-6.5-11.5c-4.9,3.4-10,6.9-15.2,10.5c-0.9-2.7-1.7-5-2.5-7.4c-3.6,0.4-6.6,0.7-9.5,1.2
        c-9.8,1.8-11.5,5.7-6.6,15.7c4-1.2,8-2.5,12-3.7c-1.9,3.2-4.4,5.4-6.8,7.6c-6.4,5.8-9.5,12.5-7,21.4c1,3.7,1,8.1,0.2,11.8
        c-1.5,6.8,0,11.3,6.5,14.8c6.2,3.3,6.5,6.3,1.5,10.6c-7.6,6.5-9.9,14.7-9.4,24.1c0.6,10.6,1,21.3,1.9,31.9c0.3,3.5,1.7,6.8,2.5,10
        c14.5-1.1,14.5-1.1,16.7,5.2c-1.6,0.4-3.3,0.7-4.8,1.2c-9.2,3-10.9,5.2-10.9,15.2c0,6.7,0.4,13.3,0.2,20c-0.2,6,1.2,11,6.8,13.9
        c-3.8,7.3-4.2,13.9,0.1,20.5c1,1.5,1.6,4.2,0.9,5.5c-4.5,8.2-4.1,16.6-2.1,25.2c0.4,1.8,0.3,4.1-0.4,5.9
        c-6.9,18.2-3.5,36.9-3.1,55.4c0.1,3.4,1.9,6.8,2.8,9.9c10.5,2,20,3.5,29.2-1.9c0-3.8-0.1-7.4,0-11c0.1-3.9,0.4-7.9,0.7-11.5
        c-4.2-3.8-7.8-2.6-11,0.5c-3.1,3-5.7,6.5-8.9,9.5c-1.4,1.4-3.5,2.1-5.3,3.2c1.3-7.1,5.6-11.7,10.7-15.2c16.1-11.1,13.5-22,7.4-39.3
        c-3.3,0.5-6.6,1.1-9.9,1.6c0.6-2.4,1.7-3.8,3-4.4c7.7-3.4,9.6-9.1,8.3-17.1c-1.8-10.8-3-21.8-4.3-32.7c-1.5-11.6-1.4-11.6-12.5-8.3
        c-0.2,0.1-0.8-0.8-1.8-1.9c2.8-2.3,5.2-5.1,8.3-6.7c6.1-3.1,8.5-7.5,8.4-14.3c-0.1-8,0.9-16,8.9-20.9c6-3.7,8.7-8.7,4.5-15.3
        c12.7-6.7,24.8-13,36.8-19.3c7.1,7.6,9.4,8.4,20,6.4c5.5-1,10.9-2.3,16.3-3.5c2.4,3.8,4,7.1,6.3,9.9c2.3,2.8,4.8,5.7,7.9,7.3
        c11,5.5,17.4,14.6,21.9,25.6c1.3,3.2,3.3,6.2,4.3,8c-0.9,7.6-2.3,14.1-2.5,20.7c-0.3,15.3-0.1,30.6-0.1,45.9
        c0,5.3-1.1,10.9,0.2,15.9c1.3,4.8,5.1,8.9,7.7,13.3c-11.4,12.4-14.3,30-4.4,37c-1.8,6.2-9.8,12.2-0.7,19.6
        c5.3-2.2,10.7-4.5,16.6-6.9c0,2.3,0,3.8,0,5.8c5.4,1.4,10.8,2.8,16.1,4.2c4.1-8.6,1.2-14.1-6.5-18c4-7.8,1-15.2-0.2-22.6
        c-0.8-4.7-4-5.8-8.2-4.8c-3.3,0.8-6.5,2.6-12.1-0.3c7.8-3.8,13.9-6.7,18.7-9c1.9-5.9,3.8-10.4,4.9-15.2c0.8-3.1,1.8-7,0.6-9.7
        c-4.1-9.4-2-17.5,3.9-24.8c-0.8-2.4-1.3-4.3-2.1-6.1c-2.6-6.2-5-12.1-2.2-19.2c1-2.6-0.2-6.7-1.6-9.5c-2.8-5.3-2.4-10,0.2-15.1
        c2.8-5.4,4.6-11.2,3-17.4c-2.8-10.6-5.6-21.2-9.1-31.6C1460.6,430.4,1457.2,427,1454.4,422.7z M1281.6,306.8
        c7.4-4.3,15.2-6.2,17.7-15.2c-6.2-3.1-12.2-2.5-18-1.8c-6.3,0.8-8.4,5-5.3,10.7C1277.2,302.6,1279.4,304.3,1281.6,306.8z
         M1306.5,304.9c3.9-2.5,7.5-4.5,10.6-7c0.8-0.6,0.8-3.4,0.1-4.4c-0.7-1-2.8-1.5-4.2-1.3C1307.7,293,1305.7,296.6,1306.5,304.9z"/>
      <path d="M770.8,230.2c-28.9-0.9-54.3-12.7-73.7-35.8c-27.5-32.7-23.9-86.7,7.1-116.7c20.5-19.9,45.2-28.3,73.6-27.1
        c43.8,1.9,79,31.6,86.4,74.6c5.3,30.7-4.1,57.4-26.7,78.8C819.3,221.2,797.2,229.5,770.8,230.2z M773.1,91.5
        c-12,0.1-22,2.8-30.3,9.4c-18.7,14.9-30.4,38.7-12.6,64.9c10.5,15.6,26.4,22.4,44.6,22c18.8-0.4,32.5-10.7,41.6-26.8
        c8.9-15.7,7.2-31.4-2.1-46.3C804.6,99.1,789.8,92.1,773.1,91.5z"/>
      <path d="M2548.9,631.3c-5.1,0.8-9.6,1.9-14.2,2.2c-15.1,0.9-29.9-1.3-44.6-4.6c-30.9-6.9-48.7-25.8-53.9-57.9
        c-1.7-10.5-2.3-21.2-2.6-31.8c-0.7-27.3-1-54.6-1.4-81.9c0-1,0-2-0.1-3c-0.7-13.5-4.8-16.5-17.7-13.3c-1.6,0.4-3.2,0.7-4.9,0.9
        c-0.6,0.1-1.2-0.3-3.1-0.8c-0.6-14.4-1.8-29.2-0.7-44.1c0.4-5.7,4.3-8,9.4-8.6c4.6-0.5,9.3-0.3,14-0.5c6.8-0.3,9.2-1.9,9.9-8.5
        c0.6-5.2,0.1-10.6,0-15.9c-0.1-6.3-0.7-12.7-0.3-18.9c0.5-7.3,2.4-9.3,9.5-10.7c4.5-0.9,9.3-1.3,13.8-0.7c9.1,1.1,17.9,0.4,26.7-1.9
        c10.7-2.8,17.3,1.7,18.5,13c1.2,10.9,1.6,21.9,2.3,32.9c0.5,8,0.7,8.8,8.4,9.7c8.2,1,16.6,0.9,24.9,0.9c16.2,0.1,16.6,0.2,17,16.7
        c0.2,8.6,0.2,17.3-0.2,26c-0.4,9.9-3,12.1-13.1,11.7c-5.3-0.2-10.6-1.4-15.9-1.8c-24.8-1.9-29.4,4.2-28.6,25.1
        c0.9,22.6,3.8,45.1,6.2,67.6c1.3,12.6,3.2,25.1,5,37.6c1.7,11.6,5.9,14.6,17.8,12.7c0.3-0.1,0.6-0.2,1-0.2
        c15.7-2.7,18.6-0.5,19.2,15c0.3,7,0.2,14-0.2,21C2550.9,622.7,2549.9,626.3,2548.9,631.3z M2487.8,347.7c0.4-4.1-1.9-6.5-5.6-7
        c-8.2-1-16.6-2-24.8-1.9c-2.7,0-6.9,2.6-7.6,4.9c-0.9,2.9,0.2,7.3,1.9,10.1c2.4,4,7,3.1,10.9,1.9c8.5-2.6,17-5.5,25.5-8.3
        c-2.9,2.7-5.6,5.6-8.7,8.1c-7.8,6.3-15.7,12.3-23.6,18.5c-3.4,2.7-6.7,5.5-6.1,11.2c4.6,0.9,9.2,1.8,14.7,2.8
        c-4,6.5-7.2,11.8-10.7,17.5c2.9,1.8,5.2,3.3,7.4,4.7c-5.8,14-13,16.8-23.2,9c3.1-4.1,6.2-8.2,10.3-13.6c-6.5-2.9-11.7-6.4-17.3-7.4
        c-16.2-2.8-18.5,0.7-18,14.8c0,1.3,0.1,2.7,0.1,4c-0.2,8.2,2.5,14.2,11.3,16.2c3.5,0.8,6.9,2.5,10.2,3.9c9.9,4.1,19.3,3,28.8-1.8
        c9.2-4.7,18.6-9,28.2-12.4c8.9-3.2,12.7-9,12.8-17.9c0.1-6.1-5.4-10.5-11.3-8.5c-2.5,0.8-4.6,2.6-7,3.9c-7.8,4.4-11,5.3-17.8,5.2
        c25.5-16.4,26.9-18.1,26.6-28.9c-5.1-3.1-9.6,0.1-14.1,1.8c-4.6,1.8-7.5,1-8.5-5c5.1-1.7,9.9-3,14.4-4.9c3-1.2,6.6-2.6,8.3-5
        c2.1-3,3.8-7.3,3.4-10.8c-0.5-4.4-5.2-4.2-8.9-4.4C2489.1,348.1,2488.5,347.8,2487.8,347.7z M2463.9,480c-0.2-0.7-0.4-1.4-0.6-2.2
        c7.4-5,14.7-10,22.3-15.2c-1.8-4.9-2.5-9.2,2.5-12.4c1.3-0.8,1.2-3.8,2.1-7c-14.7-2.4-28.1-3.2-41.1,2.5c-7.9,3.5-9.1,8.3-4.6,16.6
        c6.7-1.2,6.7-1.2,10.8,1.5c-1.7,1.3-3.3,2.4-4.7,3.7c-1.8,1.5-3.7,2.9-5.1,4.7c-3.1,4.1-2.4,7.3,2.1,9.8c1.5,0.8,3.4,1,5.5,1.6
        c-8.9,9.1-12.6,11.6,1.6,16.2c-3,3.7-6.2,7-8.8,10.8c-3.6,5.2-3.2,7,2.5,13.1c6.4-5.5,14.4-5.5,22.4-5.8c5.6-0.2,11.3-0.9,16.9-1.5
        c3.7-0.4,6.5-2.5,6-6.5c-0.6-4.4-4.1-4.6-7.7-3.8c-1.9,0.4-3.8,1.6-5.7,1.6c-1.8-0.1-3.6-1.4-7-2.9c4.2-2.7,6.5-4.4,9-5.8
        c3.9-2.1,7.8-3.9,7.9-9.2c0.1-5.7-3.9-8.4-8.3-10.1c-2.9-1.2-6.3-1.3-9.5-1.2C2469.7,478.5,2466.8,479.4,2463.9,480z M2496.9,551.6
        c-5.8-3.2-9.5-1.7-11.9,2.6c-3.2,5.8-8.4,8.2-14.4,9.7c-3.8,1-7.7,2.1-11.3,3.7c-3,1.3-5.9,3-8.4,5.2c-3.5,3.1-3.1,7.1-0.7,10.9
        c2.4,3.7,5.8,4.1,9.6,2.3c4.1-1.9,8.2-3.8,12.3-5.7c2.5,4.5,0.7,7.1-2.1,9.3c-2.1,1.6-4.8,2.6-6.5,4.6c-1.3,1.5-2.4,4.3-1.9,6
        c0.6,1.7,3.1,3.5,5.1,3.8c2,0.4,4.6-0.4,6.5-1.5c7.7-4.5,15.4-9.3,22.9-14.1c1.5-1,2.6-2.8,3.6-4.4c0.4-0.6-0.3-1.8-0.5-2.9
        c-2.6-0.8-5.2-1.6-8.3-2.6c3.4-3.8,6-6.7,9-10c-2.9-2.2-5.4-4.2-8.2-6.4C2493.3,558.7,2494.9,555.6,2496.9,551.6z M2527.9,411.7
        c-0.3-0.5-0.6-1-1-1.6c2.6-1.8,5.3-3.7,7.9-5.5c2.5-1.8,5.1-3.5,8.2-5.7c-10.5-4.6-19.4-4.7-26.1-0.9c-3.5,1.9-6.2,4.4-4.7,8.7
        c2.9,8.4-1.3,12.9-8,16.8c-3.5,2-7.4,4.7-5.9,10.7c16.3,0.8,32.3,1.1,48.2-2.9c2.6-8.1,5.7-15.5-0.4-23.4
        C2540.1,409.2,2534,410.4,2527.9,411.7z M2493.3,523.8c-4.3,1.6-7.9,3-10.3,3.9c-4-1.6-7.4-4.3-9.7-3.6c-8.2,2.5-16.3,5.5-23.8,9.5
        c-4.7,2.5-7.5,7.4-4.1,14.8c6.4-3,12.5-5.8,18.6-8.6c0.3,0.3,0.5,0.5,0.8,0.8c-6,7-12,14-18.3,21.4c9.2,2.8,21.3-0.4,25.6-7.7
        c4.3-7.2,10.5-10.5,17.6-13.5C2497.9,537.5,2498.7,533.3,2493.3,523.8z M2537.3,596.6c-10.6,2.2-21.5,9.5-28,18.7
        c1.6,2,3.4,3.9,4.9,6c3.6,5.2,8.1,6.2,13.7,3.5c1.8-0.9,3.8-1.2,5.6-2c6.5-3.2,6.7-7.3,0.7-11.5c-1-0.7-2.1-1.4-3.3-2.1
        C2533.1,604.8,2539.6,602.9,2537.3,596.6z M2512.8,596.9c-9.7-2.3-32.2,6.5-40.4,15.9C2490.3,621.6,2508.9,613.8,2512.8,596.9z"/>
      <path d="M2225.5,441.7c-0.9-9.8-1.7-19-2.4-28.3c-0.2-3-0.1-6,0.1-9c1-14.1,2.9-16,16.8-16.3c4.9-0.1,9.9,0,15.9,0
        c0.7-6.5,1.8-12,1.9-17.5c0.2-8.3-0.5-16.7-0.4-25c0.1-7.9,1.8-10,9.4-11.9c4.1-1,8.7-1.4,12.8-0.7c8.8,1.3,17.2,0.6,25.7-1.6
        c12.6-3.3,19.1,1,20.5,14.2c1.1,10.3,1.2,20.6,1.8,30.9c0.5,8.4,0.6,9.2,9,10.2c8.5,1,17.3,0.9,25.9,1c14.4,0.3,15.2,0.8,15.4,15.3
        c0.1,9.3-0.1,18.7-0.4,28c-0.3,9.6-2.5,11.4-12.4,11c-5-0.2-10-0.8-14.9-1.3c-1.7-0.2-3.3-0.6-4.9-0.7c-16.8-1.3-25.6,5.6-25.4,22.3
        c0.2,15.3,2.3,30.5,3.9,45.7c2.3,20.5,5,41,7.5,61.5c1.5,12.5,5.5,15.7,17.9,13.6c15.8-2.7,20.8-1.9,20.1,16.7
        c-0.3,7.7,0,15.3-0.3,23c-0.3,7.7-2.6,10.2-10.3,10.5c-22.5,1-44.5-1.1-65.7-9.5c-15.1-6-25.6-16.4-31.8-31.3
        c-8-19.1-10.1-39-9.8-59.6c0.4-26-0.5-51.9-0.9-77.9c0-2.6-0.3-5.4-1.1-7.9c-2.1-6.6-6.5-8.7-13.1-7c-2.9,0.8-5.7,1.7-8.6,2.4
        C2227.1,442.6,2226.4,442,2225.5,441.7z M2291.8,372.9c5.2-1.7,10.2-3.3,15.2-5c5.1-1.7,8.8-5.1,9.8-10.4c0.4-2.3,0-5.8-1.4-7
        c-1.5-1.2-5.4-1.4-7.1-0.2c-11.9,7.8-23.7,16-35.2,24.3c-3.1,2.3-6.2,5.3-4.7,10.5c4.2,1.1,8.5,2.3,14.4,4
        c-3.3,4.5-5.8,7.8-8.1,11.3c-2.7,4.1-1.7,6.4,3.2,8c1.3,0.4,2.7,0.5,4.4,0.8c-8.8,14.5-18.2,18-28.2,11.1c2.6-5.3,11.4-6.8,9.8-15
        c-3-1.9-5.8-4.6-9.1-5.8c-4.6-1.5-9.6-2.8-14.3-2.4c-7.2,0.5-9.2,3.2-9.3,10.4c0,5,0.6,10,0.7,15c0.1,5.2,2.8,7.6,7.5,9
        c6.4,1.9,12.6,4.2,18.8,6.7c4.9,2,9.6,2.2,14.5,0.1c13.7-5.9,27.5-11.9,41.3-17.6c4.8-2,7.5-5.2,8.1-10.4c0.4-2.9,1-5.8,1.5-8.5
        c-4.2-5.7-9-6.8-15.1-3.9c-3.4,1.6-7.2,2.5-13,4.4c7.9-9.3,19-13.6,18.4-27.3c-3.6,0.5-6.7,0.7-9.8,1.4c-2.6,0.6-5,2-7.6,2.5
        C2293.3,379.5,2291.6,377.8,2291.8,372.9z M2293.2,506.8c1.4-6.7,6.7-6.6,9.9-9c4.9-3.7,6.6-7.3,4.7-11.7c-2.2-5.2-11-10-16.4-8.2
        c-6.6,2.1-13.2,4.4-19.5,7.4c-3,1.4-6.7,4.2-7.4,7.1c-1.2,4.3,4,5.2,6.8,7.3c0.4,0.3,0.4,1.1,0.7,2c-4.9,6.3-13,11.8-7.2,22.4
        c10.4-5.1,21.1-6.9,32.2-6.7c3.9,0,7.9-1.1,11.6-2.4c1.5-0.5,3.5-3.3,3.2-4.2c-0.7-1.8-2.7-3.8-4.5-4.4c-2-0.6-4.4,0.7-6.7,0.8
        C2298.3,507.4,2296,507,2293.2,506.8z M2292.2,583.4c-1.8,2.4-3.4,4.7-5.3,6.7c-1.4,1.4-4,1.8-4.8,3.4c-1.3,2.3-2.4,5.6-1.7,7.8
        c0.5,1.5,4.7,3.1,6.6,2.5c6-1.9,12-4.2,17.3-7.4c5.5-3.3,11.6-6.5,14.4-14.1c-2.9-1.4-5.6-2.7-8.4-4.1c2.7-3.2,5.1-6.2,8.6-10.4
        c-5.5-2.4-10.2-6.5-14.3-5.9c-10.8,1.5-21.5,4.5-31.9,7.9c-5.6,1.8-7.3,8.6-4.7,13.4c2.3,4.2,6.1,4.9,12.1,1.9
        C2283.6,583.3,2286.7,580.3,2292.2,583.4z M2330.9,401.1c0,2-0.3,3.4,0,4.6c2.2,8.3-0.6,13.9-8.4,17.7c-3.3,1.6-6.9,3.9-6.7,9.6
        c14.2,2.1,27.9,2.3,41.7-0.9c7.8-1.8,9.6-3.5,9.8-11.4c0.1-4.1-1.3-8.2-2.1-12.6c-7.3,1.1-13.4,2.1-21.9,3.3
        c7.2-5.2,12.5-9.1,18.5-13.5C2350,391.8,2340.7,395.4,2330.9,401.1z M2309.6,445.7c-15.9-5.4-29.7-5.9-43.3,0.7
        c-6.5,3.2-8.3,7.6-5.3,13.8c2.1,4.4,2.9,8.3,1.4,13.2c-1.7,5.3,1.9,9,7.5,8.4c2.2-0.3,4.5-1.3,6.5-2.4c7.2-4.1,14.3-8.5,21.5-12.6
        c3.8-2.2,6.4-4.5,4.3-9.6c-0.5-1.2,1-3.4,2-4.9C2305.4,450.4,2306.9,448.8,2309.6,445.7z M2265.1,561.9c9.1,3.2,20.4,0.1,25.3-7.6
        c4-6.3,9.1-10,15.9-12.5c9.9-3.6,10.6-7.8,3.5-17.9c-4.3,3-8.5,6.1-12.9,8.9c-4.1,2.7-8,6.2-14.6,6.5c5-4.9,11.2-7,13.2-13.3
        c-15.5-3.7-32.4,6.5-32.9,19.5c5,4,9.1-1.4,15.9-1.8C2273.7,550.4,2269.7,555.8,2265.1,561.9z M2350.8,609.7c1.9-3,3.8-5.3,4.9-8
        c0.6-1.2,0.4-3.5-0.4-4.4c-0.8-0.8-3.2-1.1-4.3-0.5c-8.6,4.9-17.3,9.6-24.3,17.5c2,2.6,3.7,5.1,5.6,7.2c5.2,5.8,15.7,6,21.1,0.4
        C2358,617.3,2357.6,614.9,2350.8,609.7z M2305,343.9c-0.3-0.8-0.6-1.7-1-2.5c-9.8-1-19.6-2.5-29.4-3c-6.3-0.3-9.4,4.7-7.6,10.8
        c1.7,6,5.6,8.6,12.2,6.9c7.3-2,14.4-4.8,21.5-7.7C2302.5,347.7,2303.6,345.4,2305,343.9z M2331.5,595.9c-3.3,0-6.2-0.8-8.5,0.1
        c-9.2,3.6-18.3,7.7-27.3,11.8c-2.1,1-3.7,3.2-5.9,5.2C2309.9,620.3,2325.1,615.5,2331.5,595.9z M2305.5,561.4c3.5-4,10.5-4,7.6-9.6
        c-2-4-6.2-0.2-9.2,1.3c-0.5,0.3-0.8,1-1.4,1.9C2303.2,556.5,2304,558.1,2305.5,561.4z"/>
      <path d="M1228.4,388.4c0.7,3.9,1.9,7.4,1.9,10.9c0.1,11.3,0,22.6-0.5,33.9c-0.3,6.9-2.6,8.9-9.4,8.8c-5.6,0-11.3-0.8-16.9-1.3
        c-1.7-0.1-3.3-0.6-4.9-0.8c-16.2-1.6-24.7,4.6-26,20.6c-0.8,9.2,0.3,18.6,1.3,27.8c3,27.1,6.4,54.2,9.8,81.3
        c1.5,12.1,5.8,15.3,18.2,13.6c18.3-2.4,20.1-0.8,20.2,17.9c0.1,7,0,14-0.5,21c-0.5,8.3-3,10.8-10.9,11.2
        c-20.8,0.9-41.3-1.3-61.1-7.9c-18.5-6.2-31.1-18.7-37.8-37.1c-6.3-17.4-8.5-35.4-8.1-53.9c0.5-23,0.2-45.9,0-68.9
        c0-4.6-0.4-9.3-1-13.9c-1.4-11.1-5.7-13.8-16.2-10.7c-2.5,0.8-5,1.6-8,2.5c-0.6-2.5-1.5-4.7-1.6-7c-0.5-12-1.1-24-1-35.9
        c0.1-9,3.1-11.7,12.2-12.3c4-0.3,8,0,12-0.2c7.6-0.4,9.3-1.7,9.9-9.4c0.4-5.3,0.2-10.6,0.1-16c0-5.3-0.5-10.7-0.3-16
        c0.4-9.4,3.2-12.5,12.2-13.9c2.3-0.4,4.7-0.6,7-0.2c10.7,1.8,21.1,1,31.6-1.9c8.6-2.4,14.9,2,17.1,10.8c0.9,3.5,1.2,7.2,1.4,10.9
        c0.4,7.3,0.5,14.6,0.9,22c0.6,10.9,2.8,13.2,13.9,13.5c8.7,0.2,17.3,0,26,0.1C1222.5,388,1225.1,388.3,1228.4,388.4z M1134.4,388.5
        c-2,6.5-9.1,10.5-7.9,18.3c2.7,1.1,5.2,2.1,7.3,3c-5,14.8-16.3,14.2-28.5,13.8c1.5-7.8,12.8-9.2,10.9-18.3c-3.1-2-6.1-4-9.2-5.9
        c-4.8-3-10-3.5-15.4-2.2c-6,1.4-8.3,3.4-8.2,9.5c0.1,7.5,1.1,15.1,1.6,22.2c8.7,3.2,16.4,5.4,23.6,8.7c6.3,2.8,12,2.8,18.2,0
        c12.5-5.5,25-11,37.6-16c6.3-2.5,9.4-6.7,10.3-13.3c0.5-3.8,1.4-7.2-2.1-10c-3.6-2.9-7.2-2.8-11.2-0.9c-3.8,1.8-7.7,3.2-11.6,4.8
        c5.5-8.9,16.4-13.3,16.6-26.8c-3.6,0.3-6.6,0.2-9.6,0.8c-2.6,0.5-5,2-7.5,2.6c-4.6,1-6-1.3-5.1-5.9c4.8-1.6,9.5-3.2,14.2-4.6
        c5.6-1.7,9.4-5.2,10.6-10.8c0.5-2.2-0.3-5.4-1.8-7.1c-1-1.1-4.6-1.2-6.3-0.3c-13,7.3-26.3,14.5-36.7,25.5c-2.2,2.4-3.3,5.8-5.3,9.4
        C1125.2,386.3,1129.6,387.4,1134.4,388.5z M1124.1,501.1c-2.6,3.8-5.8,7.7-8.1,12c-1.1,2-1.6,5.5-0.5,7.1c0.9,1.4,4.5,1.6,6.8,1.3
        c8.2-1.1,16.3-2.5,24.4-4c4.9-0.8,9.8-1.6,14.6-3c1.3-0.4,3-3.5,2.5-4.5c-0.8-1.7-2.9-3.3-4.8-3.8c-1.7-0.4-3.8,1.1-5.8,1.2
        c-2.4,0.1-4.8-0.3-8.7-0.5c1.9-2.7,2.5-4.2,3.6-5c2.8-1.8,5.9-3.1,8.6-5c5.8-4.1,6.2-9.6,1-14.1c-4.9-4.2-10.1-7.3-17.2-3.8
        c-5,2.5-10.6,3.8-15.9,6c-3.7,1.5-8,3.7-7.2,8.3C1117.9,495.8,1121.3,497.8,1124.1,501.1z M1183.4,417.2c-4.1,2.9-8.6,5.7-12.7,9
        c-1.3,1.1-2.5,3.6-2.2,5.1c0.3,1.3,2.8,3.1,4.3,3.1c13.6-0.5,27.2-1.3,40.8-2.3c1.6-0.1,3.8-1.8,4.4-3.3c2.7-7,4.2-14-1.5-20.7
        c-6.3,1.1-12.3,2.3-19.8,3.6c5.4-6.9,14.6-7.5,17.8-15.5c-8.4-3.4-15.9-2.4-23.3,0.1c-7.8,2.6-8.4,3.9-7.8,12.5
        C1183.6,411.4,1183.4,414.1,1183.4,417.2z M1169.6,568.3c-6.4-5.9-12.5-8.2-20.5-5.3c-5.9,2.1-12.2,3.1-18.4,4.7
        c-2.2,0.6-4.6,1.2-6.5,2.4c-4.8,2.9-6.4,8.3-4,12.9c2.2,4.4,6.1,5.1,12,2.2c3.5-1.7,6.6-4.8,12.2-1.8c-1.7,2.4-3.1,4.7-5,6.7
        c-1.3,1.4-4,1.8-4.8,3.3c-1.3,2.3-2.7,5.5-2,7.7c0.4,1.5,4.5,3.2,6.5,2.8c12.9-3,23.6-9.8,32.8-20.4c-3.2-2.3-5.4-3.9-7.6-5.6
        C1166,574.7,1167.6,571.9,1169.6,568.3z M1153.8,454.7c1.7-1.6,3.4-2.7,4.6-4.2c3.1-3.9,2.3-7-2.7-7.8c-12.6-2.3-25.2-2.1-37,3.7
        c-6.5,3.2-8,7.2-5.1,13.9c1.8,4.2,2.4,7.9,1.4,12.4c-1.6,6.9,3.2,11.6,9.3,8.7c11-5.2,22.4-10.1,31.6-18.7
        C1155.2,459.7,1154.5,457.3,1153.8,454.7z M1119.5,565.2c8.2-1.5,16.1-2.3,20.8-8.1c6.1-7.4,12.4-13.4,21.5-16.9
        c2.8-1.1,4.5-6.2,5.6-9.8c0.5-1.7-1.6-4.3-2.7-6.9c-7,3.8-12.8,6.9-20.1,10.9c1.2-3.7,2-5.9,2.8-8.3c-19.8-1.4-31.5,6.7-30.5,21.2
        c3.8-1,7.5-2,10.9-2.8C1129.2,553,1118,555.6,1119.5,565.2z M1179.4,613.8c8.4,13.4,10.6,14.6,22,10.3c3-1.1,6.2-3.9,7.7-6.8
        c2.5-4.6-3.4-5.3-5.6-8.4c1.4-2.2,3.1-4.2,4.1-6.6c0.6-1.5,0.6-3.7,0-5.2c-0.3-0.6-3.1-1-4.3-0.4
        C1194.7,601.6,1186.2,606.6,1179.4,613.8z M1183.9,596c-13.3-2.2-22.5,6.6-33.3,9.6c-3.3,0.9-6.4,3.4-6.3,7.7
        C1164.6,620.3,1177.6,614.9,1183.9,596z M1158.5,345.5c-0.7-1.1-1.4-2.3-2.1-3.4c-1.9-0.5-3.7-1.3-5.7-1.5
        c-7.3-0.8-14.6-1.5-21.8-2.1c-3.9-0.3-7.4,0.8-9.2,4.6c-1.8,3.7-0.7,7.1,1.9,10.1c2.7,3.2,6,3.9,9.9,2.4c7.5-2.8,14.9-5.6,22.4-8.5
        C1155.4,346.6,1157,346,1158.5,345.5z M1156.3,561.8c4.6-3.2,7.3-4.6,9.2-6.6c0.6-0.7-0.7-3.1-1.1-4.7c-2.7,0.8-5.9,1-7.9,2.6
        C1155.4,554,1156.3,557.4,1156.3,561.8z"/>
      <path d="M1608.4,584.5c0.5-3.2,0.9-6.3,1.4-9.1c-8.7-1.3-17.1-3.1-25.5-3.8c-8.5-0.6-17.2-0.1-25.7-0.1c-1.3-5.8,1.8-6.3,4.9-5.7
        c3.9,0.7,7.8,1.8,11.6,3c7.1,2.4,13.7,1.3,20.2-1.7c0.2-8.6,0.2-8.6-14.6-13c2.1-3.6,5.1-6.9,6-10.7c0.5-2.1-2.4-5-3.9-7.6
        c-6.3,1-10.5,3.9-14.1,7.9c-4.2,4.6-9.1,7.4-15.4,8.3c-7.8,1.1-11.4,5.8-9.6,13.3c1.8,7.7,5,15.1,7.8,22.5c2,5.5,7.2,4.9,12.4,5
        c-1.9,8,2.7,11.7,8.5,14.1c12.6,5.2,25.1,10.6,38.1,14.6c5.7,1.8,12.4,0.3,19.6,0.3c2,4.4,6.8,4.8,12,4.7c7.3-0.2,14.7,0,22-0.1
        c17.1-0.1,31.7-5.3,44.1-15.7c2,2,4.1,4.1,6.3,6.3c-3.4,6.3-9.3,8.5-15.2,10.5c-18.3,6.2-37.2,7.1-56.3,5.4
        c-9.6-0.9-19.2-2.3-28.7-3.6c-2.6-0.3-5.4-0.5-7.8-1.6c-17-7.7-33.9-15.5-50.7-23.6c-6.3-3.1-9.4-9.1-11.6-15.7
        c-3.2-9.5-5.8-19.2-9.7-28.3c-9.6-22.6-9-45.5-3.2-68.6c2.7-10.6,6.5-21,11-31c7.7-17.2,20.2-30.8,37-39.3
        c13.3-6.7,27.5-11.8,41.4-17.2c7.6-3,15.9-3.1,23.6-0.8c15.3,4.6,30.7,9.1,45.4,15.1c20.2,8.2,32.8,23.4,36.5,45.3
        c1.7,9.8,3.5,19.7,5,29.5c0.4,2.6,0.4,5.3,0.3,8c-0.3,17-6.9,24.6-23.6,26.3c-16.2,1.7-32.5,2.7-48.8,4c-17.6,1.4-35.2,2.6-52.8,4
        c-1.7,0.1-3.4,0.2-4.9,0.6c-4.1,1.1-6.2,3.5-5.2,8.1c1.6,7.9,5.4,14.4,12.1,18.8c15.6,10.2,32.1,17.2,51.5,15.9
        c10.8-0.8,21.8-0.1,33,0.4c-16.1,4.7-31.2,11.1-41.4,26.1c2.7,1.2,5,2.2,7.3,3.2c-0.1,0.8,0,1.8-0.3,1.9c-2.6,1.2-5.7,3.6-7.8,2.9
        c-7.1-2.4-12.9-0.9-18.9,5.2c-1-8.2,1.9-12.7,7.3-16c4.3-2.6,8.8-4.8,12.9-7.6c2.9-2,5.2-4.6,8.4-7.5c-6.9-3.7-11.9-1.2-16.9,1.3
        c-5,2.6-10.1,5.1-15.6,7.9c-1.9-1.7-3.8-3.4-6.2-5.6c1-1,2.2-1.8,2.6-2.8c0.6-2,1.4-4.4,0.7-6.1c-0.4-1.1-3.5-1.8-5.2-1.5
        c-3.9,0.8-7.6,2.3-11.4,3.5L1608.4,584.5z M1594.6,437l0.1,0.2c1.9-4,3.9-8,5.8-12.1c-15-4.3-28.8,2.6-34.1,17
        c9.4-1.4,10.3,8.9,18.9,11.3c-12,5.1-22.1,9.2-32.1,13.6c-3,1.3-6.1,3-8.3,5.3c-4.4,4.6-3.4,7.7,1,12.5c5.5,6,12.3,8.7,19.9,10.5
        c4.2,1,8.4,2,12.6,3.1c3.3,0.9,4.7,3,4.7,5.8c-7.2,1.2-13.6,2.2-20.7,3.4c-1.1-1.7-2.4-3.8-4-6.2c-4.7,0.9-9.3,1.4-13.8,2.7
        c-8.5,2.4-11.9,10.3-8.7,19.1c2.6,7.2,8.2,8.6,17.3,4c2.4-1.2,4.6-2.6,6.9-4c7.4-4.5,14.8-9,22.3-13.4c2.4-1.4,5-2.3,8.8-3.9
        c-2.2,7.6-10.8,12-5.3,20.1c3.9-1.4,7.3-3.6,10.7-3.6c3.4,0,6.7,2.5,10.2,3.3c2.5,0.6,5.3,0.4,7.9,0.6c32.1,2.2,63.8-3.1,95.5-6.5
        c1.6-0.2,3.3-0.6,4.8-1.2c7.1-2.8,9.6-12.1,4.8-18.1c-2.4-3-6.5-2.8-8.1,0.7c-1.1,2.2-1.4,4.8-2.2,7.5c-12.9-1.8-24.6,0.7-36.2,3.9
        c-2.6,0.7-5.2,1.3-7.8,1.6c-3.4,0.3-5.9-1.4-6.5-4.8c-0.7-4,1.5-6.6,5.1-7.2c2.3-0.3,5.1,0.5,7.2,1.6c7.7,3.7,11.2,1.5,10.8-7.1
        c-0.1-2.2-0.8-4.4-1.3-6.9c-8.1,0.5-15.7,1.5-23.3,1.4c-6.1,0-11.8,0-15.7,7.5c-4.2-9.3-11-9.4-18.2-9.1c-9.3,0.3-18.7,0.1-28,0
        c-3.6,0-7.1-0.5-10.7-0.7c-0.6-2.1-1.1-3.3-1.3-4.6c-1.9-12.1-1.9-12-13.9-11.1c-0.5,0-1.1-0.6-1.6-1c2.1-7.6,3.2-9.3,8.6-7.6
        c10.1,3.2,16.5-1,22.6-8.2c6.4-7.5,14.3-13.3,24.3-14.8c9.1-1.4,16.2-4.9,21.7-12.6c3.5-5.1,8.9-6.4,15.2-3.5
        c-0.2,13.6-13.2,13.8-21.1,19.8c6.9,5.6,13.5,4.4,20.5,2.9c7.7-1.7,6.8-9,9.5-13.9c4.6-0.2,8.8-2.5,14.4-0.9
        c-5.9,8.3-11.1,15.7-16.7,23.5c3,0.8,5.1,1.4,7.4,2c0.3,1.5,0.6,2.8,0.8,4.1c2.3,12.4,4.8,14.6,17,14.9c2.9,0.1,5.8,0.3,8.5,0.5
        c2.6,11.9,10.6,15,20.6,8.5c-0.3-3.2-0.9-6.4-1-9.7c-0.1-3.7,0.5-7.3,0.3-11c-0.5-10.9-6.1-16.3-17.1-16.6c-3.1-0.1-6.2,0-9.3,0
        c-0.2-0.7-0.3-1.5-0.5-2.2c3.9-2.6,7.7-5.1,11.6-7.8c-5.5-10.1-13.7-15.1-23.7-18.1c-6.9-2.1-13.5-5.4-20.4-7.6
        c-3.3-1-7.5-2.1-10.3-1c-6.7,2.8-13.6,6-19,10.7c-7.2,6.4-15,9.3-24.3,10C1603.3,434.1,1599,435.8,1594.6,437z M1593.4,478.9
        c11.3,4,21.3,3.6,31.2,3.8c11.3,0.3,22.6,0.2,33.8-0.4c8.5-0.4,10.3-3.9,6.9-11.8c-3.2-7.6-9.4-11.9-16.9-14.3
        c-16.1-5.2-40,1.8-50.8,15C1596,473.1,1595.2,475.6,1593.4,478.9z M1560.7,539.3c-6.9-3-12.1-1.8-16.8,1.8c-1.1,0.8-2.2,3.4-1.8,4
        c1,1.3,3.1,2.9,4.6,2.6C1551.9,546.9,1556.8,545.1,1560.7,539.3z M1560.6,448.6c-2.4,2.4-4.3,4-5.8,5.9c-0.2,0.2,2.3,3.2,2.6,3
        c2.1-1.1,4.2-2.6,5.9-4.3C1563.6,453,1562,450.9,1560.6,448.6z M1571.3,531.7c3.1-1.3,6.2-2.6,9.3-3.9
        C1576.1,525.6,1572.2,524.9,1571.3,531.7z"/>
      <path d="M2800.9,583.1c2.2-2.6,4.4-5.1,7.5-8.7c-19.2-1.4-36.6-2.7-53.9-4c1.2-6.4,1.7-7,6.7-4.6c7.9,3.7,15.9,5,24.2,2.7
        c2.2-0.6,4-2.7,5.9-4.1c-1.7-2.3-2.9-5.2-5.1-6.6c-3-1.9-6.7-2.5-11-4c2.1-2.9,4.4-5,5.3-7.5c0.8-2.2,1-5.4,0-7.3
        c-1.6-3-5-3.3-7.9-1.6c-3.1,1.8-6.4,3.7-8.8,6.4c-3.8,4.2-8.2,6.4-13.6,7.7c-11.5,2.9-13.9,7.4-10.8,18.9c0.8,3.2,1.5,6.5,2.7,9.6
        c1,2.8,1.9,6.5,4.1,7.8c11.9,7.5,18.9,21.4,33.7,25.2c7.7,2,15.1,5.2,22.7,7.7c6.8,2.3,13.7,2.4,21.3-0.4c3.2,5.1,8.3,6.5,14.3,6.3
        c8.7-0.4,17.3,0,26-0.4c3.9-0.2,7.8-1.4,11.7-2.3c4.8-1.2,9.6-2.4,14.3-4c2.5-0.8,5-2,6.9-3.7c3.9-3.6,7.7-2.9,12.6-0.4
        c-2.7,6.9-8.5,9.3-14.3,11.3c-18.6,6.3-37.8,7.5-57.3,5.6c-8.9-0.9-17.9-2.1-26.8-3.3c-4.3-0.6-8.8-0.9-12.7-2.6
        c-15.2-6.7-30.3-13.6-45.1-21.2c-7.9-4.1-12.6-11.6-15.3-20.2c-2.8-8.9-5.9-17.7-8.9-26.5c-10.9-31.1-9.1-61.5,5.1-91.2
        c1-2.1,2.3-4.1,3-6.3c7.2-20.1,21.4-33.6,40.5-41.9c13.4-5.9,27.1-11.3,41-15.8c5.7-1.8,12.7-2.1,18.5-0.6
        c14.8,3.8,29.4,8.6,43.9,13.6c22.7,7.9,36.4,23.9,40.6,47.7c1.9,10.8,3.5,21.7,5,32.6c0.4,2.6,0.1,5.3-0.2,8
        c-1.9,14.3-5.2,18.5-19.4,21.5c-10.7,2.3-21.7,3.7-32.6,4.4c-17.3,1.2-34.6,1.5-51.9,2c-10.3,0.3-20.7,0.1-30.5,5.1
        c-2.3,9.3,1.8,16.4,8.3,22.1c12.3,10.8,26.7,17.8,43.3,18.2c12,0.3,24-0.2,36-0.4c2.9,0,5.8,0,9,1.3c-2.3,0.9-4.6,1.8-7,2.7
        c-7.2,2.6-14.3,5.3-21.6,7.7c-1.8,0.6-3.8,0.4-5.8,0.6l0,0c0-6.1-4.3-5.8-8.1-4.8c-4.8,1.3-9.3,3.4-13.9,5.3
        c-3.7,1.5-7.4,3-11.4,4.6c-1.3-1.4-2.6-2.8-4-4.3c1.5-2.3,3-4.4,4.3-6.5c-1.2-3.8-3.8-4.7-7.1-4.2c-4.6,0.7-9.1,1.7-13.6,2.5
        L2800.9,583.1z M2780.3,525.9c3.6-1.1,6.4-2.2,9.3-2.7c1.8-0.3,4.2-0.6,5.6,0.3c5.5,3.5,11.5,3.2,17.4,3.1c9.3-0.1,18.7,0.1,28-0.7
        c22.8-2.1,45.6-4.8,68.5-7.2c1-0.1,2-0.2,2.9-0.7c3.5-1.6,6.7-3.9,5.5-8.3c-1-3.6-2.2-9-4.7-10c-5.4-2.3-5.1,4.4-7.4,7.2
        c-0.2,0.2-0.5,0.4-0.2,0.2c-10.1,1.4-19.6,2.5-29.1,4c-4.3,0.7-8.4,2.5-12.6,2.9c-2.6,0.2-6.1-0.8-7.7-2.5c-1.3-1.4-1.5-6.1-0.3-7.1
        c2.5-2.2,6.3-3,9.6-4.4c1.8,2.9,3.2,5,4.8,7.7c3-1.2,5.6-2.3,8.5-3.5c-0.5-4.6-1-8.4-1.5-12.9c-8,0-15,0.2-21.9-0.1
        c-7-0.3-13.8-0.1-18.9,5.5c-6.4-7.2-6.4-7.2-14.9-7.2c-11.3,0.1-22.7,0-34,0.2c-5.4,0.1-8-2.3-8.6-7.4c-0.4-3.2-1-6.4-1.6-10.3
        c-5.1,0.6-9.3,1-13.4,1.5c-2.1-2.5-4-4.7-6.1-7.1c-4.1,1-8,1.7-11.8,2.9c-8.5,2.8-9.2,5-5.1,14.4c2.4,0,4.9,0,8,0
        c-1.2,4.3-1.1,7.5,3.9,8.6c5.8,1.4,11.5,3.3,17.2,5.2c2.4,0.8,6.2,1.6,6.7,3.3c1.8,5.6,6.9,4.9,11.1,6
        C2779.6,517.5,2779.6,517.5,2780.3,525.9z M2915.6,488.7c0-8.6,0.6-17.6-0.2-26.4c-0.7-8-6-12.1-14.3-12.6c-4.2-0.3-8.4,0-12.6,0
        c1.7-2.8,3.8-4.2,5.8-5.6c2.1-1.4,4.3-2.9,6.4-4.3c-7-11-14.5-16.1-26.2-18.8c-4.7-1.1-9.8-2.4-13.7-5c-6.7-4.4-13.1-4.7-19.6-0.8
        c-5.9,3.6-11.4,7.9-17.2,11.6c-3.3,2.1-6.8,4.5-10.5,5.3c-6.4,1.3-13,1.5-19.7,2.2c1-11.4-3-14.4-12.5-10.3
        c-3.9,1.7-7.8,3.8-11.3,6.3c-3.7,2.6-7.6,5.4-7.8,9.7c6.5,4.8,12.3,9.1,18.3,13.6c-6.2,5.2-16.5,2.1-19.7,11.5
        c9.2,0.4,17.8,0.8,26.2,1.2c1.4-1.9,2.6-3.5,3.9-5c8.8-10.5,18.9-18.8,33.1-20.4c6.8-0.8,11.7-4.5,15.5-9.9c4.1-5.8,9.2-8,16.5-5.3
        c-0.2,13.1-11.7,14.1-19.7,18.8c13.9,9.2,21.8,6.4,29.2-10.2c3.9-0.5,8-1,14.6-1.9c-6.6,9.3-11.8,16.6-16.3,22.9
        c3,5,5.8,8.7,7.4,12.8c2.8,7.1,5.4,9.7,12.9,10.2c3.9,0.3,7.9,0,11.6,0C2899.1,490,2903.3,492.1,2915.6,488.7z M2861.2,479.1
        c-1.7-14.9-6.8-20.9-20.4-23.5c-17.6-3.5-32.8,1.7-45.8,13.7c-2.6,2.4-4,6.2-6,9.6C2800.8,484,2849.6,483.9,2861.2,479.1z
         M2753.9,501.1c-5.5,1.3-11.1,2.1-16.3,4c-7.5,2.8-9.7,11.2-5.7,19.2c3.2,6.5,8,7.5,15.8,2.9c7.1-4.2,14-8.8,20.9-13.4
        c3.1-2,6-4.3,9.6-6.8c-8-4.7-14.2,0.9-21.1,0.3C2756.2,505.7,2755.3,503.8,2753.9,501.1z M2755.6,539.6c-5.9-3.1-10.7-2.3-15,0.5
        c-1.6,1.1-2.3,3.6-3.4,5.4c1.9,0.8,4.1,2.5,5.8,2.1C2747.7,546.7,2752.5,545.2,2755.6,539.6z M2757.1,446c-3.8,4.5-5.5,6.6-7.2,8.6
        c1.4,1.1,3.7,3.3,4.1,3c2.1-1.5,4.1-3.5,5.4-5.8C2759.7,451,2758.2,449,2757.1,446z M2765.1,531.8c4.1,0,7.4-0.7,9.5-4.9
        C2766.6,526.6,2766.6,526.6,2765.1,531.8z"/>
      <path d="M787.5,385.7c-23.9-1.9-47-3.8-70.4-5.7c-6.1-18.1-5.5-35.4-4.2-54.3c6.9-0.7,13.1-1.9,19.2-1.8c31.6,0.7,63.3,0.6,94.8,3.2
        c35.6,2.9,71.2,3.3,106.8,3.1c4,0,7.9,0,12.7,0c6.5,15.4-0.5,30.5,1.1,46.7c-6,1-11.4,2.2-16.9,2.6c-15.9,1.1-31.9,1.7-47.9,2.6
        c-3.7,0.2-7.3,0.5-10.9,1c-9.7,1.2-11.2,3-10.4,13c0.7,8.3,2,16.5,3.2,24.7c5.5,35.9,9.2,72,6.8,108.4c-0.6,9.6-1.8,19.3-1.7,28.9
        c0.2,14.6,1.3,29.2,1.9,43.9c0.2,4,0.3,8,0.4,12c0,4.7-2,7.2-7,7.8c-17.9,2.4-35.8,2.3-53.8,0.6c-5.2-0.5-8-3-8.8-7.7
        c-1.2-6.9-2.5-13.8-2.8-20.7c-0.9-18-1.8-35.9-5.4-53.6c-1.7-8.4-2.2-17.2-2.2-25.8c0.1-19.3,0.8-38.6,1.3-57.9
        C793.9,433.3,791.2,410.2,787.5,385.7z M841.6,346c1.1-3,1.5-6.6-2.1-7.4c-8.2-2-16.7-4.3-24.1,2.6c3.9,6.3,5.2,7.2,11.7,6.6
        c4.9-0.4,9.8-1.3,14.7-1.9c-7.9,8.7-17.1,15.2-29.2,18.4c-0.4-6.4-0.6-11.3-0.9-16.8c-12.1,0.4-14.9,13.6-25,16.5
        c-1.6-2.1-3.2-4-4.8-6.1c4.4-9,16.8-10.8,18.5-21.9c-5.1-3-9.6-2.3-13.3,1c-6.4,5.8-12.8,11.7-18.2,18.3c-4.4,5.4-7.1,12.1-5,19.7
        c12,4.5,23.4-3.3,34.4-1.9c2.6,5.9,4.9,11,7.3,16.2c-1,3-2.1,6.3-3.8,11.4c6.3-1.4,10.8-2.5,15.3-3.5c-3.1,4.6-6.9,7.7-10.8,10.5
        c-4.2,3-6.3,6.4-4.3,11.9c0.9,2.5,0.4,5.6,0.2,8.4c-0.5,6.2-1.6,12.4-1.8,18.7c-0.3,11.3,0.4,22.6-0.2,33.9c-0.5,9.1-1.1,9,7.8,13
        c0.4,0.2,0.5,1.1,0.8,1.7c-11.7,12.8-12.1,15.9-3.4,27.6c-7.1,14-7.1,20.5,0.5,35.8c3.2,0,6.9,0,12,0c-2.4,2.3-3.6,3.3-4.7,4.5
        c-2.9,3-8.9,4.8-6.9,9.7c1.2,3.1,6.2,4.7,9.9,7.3c-2,2.1-5,4.3-6.8,7.3c-1.1,1.8-1.6,5.8-0.5,6.9c4.4,4.2,5.1,8.3,3.6,14.2
        c-1.5,6.2,3.5,9.2,9.4,6c3.2-1.7,6-4.1,8.9-6c14.4,8.4,20.1,9.6,32.2,6.4c1.7-9.5,1.4-10.6-5.4-17.7c-1.8,1.2-3.5,3-5.6,3.7
        c-1.9,0.7-4.5,0.8-6.2-0.1c-0.8-0.4-0.9-3.6-0.5-5.3c1.2-5.1,5.3-7.5,9.7-9.3c3.9-1.6,5.2-4.2,5.6-8.4c3.6-36.5,4.3-73.1,1.9-109.7
        c-0.1-1-0.1-2-0.4-3c-1.4-5.5-5-6.9-9.6-3.7c-2.2,1.5-3.9,3.9-6.3,4.8c-2.1,0.8-5.9,1-7-0.3c-2.4-2.6-0.9-5.8,2-7.5
        c3.7-2.2,7.5-4.3,11.6-5.8c5.5-2,7.9-5.5,7.3-11.3c-1.8-15.9-3.9-31.7-5.3-47.6c-0.6-7.4-2.8-12.9-10.4-14.5
        c0.4-4.3,5.3-8.2-0.5-12.2c-3.1,1.3-6.3,2.7-11.5,4.9c8.6-12.6,22.4-17.1,30-29.1c-2.9-0.6-4.3-1.3-5.4-1
        C851.7,343.1,846.7,344.6,841.6,346z M772.1,334.3c-14.9-2.6-25,5.7-36.4,14.8c2.1-6.2,3.5-10.3,5-14.4c-8.6-3.8-15.8-3-23.3,1.2
        c-0.7,10.5,0.1,20.7,4.3,32.1c4.4-4.8,7.4-8.2,11.4-12.6c0,6.7,0,11.5,0,16.3c16.4,3.1,22.7-1,26.8-16.1c1-3.5,3.6-6.9,6.1-9.7
        C768.9,342.7,772.1,339.9,772.1,334.3z M936.5,340.6c-9.8,6.6-16.8,18.5-33,14.1c4.9-3.4,8.6-5.5,11.6-8.4c1.6-1.5,1.8-4.4,2.6-6.6
        c-2.1-0.4-4.6-1.7-6.2-1c-15.2,6.3-27.4,16.8-39.3,27.6c5.5,7.5,11.3,9.4,19.1,7.1c7.3-2.1,14.9-3.4,22.4-4.7c2.4-0.4,5,0.3,7.5,0.8
        c3.7,0.8,7.4,2.4,11.1,2.5c2.6,0.1,6.8-1.3,7.4-3c0.8-2.4-1.1-5.7-1.9-8.7c-4.3,1.2-7.2,2-10.2,2.9c2.8-3.9,6.4-6.3,9.6-9.1
        C942.6,349.6,942.5,347.3,936.5,340.6z M895.9,339.7c-20.3-5.8-28.2-0.6-36.1,21.7C873.7,356.8,884,349,895.9,339.7z"/>
      <path d="M2617.1,281.7c18.5,4,35.6,7.7,53.3,11.5c4.3,13.9,5.1,27.6,1.5,41.4c-10.1,38.7-14.8,78-11.9,117.8
        c2,27.8,6.7,55.5,10.3,83.2c1.5,11.2,3.6,22.4,5,33.6c1.3,11,5.6,19.1,17.5,21.1c5.1,0.9,8.8,4,9,9.3c0.3,8.9,0.4,17.9-0.5,26.8
        c-0.7,6.3-3.3,7.5-9.8,6.3c-16.7-3.2-33.5-5.9-49.9-10c-8.2-2.1-16.1-6.3-23.4-10.7c-8.3-5.1-13.5-13.2-14.2-23
        c-1.3-19.2-2.5-38.5-2.5-57.8c-0.1-46.6,0-93.3,0.8-139.9c0.5-31.7,3.4-63.2,9.6-94.3C2612.8,291.8,2615.2,287.1,2617.1,281.7z
         M2625.5,395.9c3.2-4.2,7-8.1,9.4-12.7c2.5-5,5.5-8.7,10.7-11.2c7.7-3.7,10.3-9.8,9-20.2c-6.9,4.2-13.3,8.1-19.3,11.8
        c-1.3-2.2-2.2-4.9-4-6.2c-2.2-1.6-5.3-1.9-9.1-3.2c2.6-3.3,4.2-5.6,6.1-7.8c5.3-6,10.7-11.8,16-17.8c1.9-2.2,4.7-5,1.4-7.6
        c-1.3-1-5.1-0.2-6.9,1c-6.4,4.2-12.3,9.2-18.7,13.4c-4.6,3-7.2,6.8-7.8,12.1c-0.6,4.6-1.3,9.3-1.1,13.9c0.5,11.5,3.8,13.7,14.8,11
        c0.3-0.1,0.9,0.7,2.4,2.1c-3.6,3.7-7,7.6-10.7,11.1c-5.2,4.8-5.7,6.5-2.3,14.7c3.5-1.6,6.9-3.1,10.4-4.7c-0.3,3-0.7,5.9-0.7,8.9
        c0.1,3.6-0.8,6.3-4.3,8.2c-10.7,6-13.2,15.9-12.4,27.3c0.5,7.6,0.5,15.3,1,22.9c0.4,6.6,1,13.3,1.7,19.9c0.1,0.9,0.9,1.7,1.3,2.6
        c2.9,0.4,5.7,0.9,8.8,1.3c-0.7,5.9-4.7,8.3-8.3,11.2c-4.8,3.9-5,5.9-1.5,10.9c0.9,1.4,2,2.6,3.2,3.8c3.6,3.7,3.5,7.7,0.8,11.8
        c-0.9,1.4-1.8,2.8-2.7,4.2c-3.9,6.7-4.5,13.3,1.6,19.1c4.1,3.9,4.4,7.8,1.6,12.5c-1.2,2-2.2,4.2-2.8,6.4c-1.3,4.3-4.1,9.2-3,13
        c2.2,8,4.2,16.7,11,22.1c6.3-1.3,9.5-7.5,15.6-6.8c0.5,1.2,1.2,2.1,1,2.3c-3.4,3.9-6.9,7.7-10.4,11.6c7.3,5.6,9.3,5.7,15.8,1.4
        c3.9-2.6,7.1-6.6,12.3-7.9c-0.6,6.2-1.1,11.6-1.7,18.7c8.3-2.7,14.8-4.8,21.1-6.8c9.8,7.5,13.3,8.4,16.6,4.5c4.4-5.2,3.9-12.8-2-19
        c-4.2,3.5-8.5,7.1-13.1,10.9c-2.7-7.3-3.4-17.1-16.9-12.6c1.7-6.8,2.8-12.2,4.5-17.5c3.8-11.7,3.5-12.2-8.6-13.2
        c-0.4,0-0.7-0.8-1.6-2c12.1-3.3,9.7-12.2,8.3-20.8c-0.3-2-0.9-3.9-1.4-5.8c-4.1-18.6-7.8-37.2-7.1-56.4c0.1-3.9-0.5-8-1.8-11.8
        c-0.9-2.8-3.3-5.1-5.5-8.2c-6.8,6.3-12.6,11.6-20,18.5c1.8-5.6,2.8-8.9,3.9-12.3c-2.8-1.8-5-3.3-8-5.3c7.4-2.5,13.8-4.3,19.9-7
        c7.2-3.2,8.6-7.1,5.7-14.4c-2.6-6.2-3.4-12.4-0.7-18.4c2-4.5,1.6-8.4-1-12.2c-2.7-3.9-1.9-7.4,0.3-11.2c1.8-3.2,3.9-6.4,4.8-9.9
        c0.8-3.2,0.2-6.7,0.2-12.4c-6.4,4.7-11.1,8.2-15.9,11.5C2632.2,391.8,2628.8,393.8,2625.5,395.9z M2634.9,347c0.5,0.8,1,1.7,1.5,2.5
        c5.8-2.1,11.9-3.6,17.3-6.5c3.3-1.7,6.4-5.1,7.8-8.5c3.9-9.4,3.2-19.6,2-29.5c-0.6-5.3-5.2-6.8-9.6-3.5c-3.1,2.3-5.5,5.4-8.5,8.4
        c4,3.4,7.2,6.2,10.9,9.4C2648.9,328.7,2641.9,337.8,2634.9,347z M2617.4,328.6c3.1-1.7,6.5-2.8,9.1-5.1c4.2-3.7,8-7.9,11.8-12.1
        c3.9-4.3,7.2-9.3,4-15.2c-2.8-5.1-8.6-4.2-13.4-4.3c-5.8-0.2-8.7,2.5-7,7.9c1.7,5.7-0.4,9.5-2.8,13.9
        C2616.7,317.9,2613.7,322.3,2617.4,328.6z"/>
      <path d="M1906.1,620.9c-0.6-4.6-1.6-9.2-1.8-13.8c-2.2-53.6-4.7-107.2-6.3-160.8c-0.8-25.3,0.4-50.7,0.2-76
        c-0.1-12.6-1.1-25.3-2-37.9c-0.5-7.3,0.7-10.5,7.6-11.5c13.8-2.2,27.7-3.7,41.7-4.7c6.7-0.5,9,2.6,8.5,10.4
        c-0.8,13.3-2,26.6-3.3,39.8c-4.3,44-4.6,87.8,3.1,131.5c3.1,17.4,5.1,34.9,7.7,52.4c0.4,2.6,0.9,5.3,1.5,7.8
        c2.7,11.8,5.4,14.2,17.8,14.4c23.3,0.4,46.7,0.8,70,0.2c8.1-0.2,16.2-3,24.3-4.8c2.5-0.6,4.9-1.6,8.6-2.8c0.4,7.1,0.9,13.3,1,19.5
        c0.1,8.3,0.1,16.7-0.5,25c-0.8,11.2-3.5,13.6-15.1,13.8c-13.7,0.3-27.3,0.5-41,0.3c-17.3-0.3-34.6-1.5-51.9-1.6
        c-8.6,0-17.3,0.9-25.7,2.7C1935.1,628.3,1920.7,627.3,1906.1,620.9z M1938.8,508.6c1.5-5.6,3-10.3,3.8-15.1
        c1.3-7.2-6.3-7.5-9.9-11.4c2.4-3.9,4.7-7.5,6.9-11.1c1.6-2.7,2.9-5.5,0-8.1c-3.2-2.7-6.5-1.6-8.9,1c-2.7,2.9-4.6,6.5-7.2,9.5
        c-0.9,1-2.8,1.1-4.3,1.6c-0.4-0.6-0.8-1.1-1.2-1.7c4.5-5.2,9-10.3,13.4-15.6c5-6,8.4-12.4,8.8-20.6c1.2-26.9,3.2-53.8,4.6-80.8
        c0.4-7.6,0.1-15.3-0.2-22.9c-0.2-4.9-2.9-8.9-7.7-9c-8.6-0.1-17.3,0.6-25.8,1.7c-3.5,0.4-6,3.6-5,7.4c1.1,4.1,4.6,2.8,7.5,2.1
        c0.6-0.2,1.2-0.5,1.9-0.6c4.1-1,4.1,1.7,4.6,4.7c-5.9,2-11.3,4.3-14.8,11.3c2.8,2.3,5.5,4.5,8.3,6.8c-3,2.6-5.4,4.8-8,7
        c1.8,8,5,15.6,1.3,23.7c-0.7,1.4,1.5,4.1,2.2,6.2c1,3.1,2.8,6.3,2.5,9.3c-0.4,3.8-2.3,7.5-4,11c-3.1,6.4-3.2,6.7,2.6,11.5
        c1,0.8,2.3,1.2,3.2,1.7c-10.2,14.7-10.2,14.7,1.3,19.3c2.2,5.4-0.9,9.6-3.3,13.9c-6.5,11.5-6.3,22.8,1,34c1.6,2.5,3.1,5,4.5,7.4
        c-10.9,14-10.9,14-4.3,24.5c2.3-0.1,4.8-0.3,7.7-0.4c-0.5,1.6-0.6,2.7-1.2,3.4c-1.5,1.8-3.1,3.5-4.7,5.1c-5.2,5.1-5.7,7.9-2.2,14
        c1.3,2.3,3,4.3,4.4,6.2c-0.4,1.4-0.6,2.4-1.1,3.3c-6.2,12.6-5.5,13.9,8.8,16.3c-3.7,4.1-7.2,7.9-10.6,11.9c-2.2,2.6-2.4,5.5-0.1,8.2
        c2.7,3.1,5.4,1.7,7.9-0.1c2.2-1.6,4-3.5,6.2-5.1c2.2-1.6,4.6-3.1,7.4-4.9c2.3,6.2-0.7,8.9-4,11.1c-4.4,3-9.1,5.6-13.4,8.7
        c-5.3,3.8-6.4,7.8-3.7,11.8c2.7,4,6.7,4.7,12.4,2c2.9-1.4,5.8-3,8.5-4.5c5.9,6.7,5.9,6.7,13.6,4.2c4.1-1.4,8.2-2.7,12.2-4.4
        c5.1-2.2,9.5-2.6,15,0c9.9,4.6,17.5,2,24.4-6.4c0.8-1,1.8-1.8,2.9-2.8c0.7,1.2,1.4,2,1.7,2.9c2.6,8,7.4,10.7,14.6,7.7
        c3-1.3,5.5-3.7,8.1-5.7c3.5-2.7,6.9-5.6,11-8.9c-0.4,5.7-0.7,9.8-1.2,15.4c10.8,0,20.4,0.4,30-0.1c7-0.3,9-2.3,9.1-9.3
        c0.1-9.6-0.8-19.1-1.2-28.3c-11.2-5.9-16.9,1.7-23.2,7.5c-4.2-9.7-7.5-11.8-16.9-8c-7.6,3.1-14.5,2.7-22.2,0.6
        c-16-4.2-31.7-3.3-46.5,4.9c-1.1,0.6-2.4,0.9-4,1.4c-2.3-4.3-4.5-8.3-6.5-12.1c-3.5-0.6-6.6-1.1-10.7-1.7c1.9-2.3,2.7-3.8,3.9-4.7
        c5.2-4,5.2-8.3,2.1-13.9c-1.7-3.2-2-7.6-1.6-11.3c0.4-3.9-0.3-6.5-3.3-8.6c-5-3.4-4.3-7.4-1.9-12.2
        C1948.5,511.8,1947.8,510.8,1938.8,508.6z"/>
      <path d="M2173.4,396.4c5.4,7.1,5.5,14.6,5.6,21.8c0.3,23,1.3,46-0.1,68.9c-2.3,37.1-1.3,73.8,3.7,110.6c1.3,9.6,1.4,19.2-1.5,29.5
        c-19.6,4.8-39.3,3.1-60.4,1.8c-0.2-4.9-0.7-9.4-0.3-13.9c2.2-24.5,4.8-49,6.8-73.6c0.7-8.9,0.5-17.9,0.5-26.9c0-11-1.2-22-0.4-32.9
        c1.7-23,0.1-45.9-2.2-68.8c-0.9-9.2-0.2-11.3,8.4-14c4.7-1.5,9.7-2.2,14.7-2.4C2156.6,396.1,2165.2,396.4,2173.4,396.4z M2168.9,497
        c-12.6-1.7-13.4-1.7-17,6.8c-1.7,4.1-4.4,5.2-8.2,5.9c-7,1.4-9,4-8.5,10.9c0.2,2.8,1.5,5.5,2.3,8.5c4.3-0.8,7.6-1.4,10.9-2
        c0.3,0.5,0.7,1.1,1,1.6c-4.6,5.8-9.3,11.5-14.1,17.5c2.3,2.5,4.5,4.8,6.1,6.6c-0.4,10.2-13,17.8-4.3,29.1c4-1.4,7.3-2.5,11.4-4
        c-4.1,10.3-14.6,15.6-16.1,27c3.6,0.2,6,0.3,8.7,0.4c-1.2,3.1-2.1,5.5-3,7.7c4.8,6.2,10.3-0.2,15.1,1.6c8.2,9.5,9.4,9.6,20.9,1.9
        c-1.3-7.3-2.7-14.8-4.3-23.2c-8.8,3.3-16.1,6-24.5,9.1c4.4-9,6.3-10.7,14.6-14.6c2.7-1.3,6-2.4,7.4-4.6c1.5-2.4,1.9-6.2,1.2-9.1
        c-0.8-3.7-4.4-3.3-7.5-2.6c-1.6,0.4-3.3,0.7-6.7,1.4c4.8-8.2,8.5-14.8,12.6-21.2c2.7-4.4,5-8.6,1.9-13.8c-3.1-0.3-5.9-0.6-10.3-1.1
        c4.1-3.7,7.3-6.4,10.2-9.4c3-3.2,3.3-6.5-1-9c-1.3-0.7-2.5-1.5-2.9-1.8C2166.4,509.6,2167.6,503.7,2168.9,497z M2136.6,503.4
        c6.5-3.5,10.4-6.2,14.7-7.9c11.6-4.6,15.9-14.4,18.9-25.3c1.1-3.9,0.1-6.8-3.5-9.4c-6.1-4.5-6-4.8-1.1-10.4c2.9-3.3,6.6-7.1,4-11.3
        c-3.8-6.2-3-11.8-1.2-18.1c1.6-5.7,0.6-6.9-5.3-7.8c-1.5-0.2-3.1,0-5.1,0c0.4-6.1-2.8-8.4-7.8-8.5c-4,0-8-0.1-11.9,0.7
        c-5.8,1.2-8,5.2-5.3,10.2c2,3.6,5.8,6.2,9.8,10.1c-0.1,0.2-0.4,2.2-1.3,3.9c-1.2,2.3-2.9,4.4-4.4,6.6c-4,5.8-3.4,9.1,2.2,13.4
        c2,1.5,3.8,3.1,5.9,4.9c-3.6,4.7-6.6,8.6-9.7,12.6c3.7,7.9,5,15.3,0.8,23.3C2134.9,493.3,2136.4,497.8,2136.6,503.4z"/>
      <path d="M1776,629.2c-4.9-13.8-4.5-27-3.4-40.3c0.4-5.3,1.4-10.5,2.5-15.7c1.6-7.8,2.5-8.8,10.5-8.8c12.3-0.1,24.6,0.4,36.8,0.7
        c4.6,0.1,9.3,0.4,13.9,1c6.2,0.8,9.2,3.5,9,9.7c-0.3,12.3-1.1,24.5-2,36.8c-0.4,5.6-3.8,9.4-9.3,10.3
        C1815.1,626.3,1796.1,629.3,1776,629.2z M1797.1,586.3c0.5,0.7,1,1.3,1.6,2c-1.8,2.1-3.5,4.5-5.6,6.1c-2.6,2-5.9,3.2-8.5,5.1
        c-5.2,3.8-5.7,7-1.6,11.6c5.5,6.2,11.9,11.3,20.8,8.9c7.3-1.9,14.7-4.3,21.2-7.9c7.7-4.2,8.9-8.3,6.4-16.8
        c-1.6-5.5-2.8-10.1,2.7-14.2c1.3-1,2-4.5,1.2-6c-0.8-1.5-4-3.2-5.6-2.8c-8.2,2.1-15.8-1-23.6-1.9c-6.5-0.8-13.3-0.2-19.9,0.3
        c-5.1,0.4-7.7,3.8-7.4,8.9c0.1,2.8,1.1,5.6,1.7,8.9C1786.8,587.7,1791.9,587,1797.1,586.3z"/>
      <path d="M977.9,629.3c-8-21.6-3.5-42.1,0-63.5c3.9-0.5,7.4-1.3,10.9-1.2c15.6,0.3,31.3,0.8,46.9,1.5c8.3,0.4,11.4,3.7,11.1,12
        c-0.3,10.6-0.9,21.3-1.6,31.9c-0.5,7.9-3.1,11.7-10,13C1016.6,626.4,997.9,629.2,977.9,629.3z M999.8,586.2
        c-0.5,6.1-4.1,7.8-7.6,9.8c-3.1,1.8-6,4.1-8.5,6.7c-1.1,1.2-1.6,3.7-1.3,5.5c0.8,4.5,9.9,12.6,14.5,12.5
        c13.6-0.5,26.5-3.3,35.9-14.5c2.9-3.4,4.1-7,0.9-10.8c-3.9-4.6-3.8-8.9,0.8-13c2.2-2,5.2-4.6,2.6-7.5c-1.6-1.8-5.1-2.1-7.9-2.3
        c-4.2-0.3-9.1,1.3-12.6-0.2c-9.9-4.3-19.7-1.9-29.6-1.3c-4.9,0.3-7,3.8-6.8,8.3c0.1,2.9,1.2,5.7,2.1,9.3
        C988.2,587.8,993.7,587,999.8,586.2z"/>
      <path d="M2177.2,353.5c-15.7,3.6-30.7,3.2-46.8,0c-0.6-3.4-1.7-6.9-1.8-10.4c-0.3-11-0.2-22-0.4-32.9c-0.1-4.9,2-7.7,6.9-7.7
        c10.3-0.1,20.6-0.3,30.9,0.3c2.7,0.2,5.9,2.9,7.6,5.4c2,2.9,3.4,6.7,3.5,10.2C2177.4,329.8,2177.2,341.4,2177.2,353.5z M2153.1,347
        c0,0.1,0,0.2,0,0.3c2.9-0.3,6-0.3,8.8-1c4.1-1.1,6.3-5.4,5.7-10.2c-0.5-4.4-3-8.4-7.4-7.6c-5.7,1-11.4,3.3-16.3,6.2
        c-2.1,1.2-3.4,5.8-2.9,8.4c0.3,1.7,4.5,2.9,7.1,3.8C2149.6,347.4,2151.4,347,2153.1,347z M2151.6,324.3c4.7-0.5,8.2-0.4,11.4-1.3
        c3.3-1,5.5-3.7,5.1-7.6c-0.5-4.1-3.6-5.3-7-5.6c-5.6-0.5-11.1-1.1-16.7-1.1c-7.3,0-10.1,4.5-7.3,11.2c0.9,2.2,2.7,4.1,4.7,7.1
        c3.4-5.8,4.5-12.2,11.4-14.4C2152.7,316.2,2152.3,319,2151.6,324.3z"/>
      <path d="M2913.9,615c-4.9-4.9-4.6-8.7-3-12.6c2.8-7,0.7-13.9-1.5-20.5c-0.9-2.7-4.4-4.4-7.4-7.2c1.7-1.6,3.2-3.8,5.3-4.9
        c5.7-3.1,11.3-0.6,11.6,5.8c0.6,10.6,0.4,21.3-0.1,31.9C2918.7,609.8,2916,612,2913.9,615z"/>
      <path d="M1720.1,614.6c-3.7-3.5-6.1-5.7-8.8-8.3c2.7-4.9,8.1-9.1,5-16.5c-2.2-5.3-1.3-12.2-11.2-14c4-3,6.1-5,8.5-6.1
        c4.5-2.1,8.8-0.5,9.1,4.2c0.6,12.2,0.2,24.5-0.1,36.7C1722.6,611.7,1721.3,612.8,1720.1,614.6z"/>
      <path d="M2854.5,590.3c0.9,3.1,1,5.7-2.2,7.8c-2.4,1.5-4.5,3.5-7,5.5c5.1,3.1,5.1,3.1,15.2,1.2c-3,6-7.2,10.7-13.9,8.6
        c-7.5-2.4-13.5-0.8-19.8,4.6c-1.4-8.3,2.7-12.6,8.2-15.8c4.9-2.8,10.2-4.7,15.2-7.4C2851.9,593.8,2853.1,591.8,2854.5,590.3
        C2854.5,590.3,2854.5,590.3,2854.5,590.3z"/>
      <path d="M1608.1,584.2c-1.8,6,2.2,7.5,7.5,8.7c-7.8,6.4-16.1,7.4-25.2,6.3c1.3-11.5,5.8-15.1,18-14.7
        C1608.4,584.5,1608.1,584.2,1608.1,584.2z"/>
      <path d="M2800.7,583c2.2,4.2,2.5,10.1,10.9,9.4c-8.5,6.9-16.5,8-25.4,6.8c0-7.1,2.8-11.6,9.5-13.6c1.8-0.5,3.6-1.6,5.3-2.4
        C2800.9,583.1,2800.7,583,2800.7,583z"/>
      <path d="M2810.8,611.6c2.8-4.7,4.9-8.2,7.2-12c3.7,5.2,3.9,6.7,1.7,10.1C2818.1,612.2,2818.1,612.2,2810.8,611.6z"/>
      <path d="M1625.2,599.5c-0.1,12.3-1.9,14.1-9.8,10.7C1618.3,607,1621,604.1,1625.2,599.5z"/>
      <path d="M2888.9,605.1c0.9-1.6,1.7-3.2,2.7-4.8c0.1-0.2,1.6,0.3,1.6,0.4c-0.2,1.8-0.6,3.7-0.9,5.5
        C2891.1,605.9,2890,605.5,2888.9,605.1z"/>
      <path d="M1698.7,601c-0.7,1.5-1.4,3-2,4.4c-0.7-0.4-1.5-0.9-2.2-1.3c0.9-1.4,1.8-2.7,2.7-4.1C1697.6,600.4,1698.1,600.7,1698.7,601z
        "/>
      <path d="M1460,469.8c-3.9,2.7-7.7,5.5-11.6,8.2c-0.5-0.4-1-0.8-1.5-1.2c2.4-3.6,4.5-7.3,7.3-10.5c0.7-0.8,3.5,0.3,5.3,0.5
        C1459.7,467.8,1459.9,468.8,1460,469.8z"/>
      <path d="M1453.6,448.5c-3.2,1.7-5.7,5.1-8.8,1.3c-0.5-0.6-0.3-2.8,0.4-3.5C1448.2,443.2,1450.9,444.2,1453.6,448.5z"/>
      <path d="M1365.8,423.8c2.7-2.1,5.4-4.1,8.1-6.2C1372.1,423.6,1372.1,423.6,1365.8,423.8z"/>
      <path d="M1303.5,404.6c-2.3,1.4-4.6,2.8-6.9,4.3c-0.5-0.8-0.9-1.5-1.4-2.3c2.3-1.4,4.6-2.8,6.9-4.2
        C1302.6,403.1,1303,403.9,1303.5,404.6z"/>
      <path d="M1299.3,428.8c-1.2,0.9-2.3,1.8-3.5,2.7c-0.4-0.3-0.7-0.7-1.1-1c1-1.1,1.9-2.2,2.9-3.3
        C1298.2,427.6,1298.8,428.2,1299.3,428.8z"/>
      <path d="M2267.2,463.3c1.7-1,3.3-2,5-2.8c0.1,0,1.4,2,1.3,2.1c-1.4,1.3-2.9,2.3-4.4,3.5C2268.5,465.1,2267.8,464.2,2267.2,463.3z"/>
      <path d="M1119.4,463.5c1.6-1,3.2-2.1,4.9-3.1c0.5,1,0.9,2.1,1.4,3.1c-1.8,0.7-3.5,1.5-5.3,2.2
        C1120.1,464.9,1119.8,464.2,1119.4,463.5z"/>
      <path d="M1594.6,437c-4.4,2.9-8.8,5.9-15.2,3.9c4.9-3.8,9.2-7.3,15.3-3.7C1594.8,437.2,1594.6,437,1594.6,437z"/>
      <path d="M1694,458.6c0.3,1.5,0.7,3,0.9,4.5c0,0.1-1.5,0.6-1.6,0.5c-0.7-1.4-1.2-2.8-1.8-4.2C1692.3,459.1,1693.1,458.8,1694,458.6z"
        />
      <path d="M2888.2,458.1c1,1.6,2,3.2,3,4.7c-1.1,0.5-2.2,1-3.3,1.5c-0.7-1.7-1.4-3.3-2.1-5C2886.6,458.9,2887.4,458.5,2888.2,458.1z"
        />
      <path d="M809.6,517.4c7.6-7.8,14.9-15.8,22.9-23.1c2.3-2.1,6.6-1.9,11.4-3.1c-2,6.1-6.3,7.5-9.2,10.3c-3.3,3.1-6.7,6.2-9.3,9.9
        c-3.7,5.4-8.4,7.9-14.4,8.3C810.6,519,810.1,518.2,809.6,517.4z"/>
      <path d="M828,453.4c-3.5-2.6-4-5.4-0.7-7.5c6-3.9,12.3-7.4,20.3-12.1C844.5,446.4,836.8,450.5,828,453.4z"/>
      <path d="M826.9,565.2c1.7-3.3,2.3-6.4,4.2-7.8c4.9-3.7,10.2-7,17.2-6.3C844.6,560.3,841.7,562.2,826.9,565.2z"/>
      <path d="M854.9,506.8c-2.6,7.4-8.9,9.1-14,11.9c-3.4-1.5-4-4-2.3-6.5C842.2,506.8,847.2,504.6,854.9,506.8z"/>
      <path d="M845.1,573.4c-4.3,11.3-7.7,12.4-15.7,5.7C834.4,577.3,838.7,575.8,845.1,573.4z"/>
      <path d="M845.2,533.8c-1.7,6.2-5.8,9-11.6,9.9C831.7,536.3,832.8,535.4,845.2,533.8z"/>
      <path d="M841.2,397.9c-0.3,5.2-3.5,6.2-7.8,5.8C832.5,397,833.1,396.5,841.2,397.9z"/>
      <path d="M819.2,531c2.4,4.5,3.6,8.4-4.7,9.4C816.5,536.3,817.8,533.6,819.2,531z"/>
      <path d="M809.9,450.2c2.2-3.9,3.7-6.8,9-5C817.4,449.6,814.8,450.8,809.9,450.2z"/>
      <path d="M2646.5,546.6c-0.3,7.9-0.3,7.9-9,9.7C2638.7,551.1,2640.9,547.5,2646.5,546.6z"/>
      <path d="M2620.2,432.8c-0.4-4.1,1.6-6.2,5.6-5.8c0.7,0.1,1.7,1.9,1.7,3C2627.6,432.9,2626.6,433.2,2620.2,432.8z"/>
      <path d="M2637.4,512.5c3.2,3.1,3.2,3.1,1.2,7.6c-0.9-1.6-2-2.8-2.3-4.1C2636.2,515,2637,513.9,2637.4,512.5z"/>
      <path d="M2639,489.7c-2,1.8-4.1,3.7-6.7,6.2C2631.5,489.9,2634.1,488.5,2639,489.7z"/>
      <path d="M1933.4,334c0,6.9-3.4,7.5-8.8,6.5C1925.8,336.1,1927.9,333.9,1933.4,334z"/>
      <path d="M2154.2,522c1.9-1.2,3.9-2.4,5.8-3.5c0.3,0.5,0.6,0.9,0.9,1.4c-1.8,1.3-3.5,2.6-5.3,3.9
        C2155.2,523.2,2154.7,522.6,2154.2,522z"/>
      <path d="M1802.1,603.8c2.9-1.4,5.8-2.7,10.3-4.9C1808.8,604.9,1808.8,604.9,1802.1,603.8z"/>
      <path d="M1796.2,609.9c-1.5,1.1-3.1,2.3-4.6,3.4c-0.3-0.4-0.6-0.7-0.9-1.1c1.4-1.3,2.8-2.6,4.1-3.8
        C1795.3,609,1795.8,609.4,1796.2,609.9z"/>
      <path d="M1015.5,601.1c-6.8,3.7-13.6,7.4-20.3,11.1c-0.5-0.8-0.9-1.7-1.4-2.5c6.7-3.9,13.5-7.9,20.2-11.8
        C1014.5,599,1015,600,1015.5,601.1z"/>
      </svg>
    </div>
  );
}

export default Logo;

import React, { FC } from 'react';
import { FormSection } from 'redux-form';
import cn from 'classnames';

import { Input, PhoneInput, InstagramUrl } from 'common/ui/FormElements'
import { IUser } from './User.interface';
import theme from './User.module.scss';

const User: FC<IUser> = ({ 
  className,
}) =>  {
  return(
    <div className={ cn(theme.root, className) }>
      <FormSection name="user">
        <PhoneInput name="phone" label="Телефон" />
        <Input name="lastName" label="Прізвище"/>
        <Input name="firstName" label="Ім'я"/>
        <Input name="email" label="Email" type="email" />
        <InstagramUrl name="socialUrl" label="Instagram URL" />
      </FormSection>
    </div>
  )
}

export default User;

import React, { FC } from 'react';
import cn from 'classnames';

import { Product } from 'components/application/sections';

import * as hooks from './hooks';
import * as utils from './utils';
import { IRecomendations } from './Recomendations.interface';
import theme from './Recomendations.module.scss';

const Recomendations: FC<IRecomendations> = ({
  className,
  title = "Наші рекомендації",
  productId,
}) => {
  const { selectors } = hooks.useRecomendations(title, productId);

  return (
    <div className={ cn(theme.root, className) }>
      <div className={ theme.title }>
        { selectors.title }
      </div>
      <div className={ theme.products }>
	      { utils.prepare.products(selectors.data).map((product: any, index: number) => (
	      	<Product { ...product } key={ index } />
	      )) }
      </div>
    </div>
  );
}

export default Recomendations;

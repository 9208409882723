import { all } from 'redux-saga/effects';
import {
  usersFetchFlow,
  usersTableFetchFlow,
  usersTableSearchFlow
} from './table.saga';

import {
  userFormFetchFlow,
  userFormCreateFlow,
  userFormUpdateFlow
} from './form.saga';


export function* usersFlow() {
  yield all([
    usersFetchFlow(),
    usersTableFetchFlow(),
    usersTableSearchFlow(),
    userFormFetchFlow(),
    userFormCreateFlow(),
    userFormUpdateFlow()
  ]);
}

import React, { FC } from 'react';
import classNames from 'classnames';
import { isArray, isString } from 'lodash'

import { Icon } from 'common/ui';
import { AddToListProps } from './AddToList.interface';

import theme from './AddToList.module.scss';

const AddToList: FC<AddToListProps> = ({
  className,
  id,
  input,
  label,
  meta: { touched, error },
  onClick
}) => {
  const showError = touched && isArray(error) && isString(error[0])

  return(
  <div className={ classNames({
    [theme.error]: showError,
  }, theme.root, className) }
  onClick={ onClick }
  >
    <Icon name="add" className={ theme.icon }/>
    <span className={theme.text}>{label}</span>

    { showError && <div className={ theme.errorMessage }> { error }</div> }
  </div>
);
 }

export default AddToList;

import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import { Icon } from 'common/ui';

import { IDrawer } from './Drawer.interface';
import theme from './Drawer.module.scss';

const Drawer: FC<IDrawer> = ({ 
  isOpened,
  onBackdropClick,
  children,
  className,
  property = 'right',
}) => {
  return createPortal(
    <div 
      className={ cn({
        [theme.isOpened]: isOpened,
        },
        theme.root,
        className,
      )}
    >
      <div
        className={ theme.backdrop }
        onClick={ () => onBackdropClick(false) }
      />

      <div className={ cn(theme.paper, theme[property]) }>
        { children }
      </div>
    </div>,
    document.body
  )
};

export default Drawer;

import { call, put, takeLatest } from 'redux-saga/effects'
import { get } from 'common/utils/api';

import * as types from 'reducers/both/novaPosta/novaPosta.constants';

function* hundleFetch(action: any) {
  try {
      const response = yield call(get, 'clients/nova_posta/cities', action.payload)
      if (response.success) {
        yield put({ type: types.CITY_FETCH_SUCCESS, payload: { data: response.data } })
      } else {
        yield put({ type: types.CITY_FETCH_ERROR, errors: response.errors })
      }
   } catch (error) {
      yield put({ type: types.CITY_FETCH_ERROR, error })
   }
}

export function* fetch() {
  yield takeLatest(types.FETCHING_CITIES, hundleFetch);
}

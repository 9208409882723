import { store } from 'store/indexApplication';
import { productsActions, articlesActions } from '../';

import * as types from './filters.constants';

export const refreshRelatedData = (type: string = 'product') => {
  switch (type) {
    case 'product':
      productsActions.filterChange();
      break;
    case 'article':
      articlesActions.filterChange();
      break;
  }
}

export const fetchFilters = () => {
  store.dispatch({
    type: types.FILTERS_FETCH,
    payload: {}
  })
}

export const changeCategory = (id: number, type?: string) => {
  store.dispatch({
    type: types.FILTERS_CATEGORY_CHANGED,
    payload: { id }
  });

  refreshRelatedData(type);
}

export const changeTag = (id: number, type?: string) => {
  store.dispatch({
    type: types.FILTERS_TAG_CHANGED,
    payload: { id }
  });

  refreshRelatedData(type);
}

export const changePrices = (prices: number[]) => {
  store.dispatch({
    type: types.FILTERS_PRICE_CHANGED,
    payload: { prices }
  });

  productsActions.filterChange();
}

export const changeColors = (id: number) => {
  store.dispatch({
    type: types.FILTERS_COLOR_CHANGED,
    payload: { id }
  });

  productsActions.filterChange();
}

export const cleanFilters = (type?: string) => {
  store.dispatch({
    type: types.FILTERS_CLEAN,
  });

  refreshRelatedData(type);
}
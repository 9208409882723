import { get } from 'common/utils/api';
import { novaPostaActions } from 'reducers/both';

import * as utils from './';

export const warehouses = (store, selectors) => (query: string) => get('clients/nova_posta/warehouses', { query, city_id: selectors.cityId })
  .then(({ response: { data }}) => {
    novaPostaActions.changeWarehouses(store)(data);
    return utils.prepare.warehouses(data);
  })

export const warehouses2 = (selectors) => (query: string) => get('clients/nova_posta/warehouses', { query, city_id: selectors.cityId, postomat: true})
  .then(({ response: { data }}) => utils.prepare.warehouses(data))

export const cities = (query: string) => get('clients/nova_posta/cities', { query })
  .then(({ response: { data }}) => utils.prepare.cities(data))

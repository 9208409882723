import React, { FC } from 'react';

import { IFilters } from './Filters.interface';
import { Colors, Prices, MobileFilters } from '../';
import { 
  FilterCategories, Filters as MainFilter
} from 'components/application/sections';

const Filters: FC<IFilters> = ({
  className,
  fixFilters,  
}) => {

  return (
    <MainFilter
      className={ className }
      type="product"
      fixFilters={ fixFilters }
      mobileChild={ <MobileFilters /> }
    >
      <FilterCategories type="product" />
      <Prices />
      <Colors />
    </MainFilter>
  );
}

export default Filters;

import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { post } from 'common/utils/api'
import { store } from 'store/indexAdmin';

import {
  USERS_TABLE_FETCH,
  USERS_TABLE_FAIL,
  USERS_TABLE_SUCCESS,
  USERS_TABLE_VALUES,
  USERS_TABLE_SEARCH,
} from 'reducers/admin/users/users.constants';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* fetchUsers(action: any) {
  try {
      const response = yield call(post, 'users/all', action.payload)
      yield put({type: USERS_TABLE_SUCCESS, payload: response.data})
   } catch (error) {
      yield put({type: USERS_TABLE_FAIL, error})
   }
}

function* loadinData(payload: any = {}) {
  const response = yield call(post, 'users/all', { ...payload })

  if (response.failure) {
    yield put({type: USERS_TABLE_FAIL})
    return false
  }
  yield put({type: USERS_TABLE_SUCCESS, payload: response.data})
}

function* searchTableUsers(action: any) {
  yield call(delay, 500)
  yield call(fetchTableUsers)
}

function* fetchTableUsers(action: any) {
  const { getState } = store;
  const { usersData: { table: { values } } } = getState()
  yield put({type: USERS_TABLE_FETCH, payload: values})
}

export function* usersFetchFlow() {
  yield takeLatest(USERS_TABLE_FETCH, fetchUsers)
}

export function* usersTableFetchFlow() {
  yield takeLatest(USERS_TABLE_VALUES, fetchTableUsers)
}

export function* usersTableSearchFlow() {
  yield takeLatest(USERS_TABLE_SEARCH, searchTableUsers)
}


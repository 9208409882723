import React, { FC } from 'react';
import cn from 'classnames';

import { ICard } from './Card.interface';
import theme from './Card.module.scss';
import { FileInput } from 'common/ui/FormElements';

const Card: FC<ICard> = ({ 
  className,
}) =>  {

  return(
    <div className={ cn(theme.root, className) }>
      <div className={ theme.body }>
        <p><strong>IBAN:</strong> UA393220010000026002310000555</p>
        <p><strong>ЄДРПОУ:</strong> 3401602180</p>
        <p><strong>Отримувач:</strong> ФОП Козицька Наталя Ігорівна</p>
        <br />
        <p>В призначені платежа напишіть : <strong>ОПЛАТА ЗА ТОВАР</strong>.</p> 
        <p>Врахуйте, будь ласка, комісію.</p>
        <br />
        <p>Після оплати прикріпіть повну квитанцію про оплату***</p>
        <p>*Платежі перевіряються менеджером. У квитанції повинні міститися наступні дані: дата та час переказу, ПІБ платника, сума оплати та !номер квитанції (платежа).</p>
        <p>**Скрін обробки платежа та інші повідомлення без цих даних — не враховуватимуться за оплату)</p>
        <p>***Якщо Ваш банк не відображає Вам цих даних — скористайтеся оплатою на сайті.</p>
      </div>
      <FileInput 
        name="check"
        label="Квитанція по оплаті"
        accept=".jpg, .jpeg, .png, .pdf"
      />
    </div>
  )
}

export default Card;

import React, { FC } from 'react';

import Scrollbar from 'react-scrollbars-custom';
// import Breadcrumbs from 'components/admin/layouts/Breadcrumbs/Breadcrumbs.layout';
// import Navigation from 'components/businessProfile/layouts/Navigation/Navigation.layout';

import { PageLayoutProps } from './PageLayout.interface';
import theme from './PageLayout.module.scss';

const PageLayout: FC<PageLayoutProps> = ({ children , crumbs }) => {
  return (
    <div className={ theme.root }>
      <main className="content">
        {/*<aside className={ theme.sidebar }>*/}
          {/*<Navigation />*/}
        {/*</aside>*/}

        <section className={ theme.section }>
            <div className={ theme.sectionContent }>
              { children }
            </div>
        </section>
      </main>
    </div>
  );
};

export default PageLayout;

import React, { FC, useState } from 'react';
import classNames from 'classnames';

import { ColorInputProps } from './Input.interface';
import ColorInput from './ColorInput.layout'

const ColorInputContainer: FC<ColorInputProps> = ({
  input,
  ...props
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const onColorChange = (color: any) => {
    input.onChange(color.hex)
  }

  return(
    <ColorInput
      { ...props }
      input={ input }
      onColorChange={ onColorChange }
      openDialog={ openDialog }
      dialogChange={ setOpenDialog }
    />
  )};

export default ColorInputContainer;

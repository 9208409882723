import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { join } from 'lodash';

import * as hooks from './';

const usePrices = () => {

  const { 
    filters, prices,
  } = useSelector((state: any) => state.filters, shallowEqual);


  useEffect(() => {
  }, [join([...prices, filters.prices.min, filters.prices.max], '-')]);

  return {
    min: Math.ceil(parseFloat(filters.prices.min)) - 1,
    max: Math.ceil(parseFloat(filters.prices.max)),
  }
}

export default usePrices;

import { findKey, isObject } from 'lodash';

export const errorTab = (checks, setIndex) => (errors) => {
  if (!isObject(errors)) { return }

  const keys = Object.keys(errors).filter(key => errors[key])

  // Get error key indexes
  const indexes = keys.map(key => {
    const keyIndex = findKey(checks, array => {
      return array.includes(key)
    })

    if (keyIndex) { return parseInt(keyIndex) }
  })

  if (indexes.length) {
    const index = Math.min(...indexes)
    setIndex(index)
  }
}

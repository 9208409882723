import React, { FC, useEffect, useState } from 'react';

import * as utils from '../utils';

const setStates = (pCrop: any, input: any) => {
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState(pCrop);
  const [openDialog, setOpenDialog] = useState(false);
  const [imageFormat, setImageFormat] = useState('image/jpeg');
  const [imageName, setImageName] = useState('newFile.jpg');
  const [imageRef, setImageRef] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(input.value);
  const [blob, setBlob] = useState(null);
  const [images, setImages] = useState(utils.prepareData.images(input.value));

  return {
    src,
    setSrc,
    crop,
    setCrop,
    openDialog,
    setOpenDialog,
    imageFormat,
    setImageFormat,
    imageName,
    setImageName,
    imageRef,
    setImageRef,
    croppedImageUrl,
    setCroppedImageUrl,
    images,
    setImages,
    blob,
    setBlob,
  }
}

export default setStates;
import {
  SHOW_SUCCESS,
  SHOW_ERROR,
  HIDE_SNACKBAR
} from './snackbar.constants';
import { store } from 'store/indexAdmin';

export const showSuccess = (message: string) => {
  store.dispatch({
    type: SHOW_SUCCESS,
    payload: { message }
  });
};

export const showError = (message: string) => {
  store.dispatch({
    type: SHOW_ERROR,
    payload: { message }
  });
};

export const hideSnackbar = () => {
  store.dispatch({
    type: HIDE_SNACKBAR,
  });
};

export const ORDERS_TABLE_FETCH           = 'ORDERS/TABLE/FETCH';
export const ORDERS_TABLE_SUCCESS         = 'ORDERS/TABLE/SUCCESS';
export const ORDERS_TABLE_FAIL            = 'ORDERS/TABLE/FAIL';
export const ORDERS_TABLE_VALUES          = 'ORDERS/TABLE/VALUES';
export const ORDERS_TABLE_SEARCH          = 'ORDERS/TABLE/SEARCH';
export const ORDERS_TABLE_DATE_RANGE      = 'ORDERS/TABLE/DATE_RANGE';
export const ORDERS_TABLE_FETCH_INITIAL   = 'ORDERS/TABLE/FETCH_INITIAL';
export const ORDERS_TABLE_INITIAL_SUCCESS = 'ORDERS/TABLE/INITIAL_SUCCESS';
export const ORDERS_TABLE_CHANGE_STATUSES = 'ORDERS/TABLE/CHANGE_STATUSES';

export const ORDER_FORM_FETCH           = 'ORDERS/FORM/FETCH';
export const ORDER_FORM_SUCCESS         = 'ORDERS/FORM/SUCCESS';
export const ORDER_FORM_FAIL            = 'ORDERS/FORM/FAIL';
export const ORDER_FORM_UPDATE          = 'ORDERS/FORM/UPDATE';
export const ORDER_FORM_CREATE          = 'ORDERS/FORM/CREATE';
export const ORDER_FORM_SYNC_USER       = 'ORDERS/FORM/SYNC_USER';
export const ORDER_FORM_CHECK_USER      = 'ORDERS/FORM/CHECK_USER';
export const ORDER_FORM_FETCH_INITIAL   = 'ORDERS/FORM/FETCH_INITIAL';
export const ORDER_FORM_INITIAL_SUCCESS = 'ORDERS/FORM/INITIAL_SUCCESS';

import React, { useEffect } from 'react';
import { join } from 'lodash';

import * as hooks from './';

const useRangeSlider = (min: number, max: number, value: number[]) => {

  const states = hooks.setStates(min, max, value);

  useEffect(() => {
    states.setValue([min, max]);
  }, [join([min, max, ...value], '-')]);

  return {
    states,
  }
}

export default useRangeSlider;

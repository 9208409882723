import React, { FC } from 'react';
import cn from 'classnames';

import { HtmlText, Colors } from 'common/ui';

import { Price } from '../';
import { IShortInfo } from './ShortInfo.interface';
import theme from './ShortInfo.module.scss';

const ShortInfo: FC<IShortInfo> = ({ 
  className, 
  product,
  colorChange,
  colorId,
}) => (
  <div
    className={ cn([theme.root], className) }
  >
    <div className={ theme.title }>{ product.label }</div>
    <HtmlText className={ theme.desctop } html={ product.shortInfo } />
    <Colors 
      className={ theme.colors }
      onChange={ colorChange }
      colors={ product.colors }
      onChange={ colorChange }
      selected={ [colorId] }
      title="Колір"
    />
    <HtmlText className={ theme.mobile } html={ product.shortInfo } />
    <Price product={product} colorId={ colorId } />
  </div>
);

export default ShortInfo;
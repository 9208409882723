import React, { FC } from 'react';
import cn from 'classnames';
import { join } from 'lodash';

import { IPicture } from './Picture.interface';
import theme from './Picture.module.scss';

interface ISource {
  type: string;
  original: string;
  sizes: Array<any>;
  versions: any;
}

const Source: FC<ISource> = ({
  type,
  sizes,
  original,
  versions,
}) => {
  if (!versions[type]) { return null }
  let srcset: string[] = sizes.map((size: any) => {
    return `${versions[type][size.picture]} ${size.picture}w`
  })

  srcset.push(`${versions[type][original]} ${original}w`);


  // @ts-ignore
  let sSizes: string[] = sizes.map((size: any) => {
    return `(max-width: ${size.screen}px) ${size.picture}px`;
  })

  sSizes.push('100vw')
  return (
    <source
      srcSet={ join(srcset, ', ') }
      sizes={ join(sSizes, ', ') }
      type={ `image/${type}` }
    />
  )
}

const Picture: FC<IPicture> = ({ 
  className ,
  alt = '',
  url,
  versions = [],
  sizes = [],
  original
}) => {


  return(
    <picture className={ cn(theme.root, className) }>
      <Source type="webp" sizes={ sizes } original={ original } versions={ versions } />
      <Source type="jpeg" sizes={ sizes } original={ original } versions={ versions } />
      <img src={ url } alt={alt}/>
    </picture>
)
};

export default Picture;
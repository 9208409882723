export const initialState = {
  user: {
    firstName: '',
    lastName: ''
  },
  loading: false,
  error: ''
}

export const defaultState = {
  loading: false,
  error: ''
}

import React, { FC } from 'react';
import classNames from 'classnames';

import { articlesActions } from 'reducers/application';

import * as hooks from './hooks';
import * as utils from './utils';
import { RadioCheck, InputSearch } from 'common/ui';
import { ISorting } from './Sorting.interface';
import theme from './Sorting.module.scss';

const Sorting: FC<ISorting> = ({
  className,
  type,
  params,
}) => {

  const { search, actions, sorting } = hooks.useSorting(type);

  return (
    <div className={ classNames(
        [theme.root],
        className,
      )}
    >
      <div className={ theme.row } >
        <InputSearch 
          onChange={ actions.search }
          value={ search }
        />
        <RadioCheck
          label="Спочатку показати"
          name="sortingType"
          params={ utils.prepare.params(params, sorting) }
          onChange={ (e) => actions.changeSorting(e.target.value) }
         /> 
      </div>
      <div className={ classNames([theme.row], [theme.line]) } />
    </div>
  );
}

export default Sorting;

import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import { products } from './application/products/index.reducer';
import { articles } from './application/articles/index.reducer';
import { filters } from './application/filters/index.reducer';
import { order } from './application/order/index.reducer';
import { basket } from './application/basket/index.reducer';
import { recomendations } from './application/recomendations/index.reducer';
import { metaTags } from './application/metaTags/index.reducer';
import { question } from './application/question/index.reducer';
import { promocodes } from './application/promocodes/reducer';

import { novaPosta } from './both/novaPosta/index.reducer';

export const rootApplicationReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  form,
  products,
  articles,
  filters,
  order,
  basket,
  novaPosta,
  recomendations,
  metaTags,
  question,
  promocodes,
});

import React, { FC } from 'react';
import cn from 'classnames';

import { IconButton } from 'common/ui';
import { ICounter } from './Counter.interface';

import theme from './Counter.module.scss';

const Counter: FC<ICounter> = ({
  value,
  minValue,
  maxValue,
  onChange,
  className,
}) => {

  return (
    <div className={ cn(theme.root, className) }>
      <IconButton 
        className={ theme.remove } 
        name="remove" 
        active={ !(minValue && value <= minValue) }
        onClick={ () => { onChange(--value) } }
      />
      <input 
        type="number" 
        value={ value } 
        min={ minValue } 
        max={ maxValue }
        onChange={ (e: any) => { onChange(parseInt(e.target.value)) } } 
      />
      <IconButton
        className={ theme.add } 
        name="add" 
        active={ !(maxValue && value >= maxValue) }
        onClick={ () => { onChange(++value) } }
      />
    </div>
  );
}

export default Counter;

import React, { FC } from 'react';
import { Field } from 'redux-form';

import { PhoneInput as PhoneInputComponent } from 'common/ui';
import { PhoneInputProps } from './PhoneInput.interface'
import theme from './PhoneInput.module.scss';

const PhoneInput: FC<PhoneInputProps> = ({
  name,
  type = 'text',
  label,
  ...props
}) => {

  return (
    <Field
      { ...props }
      className={ theme.root }
      name={ name }
      type={ type }
      label={ label || name }
      id={ name }
      component={ PhoneInputComponent }
    />
  )
}

export default PhoneInput;
import React, { FC } from 'react';
import cn from 'classnames';

import { IPCard } from './PCard.interface';
import { FileInput } from 'common/ui/FormElements'

import theme from './PCard.module.scss';

const PCard: FC<IPCard> = ({
  className
}) => {
  const paymentInfo = [
    {  }
  ]
  return (
    <div className={ cn(theme.root, className) }>
      <div className={ theme.info }>
        Ви оплачуєте за реквізитами, після чого прикріплюєте квитанцію на оплату.
        <br/>
        <br/>
        Натисніть кнопку <strong>Перейти до оплати</strong>, щоб відобразилися реквізити для оплати Вашого замовлення.
      </div>
    </div>
  )
}

export default PCard;

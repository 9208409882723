import { 
  defaultTableState, defaultFormState
} from 'reducers/admin/helpers/model'

export const initialFormState = {
  ...defaultFormState,
  user: {}
};

export const initialTableState = {
  ...defaultTableState,
  users: []
};

import * as hooks from './';

const labelMessages = (type: string) => {
  switch (type) {
    case 'novaPosta':
      return { label: 'Змінити вид оплати', labelSending: 'Зміна виду оплати' }
    case 'card':
      return { label: 'Надіслати чек', labelSending: 'Надсилання чеку' }
    default:
      return { label: 'Оплатити', labelSending: 'Оплата' }
  }
}

const usePaymentForm = () => {
  const selectors = hooks.useSelectors();
  const type = hooks.form.selector('type');

  return {
    ...labelMessages(type),
    selectors,
  }
}

export default usePaymentForm;

import React, { FC } from 'react';
import cn from 'classnames';
import { Grid, Tabs as MaterialTabs, Tab, Box } from '@material-ui/core';

import { HtmlText } from 'common/ui';

import { ITabs } from './Tabs.interface';
import theme from './Tabs.module.scss';
import './Tabs.scss';

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Tabs: FC<ITabs> = ({ 
  className,
  tabIndex,
  onChange,
  tabs = [],
}) => {
  
  return (
    <div
      className={ cn([theme.root], className) }
    >
      <MaterialTabs value={ tabIndex } onChange={ onChange } aria-label="simple tabs example">
        { tabs.map((val: any, index: number) => (
          <Tab key={ index } label={ val.label } />
        ))}
      </MaterialTabs>
      { tabs.map((val: any, index: number) => (
        <TabPanel key={ index } value={ tabIndex } index={ index }>
          <HtmlText html={ val.data } />
        </TabPanel>
      ))}
    </div>
  )
};

export default Tabs;
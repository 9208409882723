import { initialSelectState } from './purchases.model';
import {
  SELECT_FETCH_STATUSES,
  SELECT_STATUS_SUCCESS,
  SELECT_STATUS_FAIL
} from './purchases.constants';

export const select = (state = initialSelectState, action: any) => {
  switch(action.type) {
    case SELECT_FETCH_STATUSES: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case SELECT_STATUS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: '',
      };
    }
    case SELECT_STATUS_FAIL: {
      return {
        ...state,
        loading: false,
        error: 'Failed to fetch statuses',
      };
    }
    default: {
      return state;
    }
  }
};

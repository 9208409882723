import { change, registerField }  from 'redux-form';

import { novaPostaActions } from 'reducers/both';
import * as mHooks from '../../../hooks';

export const city = (store: any, dispatch: any, formName: string, state: any) => (cityId: number) => {
  novaPostaActions.changeCity(store)(cityId);
  dispatch(registerField(formName, 'address.warehouseId', 'Field'));
  dispatch(change(formName, 'address.warehouseId', null));
  dispatch(registerField(formName, 'address.warehouse2Id', 'Field'));
  dispatch(change(formName, 'address.warehouse2Id', null));
  dispatch(change(formName, 'address.requiredW2', false));
}

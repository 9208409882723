import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { basketActions } from 'reducers/application';
import { Icon } from 'common/ui';
import { IHeader } from './Header.interface';
import * as hooks from './hooks';
import { Order } from 'components/application';

import theme from './Header.module.scss';
import { Menu, Logo, Drawer, MobileMenu, BasketElements } from '../';

const Header: FC<IHeader> = ({
  
}) => {
  const { isDrawerOpened } = hooks.useHeader();

  return (
    <>
      <MobileMenu />
      <header className={ theme.root }>
        <NavLink to="/">
          <Logo />
        </NavLink>
        <Menu />
        <Drawer
          isOpened={ isDrawerOpened }
          onBackdropClick={ (isOpened: boolean) => basketActions.openMenu(false) }
          className={ theme.drawer }
        >
          <div className={ theme.body }>
            <div 
              className={ theme.header }
              onClick={ () => basketActions.openMenu(false) }
            >
              <Icon 
                className={ classNames({
                    [theme.iconOpen]: isDrawerOpened,
                  },
                  [theme.icon]
                )}
                name="arrow-back-ios"
              />
              Корзина
            </div>

            <BasketElements />
          </div>
        </Drawer>
        <Order />
      </header>
    </>
  );
}

export default Header;

import { 
  defaultTableState, defaultFormState, defaultSelectState
} from 'reducers/admin/helpers/model'

export const initialFormState = {
  ...defaultFormState,
  color: {},
};

export const initialTableState = {
  ...defaultTableState,
  colors: []
};

export const initialSelectState = {
  ...defaultSelectState,
  colors: [],
};

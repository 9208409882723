import React, { FC } from 'react';

import { IMobileFilters } from './MobileFilters.interface';
import { 
  FilterCategories, FilterMobileSorting 
} from 'components/application/sections';
import { Tags } from '../';

const MobileFilters: FC<IMobileFilters> = ({
}) => {

  return (
    <>
      <FilterCategories type="article" />
      <Tags />
      <FilterMobileSorting
        type="articles"
        params={[
          { label: 'Популярні', value: 'popular' },
          { label: 'Старі', value: 'old' },
          { label: 'Нові', value: 'new' },
        ]}
      />
    </>
  );
}

export default MobileFilters;

import React, { FC } from 'react';
import cn from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';

import { Product, FilterSorting } from 'components/application/sections';
import { GreyButton } from 'common/ui';
import { FilterCategories } from 'components/application/sections';
import { articlesActions } from 'reducers/application';

// import { Sorting } from '../';
import { IArticles } from './Articles.interface';
import theme from './Articles.module.scss';

const Articles: FC<IArticles> = ({
  fixFilters,
  selectors,
  articles,
  loadMore,
}) => {

  return (
    <div className={ cn({ [theme.fixFilters]: fixFilters }, theme.root) }>
      <FilterSorting 
        className={ theme.sorting } 
        type="articles"
        params={[
          { label: 'популярні', value: 'popular' },
          { label: 'старі', value: 'old' },
          { label: 'нові', value: 'new' },
        ]}
      />

      { !articles.length && <div className={ theme.empty }>
          За вашими даними цікавих порад не знайдено :(
        </div> 
      }

      <InfiniteScroll
        pageStart={0}
        className={ theme.scroll }
        loadMore={ () => { loadMore() } }
        hasMore={ !selectors.loading && selectors.leftMore }
       >

        { (articles || []).map((article, index) => (
          <Product 
            { ...article }
            key={index}
            original="402"
            className={ theme.article }
            message="Детальніше"
            showTitle
          />
        ))}
      </InfiniteScroll>

      { selectors.leftMore &&
        <div className={ theme.loadButton }>
          <GreyButton
            label="Завантажити ще"
            type="grey"
            onClick={ loadMore }
           />
        </div>
      }
    </div>
  );
}

export default Articles;

import React, { FC } from 'react';
import cn from 'classnames';
import { FormSection } from 'redux-form';

import { RadioGroup, TextField } from 'common/ui/FormElements'
import * as hooks from './hooks';

import * as utils from './utils';
import { IAddress } from './Address.interface';
import theme from './Address.module.scss';
import { NovaPosta, Other } from './components';

const Address: FC<IAddress> = ({ 
  className,
  formName = 'order',
  store,
}) =>  {
  const { type, dispatch, state, cityId } = hooks.useAddress(formName);

  return(
    <div className={ cn(theme.root, className) }>
      <FormSection name="address">
        <RadioGroup
          name="type"
          label="Перевізник"
          values={[
            { value: 'novaPosta', label: 'Нова пошта' },
            { value: 'other', label: 'Інший' },
          ]}
          onChange={ utils.change.type(store, state) }
        />

        { type == 'novaPosta' && <NovaPosta key={ cityId } state={ state } formName={ formName } store={ store } dispatch={ dispatch } /> }
        { type == 'other' && <Other formName={ formName } store={ store } /> }
        <TextField
          className={ theme.info }
          name="info"
          label="Додаткова інформація"
        />
      </FormSection>
    </div>
  )
}

export default Address;

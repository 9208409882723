import { call, put, take, takeLatest, cancel, fork } from 'redux-saga/effects'
import { startSubmit, stopSubmit, reset } from 'redux-form';
import { push } from 'connected-react-router'


import { orderActions } from 'reducers/application';
import { novaPostaActions } from 'reducers/both';
import { post, patch } from 'common/utils/api';
import { preparedFormData } from 'common/utils/preparedData';
import { store } from 'store/indexApplication';

import * as types from 'reducers/application/order/order.constants';

function* orderResponse(response, message = null) {
  if (response.success && response.data.success) {
    if (message) { yield put(stopSubmit('order')) }
    yield put({type: types.ORDER_FORM_SUCCESS, payload: response.data});

    orderActions.payment.start();
    orderActions.main.checkMessage();
    if (response.data.showMessage) { orderActions.order.show(true) };

  } else {
    yield put({type: types.ORDER_FORM_FAIL, payload: { error: response.data.errors }})
    if (response.data.messages) {
      yield put(stopSubmit('order', response.data.messages))
    }
  }
}

function* updateOrder(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('order'))
    const response = yield call(patch, 'clients/orders/update', formData)

    yield call(orderResponse, response, "Замовлення було оновлено")
  } catch (error) {
    yield put({type: types.ORDER_FORM_FAIL, error})
  }
}

function* createOrder(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    yield put(startSubmit('order'))
    const response = yield call(post, 'clients/orders/create', formData)

    yield call(orderResponse, response, "Замовлення було створено")
  } catch (error) {
    yield put({type: types.ORDER_FORM_FAIL, error})
  }
}

function* fetchOrder(action: any) {
  try {
    const formData = preparedFormData(action.payload)
    const response = yield call(post, 'clients/orders/fetch', formData)

    yield call(orderResponse, response, false);
    novaPostaActions.checkOrderData(store)();
  } catch (error) {
    yield put({type: types.ORDER_FORM_FAIL, error})
  }
}

export function* fetchFlow() {
  yield takeLatest(types.FETCH_ORDER, fetchOrder)
}

export function* createFlow() {
  yield takeLatest(types.ORDER_CREATE, createOrder)
}

export function* updateFlow() {
  yield takeLatest(types.ORDER_UPDATE, updateOrder)
}

